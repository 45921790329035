import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { s3Upload } from '../lib/awsLib'
import { API } from 'aws-amplify'
import { Controller, useForm } from 'react-hook-form'
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardGroup,
  CardHeader,
  ErrorMessage,
  FileInput,
  Form,
  FormGroup,
  Grid,
  GridContainer,
  Icon,
  Label,
} from '@trussworks/react-uswds'
import Skeleton from 'react-loading-skeleton'
import * as uuid from 'uuid'
import { useConfig } from '../contexts/ConfigContext'
import { useAuth } from '../contexts/AuthContext'
import { formatDate } from '../util/datetime'
import sanitize from 'sanitize-filename'
import ContactInfo from './ContactInfo'
import DataTable from 'react-data-table-component'
import DataTableLoader from './DataTableLoader'
import VendorSurveyNotes from './VendorSurveyNotes'
import Spinner from './Spinner'
import ButtonCsvDownload from './ButtonCsvDownload'
import Main from './Main'

// TODO: Break this dashboard down into smaller individual components
const VendorDashboard = () => {
  const noFile = 'File: No file selected, choose from folder'
  const [selectedVendor, setSelectedVendor] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [uploadSuccess, setUploadSuccess] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [refreshError, setRefreshError] = useState(false)
  const [fileList, setFileList] = useState(noFile)
  const [csvError, setCsvError] = useState(false)

  const fileInput = useRef(null)
  const config = useConfig()
  const user = useAuth()
  const navigate = useNavigate()
  const {
    control,
    reset,
    handleSubmit,
    clearErrors,
    formState,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      file: '',
    },
  })

  const customDataTableStyles = {
    tableWrapper: {
      style: { paddingTop: '0.25rem' },
    },
    header: {
      style: {
        fontSize: '1rem',
        minHeight: '48px',
        paddingLeft: '0',
        paddingRight: '0',
      },
    },
    expanderButton: {
      style: {
        color: '#757575',
        fill: '#757575',
      },
    },
    pagination: {
      style: {
        fontSize: 'unset',
      },
      pageButtonsStyle: { borderRadius: '0.25rem' },
    },
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,
  }

  const csvColumns = [
    {
      id: 'name',
      name: 'Name',
      selector: (row) => row.name_,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: 'Status',
      selector: (row) => row.status_,
      sortable: true,
      wrap: true,
      format: (row) => {
        switch (row.status_) {
          case 'Pending Validation':
            return (
              <>
                <Icon.Schedule className="margin-right-05 text-info-dark" role="presentation" focusable="false" />{' '}
                {row.status_}
              </>
            )
          case 'File Error':
            return (
              <>
                <Icon.Error className="margin-right-05 text-error-dark" role="presentation" focusable="false" />{' '}
                {row.status_}
              </>
            )
          case 'Passed Validation':
            return (
              <>
                <Icon.CheckCircle className="margin-right-05 text-success-dark" role="presentation" focusable="false" />{' '}
                {row.status_}
              </>
            )
          case 'Failed Validation':
            return (
              <>
                <Icon.Warning className="margin-right-05 text-warning-dark" role="presentation" focusable="false" />{' '}
                {row.status_}
              </>
            )
          default:
            return (
              <>
                <Icon.Info className="margin-right-05 text-info-dark" role="presentation" focusable="false" />{' '}
                {row.status_}
              </>
            )
        }
      },
    },
    {
      name: 'Submission Type',
      selector: (row) => row.submissionType,
    },
    {
      id: 'date',
      name: 'Uploaded Date',
      selector: (row) => row.createdAt,
      sortable: true,
      wrap: true,
      format: (row) => formatDate(row.createdAt),
    },
  ]

  const uploadColumns = [
    {
      id: 'name',
      name: 'Name',
      selector: (row) => row.name_,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: 'Submission Type',
      selector: (row) => row.submissionType,
    },
    {
      id: 'date',
      name: 'Uploaded Date',
      selector: (row) => row.createdAt,
      sortable: true,
      format: (row) => formatDate(row.createdAt),
    },
  ]

  useEffect(() => {
    if (isSubmitSuccessful) {
      fileInput.current.clearFiles()
      reset({ file: '' })
      setFileList(noFile)
    }
  }, [formState, reset])

  useLayoutEffect(() => {
    // Get list of vendors associated with logged in user
    const onLoad = async () => {
      setIsLoading(true)
      setUploadSuccess(false)
      setUploadError(false)
      setRefreshError(false)

      if (!config.isLoading && !user.isLoading) {
        if (user.info?.userRole === 'Vendor') {
          try {
            const dashboard = await API.get('qhp-survey-auth', '/vendor/dashboard')

            setSelectedVendor({
              configSubmissionType: dashboard.configSubmissionType,
              config: dashboard.config,
              info: dashboard.vendor,
              user: user.info,
              uploads: dashboard.uploads,
              csvs: dashboard.csvs,
            })

            navigate('/dashboard/vendor/' + dashboard.vendor.id)
          } catch (error) {
            console.error(error)
          }
        }
      }

      setIsLoading(false)
    }

    onLoad()
  }, [config.isLoading, user.isLoading, user.info])

  const getCsvList = () => {
    return API.get('qhp-survey-auth', '/vendor/survey/csvfiles')
  }

  const handleFileChange = (event) => {
    clearErrors()
    setUploadSuccess(false)
    setUploadError(false)
    if (event.target?.files[0]) {
      setFileList(`Selected File: ${event.target.files[0].name}, press to change`)
    } else {
      setFileList(noFile)
    }
  }

  const handleFileCancel = () => {
    clearErrors()
    fileInput.current.clearFiles()
    reset({ file: '' })
    setFileList(noFile)
  }

  const handleRefresh = async () => {
    setIsProcessing(false)
    setIsRefreshing(true)
    setRefreshError(false)

    try {
      const csvList = await getCsvList()
      setSelectedVendor((prev) => {
        return {
          ...prev,
          csvs: csvList,
        }
      })
    } catch (e) {
      setRefreshError(true)
    }

    setIsRefreshing(false)
  }

  async function onSubmit() {
    setIsProcessing(false)
    setUploadSuccess(false)
    setUploadError(false)
    setRefreshError(false)

    if (fileInput.current.files[0]) {
      const file = fileInput.current.files[0]

      const uploadId = uuid.v1()

      try {
        const submissionItem = await API.post('qhp-survey-auth', '/vendor/survey/create/upload', {
          body: {
            id: uploadId,
            fileName: file.name,
            fileType: file.type,
            fileSize: file.size,
          },
        })

        setSelectedVendor((prev) => {
          return {
            ...prev,
            uploads: [...prev.uploads, submissionItem],
          }
        })

        const filePath = `vendor/${sanitize(config.sys.year_)}/${sanitize(selectedVendor.info.id)}/surveys/${sanitize(
          selectedVendor.configSubmissionType
        )}/${uploadId}/`

        await s3Upload(filePath, file)

        setIsProcessing(true)
        setUploadSuccess(true)
      } catch (error) {
        console.error(error)
        setUploadError(true)
      }
    }
  }

  return (
    <>
      <GridContainer>
        <Grid row gap>
          <Grid tablet={{ col: true }}>
            <Main>
              <section className="usa-section usa-prose usa-prose--wide">
                <h1>Dashboard</h1>
                {config.isLoading || user.isLoading || isLoading ? (
                  <>
                    <Grid row gap={2}>
                      <Grid tablet={{ col: 6 }}>
                        <Skeleton height="12rem" />
                      </Grid>
                      <Grid tablet={{ col: 6 }}>
                        <Skeleton height="12rem" />
                      </Grid>
                      <Grid tablet={{ col: 12 }} className="margin-top-1">
                        <Skeleton height="12rem" />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {!selectedVendor || !selectedVendor.info || !selectedVendor.info.name_ ? (
                      <Alert type="info">
                        Your account is not currently associated with any vendors. If you feel this is an error, please
                        contact us at <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a>
                      </Alert>
                    ) : (
                      <>
                        <CardGroup>
                          <Card gridLayout={{ tablet: { col: 4 } }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.AccountCircle
                                  className="margin-right-05"
                                  role="presentation"
                                  focusable="false"
                                  size={3}
                                />{' '}
                                Vendor Information
                              </h2>
                            </CardHeader>
                            <CardBody>
                              <address>
                                <p>
                                  <strong className="font-body-lg">{selectedVendor.info?.name_}</strong>
                                  <br />
                                  <br />
                                  <strong className="text-base">Contact:</strong>
                                  <br />
                                  <ContactInfo contact={selectedVendor.user} />
                                </p>
                                <p className="font-body-2xs text-base">
                                  For changes to contact information please contact us at{' '}
                                  <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a>
                                </p>
                              </address>
                            </CardBody>
                          </Card>
                          <Card gridLayout={{ tablet: { col: 3 } }}>
                            <CardHeader>
                              {selectedVendor.configSubmissionType === 'NONE' ? (
                                <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                  <Icon.FileUpload
                                    className="margin-right-05"
                                    role="presentation"
                                    focusable="false"
                                    size={3}
                                  />{' '}
                                  Upload Survey File
                                </h2>
                              ) : (
                                <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                  <Icon.FileUpload
                                    className="margin-right-05"
                                    role="presentation"
                                    focusable="false"
                                    size={3}
                                  />{' '}
                                  Upload {selectedVendor.configSubmissionType} Survey File
                                </h2>
                              )}
                            </CardHeader>
                            <CardBody>
                              {selectedVendor.configSubmissionType === 'NONE' ? (
                                <>
                                  <p>Uploads are currently not available.</p>
                                  <p>
                                    <strong>Test File Uploads:</strong>
                                    <br />
                                    {selectedVendor.config.dateStartTest}
                                    {' - '}
                                    {selectedVendor.config.dateEndTest}
                                  </p>
                                  <p>
                                    <strong>Final File Uploads:</strong>
                                    <br />
                                    {selectedVendor.config.dateStartFinal}
                                    {' - '}
                                    {selectedVendor.config.dateEndFinal}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <div aria-live="polite">
                                    {uploadSuccess && (
                                      <Alert className="margin-bottom-3" type="success" headingLevel="h4" slim>
                                        File was uploaded successfully
                                      </Alert>
                                    )}
                                    {uploadError && (
                                      <Alert className="margin-bottom-3" type="error" headingLevel="h4" slim>
                                        There was an error uploading the file
                                      </Alert>
                                    )}
                                  </div>
                                  <Form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup className="margin-top-0" error={errors.file}>
                                      <Label htmlFor="file" className="text-bold" error={errors.file}>
                                        File{' '}
                                        <span className="usa-hint usa-hint--required" aria-hidden="true">
                                          *
                                        </span>
                                      </Label>
                                      <span className="usa-hint font-body-2xs" id="file-hint">
                                        Only CSV and ZIP files will be processed
                                      </span>
                                      {errors.file && (
                                        <ErrorMessage id="file-error-alert">{errors.file.message}</ErrorMessage>
                                      )}
                                      <Controller
                                        control={control}
                                        name="file"
                                        rules={{
                                          required: 'File is required',
                                          validate: {
                                            fileSize: () => {
                                              return (
                                                fileInput.current?.files[0]?.size < 10000000 ||
                                                'File size cannot exceed 10 MB'
                                              )
                                            },
                                          },
                                        }}
                                        render={({ field }) => (
                                          <FileInput
                                            {...field}
                                            ref={fileInput}
                                            id="file"
                                            accept="application/zip,application/zip-compressed,application/x-zip-compressed,text/csv,application/vnd.ms-excel"
                                            aria-describedby="file-hint file-error-alert"
                                            aria-required="true"
                                            aria-label={`${fileList}`}
                                            onChange={(event) => {
                                              handleFileChange(event)
                                              field.onChange(event)
                                            }}
                                          />
                                        )}
                                      />
                                    </FormGroup>
                                    <ButtonGroup>
                                      <Button type="submit">
                                        {isSubmitting ? (
                                          <>
                                            <Spinner />
                                            Uploading
                                          </>
                                        ) : (
                                          'Upload'
                                        )}
                                      </Button>
                                      <Button type="button" outline onClick={handleFileCancel}>
                                        Cancel
                                      </Button>
                                    </ButtonGroup>
                                  </Form>
                                </>
                              )}
                            </CardBody>
                          </Card>
                          <Card gridLayout={{ tablet: { col: 5 } }} containerProps={{ className: 'usa-card--light' }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.Info className="margin-right-05" role="presentation" focusable="false" size={3} />{' '}
                                Vendor Submissions
                              </h2>
                            </CardHeader>
                            <CardBody>
                              <p>
                                <strong>CSV Files</strong>
                                <br /> CSV filenames must use the following naming convention:
                                <br />
                                &lt;Reporting Unit ID&gt;.csv.
                              </p>
                              <p>
                                <strong>ZIP Files</strong>
                                <br />
                                To assist with tracking multiple submissions, ZIP files should use the following naming
                                convention: &lt;VendorName&gt;_Submission_&lt;Letter&gt;.zip. For example, vendors
                                should use &lt;VendorName&gt;_Submission_A.zip for the first ZIP file,
                                &lt;VendorName&gt;_Submission_B.zip for the second ZIP file, and so on.
                              </p>
                              <p>
                                For technical assistance, please contact{' '}
                                <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a>.
                              </p>
                            </CardBody>
                          </Card>
                          <Card gridLayout={{ tablet: { col: 12 } }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.FilePresent
                                  className="margin-right-05"
                                  role="presentation"
                                  focusable="false"
                                  size={3}
                                />{' '}
                                Survey CSV Files
                              </h2>
                            </CardHeader>
                            <CardBody>
                              <div aria-live="polite">
                                {isProcessing && (
                                  <Alert type="info" headingLevel="h3" slim>
                                    File uploads may continue to process in the background. Select the
                                    &quot;Refresh&quot; button to update the list of processed CSV files.
                                  </Alert>
                                )}
                                {refreshError && (
                                  <Alert type="error" headingLevel="h3" slim>
                                    We&apos;re sorry, there was an error refreshing the list of processed CSV files.
                                  </Alert>
                                )}
                              </div>
                              <div className="display-flex flex-row flex-justify-end padding-y-2">
                                {selectedVendor.configSubmissionType !== 'NONE' && (
                                  <Button
                                    type="button"
                                    outline
                                    className="usa-button--icon usa-button--small margin-right-0"
                                    aria-label="Refresh Survey CSV Files data"
                                    onClick={handleRefresh}
                                  >
                                    <Icon.Autorenew role="presentation" focusable="false" /> Refresh
                                  </Button>
                                )}
                              </div>
                              <DataTable
                                columns={csvColumns}
                                data={selectedVendor.csvs}
                                responsive
                                striped
                                pagination
                                paginationPerPage={25}
                                paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                paginationComponentOptions={paginationComponentOptions}
                                highlightOnHover
                                defaultSortFieldId="date"
                                defaultSortAsc={false}
                                sortIcon={<Icon.ArrowDownward role="presentation" />}
                                expandableRows
                                expandableRowDisabled={(row) => {
                                  return row.status_ !== 'File Error' && row.status_ !== 'Failed Validation'
                                }}
                                expandableRowsComponent={VendorSurveyNotes}
                                expandOnRowClicked
                                progressPending={isSubmitting || isRefreshing}
                                progressComponent={<DataTableLoader />}
                                customStyles={customDataTableStyles}
                              />
                            </CardBody>
                          </Card>
                          <Card gridLayout={{ tablet: { col: 12 } }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.FileUpload
                                  className="margin-right-05"
                                  role="presentation"
                                  focusable="false"
                                  size={3}
                                />{' '}
                                Uploads
                              </h2>
                            </CardHeader>
                            <CardBody>
                              <DataTable
                                columns={uploadColumns}
                                data={selectedVendor.uploads}
                                responsive
                                striped
                                pagination
                                paginationPerPage={5}
                                paginationRowsPerPageOptions={[5, 25, 50, 75, 100]}
                                paginationComponentOptions={paginationComponentOptions}
                                highlightOnHover
                                defaultSortFieldId="date"
                                defaultSortAsc={false}
                                sortIcon={<Icon.ArrowDownward role="presentation" />}
                                progressPending={isSubmitting}
                                progressComponent={<DataTableLoader />}
                                customStyles={customDataTableStyles}
                              />
                            </CardBody>
                          </Card>
                          <Card gridLayout={{ tablet: { col: 12 } }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.FileDownload
                                  className="margin-right-05"
                                  role="presentation"
                                  focusable="false"
                                  size={3}
                                />{' '}
                                CSV Report
                              </h2>
                            </CardHeader>
                            <CardBody>
                              <p className="font-body-2xs text-base">
                                Report includes data for all uploaded CSV files for the current cycle.
                              </p>
                              {csvError && (
                                <Alert className="margin-bottom-3" type="error" headingLevel="h4" slim>
                                  {csvError}
                                </Alert>
                              )}
                              <ul className="qhp-list qhp-list--unstyled qhp-list--buttons">
                                <li>
                                  <ButtonCsvDownload
                                    label="Vendor Survey Submission Report"
                                    filename="QHPVendorSurveySubmissionReport.csv"
                                    apiPath="/reports/vendor/vendorsurveysubmissions"
                                    setCsvError={setCsvError}
                                  />
                                </li>
                              </ul>
                            </CardBody>
                          </Card>
                        </CardGroup>
                      </>
                    )}
                  </>
                )}
              </section>
            </Main>
          </Grid>
        </Grid>
      </GridContainer>
    </>
  )
}

export default VendorDashboard
