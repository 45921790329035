import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'
import {
  Alert,
  Button,
  ButtonGroup,
  ErrorMessage,
  FormGroup,
  Grid,
  GridContainer,
  Label,
  Modal,
  ModalHeading,
  ModalFooter,
  ModalToggleButton,
  Tag,
} from '@trussworks/react-uswds'
import { useForm } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import Skeleton from 'react-loading-skeleton'
import Spinner from './Spinner'

// TODO: Use controlled form components like the Analyst Survey Notes component (AnalystSurveyNotes.js)
// TODO: Break this component down into smaller, more manageable components
const IssuerAttestation = ({ data, config, handleSave, setStatusMessage }) => {
  const [submitMessage, setSubmitMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [attestation, setAttestation] = useState({ isModified: false, data: {}, undo: {} })

  const modalRef = useRef()
  const formRef = useRef(null)

  let allFields = []
  switch (config?.vendorSelection) {
    case 'APPROVED':
      allFields = [
        'answer01',
        'answer02',
        'answer03',
        'answer04',
        'answer05',
        'answer06',
        'answer07',
        'answer08',
        'answer09',
        'answer10',
        'answer11',
        'answer12',
        'answer13',
        'answer14',
        'ineligibilityReason',
        'note',
        'vendor',
        'answer15',
        'answer16',
        'version',
      ]
      break
    default:
      break
  }

  const {
    register,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    reValidateMode: 'onSubmit',
  })

  const onSubmit = async (data, e) => {
    e.preventDefault()
    setSubmitMessage(null)

    try {
      if (config?.vendorSelection === 'APPROVED') {
        if (data.ineligibilityReason === '' && data.vendor === '') {
          data.status_ = 'Incomplete'
        } else {
          data.status_ = 'Complete'
        }
      }

      await API.post('qhp-survey-auth', '/issuer/attestation/update', { body: data })

      setAttestation((prev) => {
        return { ...prev, isModified: false, data: { ...prev.data, ...data }, undo: { ...prev.undo, ...data } }
      })
      handleSave(data)
      setSubmitMessage({ type: 'success', message: 'Attestation information saved successfully.' })
      if (formRef && formRef.current) {
        formRef.current.scrollIntoView({
          inline: 'start',
          behavior: 'smooth',
        })
      }
    } catch (error) {
      setSubmitMessage({
        type: 'error',
        message: "We're sorry, there was a problem submitting your attestation information.",
      })
    }
  }

  // TODO: Consider using React Hook Form's watch functionality instead of an onChange event
  const handleQuestionChange = (event) => {
    setSubmitMessage(null)
    const fieldName = event.target.name
    const index = allFields.indexOf(fieldName)
    const remainingFields = allFields.slice(index + 1)
    let fieldsToUpdate = {}
    fieldsToUpdate[fieldName] = event.target.value
    if (event.target.value === 'Yes' || event.target.value === 'No' || event.target.value === '') {
      remainingFields.forEach((fieldName) => (fieldsToUpdate[fieldName] = ''))
    }

    if (config?.vendorSelection === 'APPROVED') {
      setValue('vendor', '')
      setValue('ineligibilityReason', '')

      if (getValues('ineligibilityReason') === '' && getValues('vendor') === '') {
        fieldsToUpdate['status_'] = 'Incomplete'
      }
    }

    fieldsToUpdate['reportingUnit'] = getValues('reportingUnit')

    setAttestation((prev) => {
      return { ...prev, isModified: true, data: { ...prev.data, ...fieldsToUpdate } }
    })

    handleSave(fieldsToUpdate)
  }

  const handleCancel = () => {
    setSubmitMessage(null)
    modalRef.current.toggleModal()
    // Return attestation state back to original data that was passed in
    setAttestation((prev) => {
      return { ...prev, isModified: false, data: { ...prev.data, ...prev.undo } }
    })
    handleSave(attestation.undo)
  }

  useEffect(() => {
    setIsLoading(true)
    const onLoad = async () => {
      const currentAttestation = JSON.parse(sessionStorage.getItem(`attestation_${data.reportingUnit}`)) || {
        isModified: false,
        data: data,
        undo: data,
      }
      setAttestation(currentAttestation)
      setIsLoading(false)
    }

    onLoad()
  }, [])

  useEffect(() => {
    setStatusMessage(submitMessage?.message)
  }, [submitMessage])

  useDeepCompareEffect(() => {
    sessionStorage.setItem(`attestation_${data.reportingUnit}`, JSON.stringify(attestation))

    // Update related form fields
    let formFields = {}
    allFields.forEach((fieldName) => (formFields[fieldName] = attestation.data[fieldName]))
    reset(formFields)
  }, [attestation])

  return (
    <>
      {isLoading ? (
        <Skeleton height="3rem" count={3} className="margin-bottom-1" />
      ) : (
        <div ref={formRef}>
          <form
            className="usa-form usa-form--full padding-y-4 border-bottom border-base-lighter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input {...register('id')} id={`id_${data.reportingUnit}`} name="id" type="hidden" value={data.id} />
            <input
              {...register('reportingUnit')}
              id={`reportingUnit_${data.reportingUnit}`}
              name="reportingUnit"
              type="hidden"
              value={data.reportingUnit}
            />
            <input
              {...register('year_')}
              id={`year_${data.reportingUnit}`}
              name="year_"
              type="hidden"
              value={config?.attestation?.year_}
            />
            <GridContainer className="flex-fill">
              <Grid row gap>
                <Grid tablet={{ col: true }}>
                  {submitMessage && (
                    <Alert type={submitMessage.type} headingLevel="h3" slim>
                      {submitMessage.message}
                    </Alert>
                  )}
                </Grid>
              </Grid>
              {config?.vendorSelection === 'APPROVED' && (
                <>
                  <Grid row gap>
                    <Grid
                      tablet={{ col: true }}
                      role="group"
                      aria-labelledby={`label_${data.reportingUnit}`}
                      aria-describedby={`modified_${data.reportingUnit}`}
                    >
                      <div id={`modified_${data.reportingUnit}`} className="float-right margin-top-2">
                        {attestation.isModified && (
                          <Tag className="usa-tag--error">
                            <span className="usa-sr-only">This attestation record has been </span>modified{' '}
                            <span className="usa-sr-only">
                              Be sure to save or cancel your changes before leaving the page.
                            </span>
                          </Tag>
                        )}
                      </div>
                      <fieldset
                        className="usa-fieldset"
                        role="radiogroup"
                        aria-labelledby={`legend-question01_${data.reportingUnit}`}
                      >
                        <legend id={`legend-question01_${data.reportingUnit}`} className="text-bold">
                          {config?.attestation?.question01}
                        </legend>
                        <div className="display-flex">
                          <div className="usa-radio margin-right-3">
                            <input
                              {...register('answer01', { onChange: (e) => handleQuestionChange(e) })}
                              id={`answer01-no_${data.reportingUnit}`}
                              name="answer01"
                              className="usa-radio__input"
                              type="radio"
                              value="No"
                            />
                            <label className="usa-radio__label" htmlFor={`answer01-no_${data.reportingUnit}`}>
                              No
                            </label>
                          </div>
                          <div className="usa-radio margin-right-3">
                            <input
                              {...register('answer01', { onChange: (e) => handleQuestionChange(e) })}
                              id={`answer01-yes_${data.reportingUnit}`}
                              name="answer01"
                              className="usa-radio__input"
                              type="radio"
                              value="Yes"
                            />
                            <label className="usa-radio__label" htmlFor={`answer01-yes_${data.reportingUnit}`}>
                              Yes
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer01 ? 'usa-fieldset display-block' : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question02_${data.reportingUnit}`}
                      >
                        <legend id={`legend-question02_${data.reportingUnit}`} className="text-bold">
                          {config?.attestation?.question02}
                        </legend>
                        <div className="display-flex">
                          <div className="usa-radio margin-right-3">
                            <input
                              {...register('answer02', { onChange: (e) => handleQuestionChange(e) })}
                              id={`answer02-no_${data.reportingUnit}`}
                              name="answer02"
                              className="usa-radio__input"
                              type="radio"
                              value="No"
                            />
                            <label className="usa-radio__label" htmlFor={`answer02-no_${data.reportingUnit}`}>
                              No
                            </label>
                          </div>
                          <div className="usa-radio margin-right-3">
                            <input
                              {...register('answer02', { onChange: (e) => handleQuestionChange(e) })}
                              id={`answer02-yes_${data.reportingUnit}`}
                              name="answer02"
                              className="usa-radio__input"
                              type="radio"
                              value="Yes"
                            />
                            <label className="usa-radio__label" htmlFor={`answer02-yes_${data.reportingUnit}`}>
                              Yes
                            </label>
                          </div>
                        </div>
                        <div
                          className={
                            attestation.data.answer01 === 'No' && attestation.data.answer02 === 'No'
                              ? 'usa-fieldset display-block'
                              : 'usa-fieldset display-none'
                          }
                          role="radiogroup"
                          aria-labelledby={`legend-question02_error`}
                        >
                          <Alert className="margin-bottom-3" type="warning" headingLevel="h4">
                            Please review your responses to the previous two questions or contact the Project Team at
                            QHP_Survey@air.org for further instructions.
                          </Alert>
                        </div>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer01 == 'Yes' && attestation.data.answer02 == 'Yes'
                            ? 'usa-fieldset display-block'
                            : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question03_${data.reportingUnit}`}
                      >
                        <FormGroup error={errors.answer03}>
                          <Label className="usa-label text-bold" htmlFor={`answer03_${data.reportingUnit}`}>
                            {config?.attestation?.question03}
                          </Label>
                          {errors.answer03 && (
                            <ErrorMessage id="answer03-error-alert">{errors.answer03.message}</ErrorMessage>
                          )}
                          <div className="display-flex">
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer03', { onChange: (e) => handleQuestionChange(e) })}
                                id={`answer03-no_${data.reportingUnit}`}
                                name="answer03"
                                className="usa-radio__input"
                                type="radio"
                                value="No"
                                aria-labelledby={`answer03-no-label_${data.reportingUnit}`}
                                aria-describedby="answer03-error-alert"
                              />
                              <label
                                id={`answer03-no-label_${data.reportingUnit}`}
                                className="usa-radio__label"
                                htmlFor={`answer03-no_${data.reportingUnit}`}
                              >
                                No
                              </label>
                            </div>
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer03', { onChange: (e) => handleQuestionChange(e) })}
                                id={`answer03-yes_${data.reportingUnit}`}
                                name="answer03"
                                className="usa-radio__input"
                                type="radio"
                                value="Yes"
                                aria-labelledby={`answer03-yes-label_${data.reportingUnit}`}
                                aria-describedby="answer03-error-alert"
                              />
                              <label
                                id={`answer03-yes-label_${data.reportingUnit}`}
                                className="usa-radio__label"
                                htmlFor={`answer03-yes_${data.reportingUnit}`}
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={
                          (attestation.data.answer01 == 'Yes' && attestation.data.answer02 == 'No') ||
                          attestation.data.answer03
                            ? 'usa-fieldset display-block'
                            : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question04_${data.reportingUnit}`}
                      >
                        <FormGroup error={errors.answer04}>
                          <Label className="usa-label text-bold" htmlFor={`answer04_${data.reportingUnit}`}>
                            {config?.attestation?.question04}
                          </Label>
                          {errors.answer04 && (
                            <ErrorMessage id="answer04-error-alert">{errors.answer04.message}</ErrorMessage>
                          )}
                          <div className="display-flex">
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer04', { onChange: (e) => handleQuestionChange(e) })}
                                id={`answer04-no_${data.reportingUnit}`}
                                name="answer04"
                                className="usa-radio__input"
                                type="radio"
                                value="No"
                                aria-labelledby={`answer04-no-label_${data.reportingUnit}`}
                                aria-describedby="answer04-error-alert"
                              />
                              <label
                                id={`answer04-no-label_${data.reportingUnit}`}
                                className="usa-radio__label"
                                htmlFor={`answer04-no_${data.reportingUnit}`}
                              >
                                No
                              </label>
                            </div>
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer04', {
                                  onChange: (e) => handleQuestionChange(e),
                                })}
                                id={`answer04-yes_${data.reportingUnit}`}
                                name="answer04"
                                className="usa-radio__input"
                                type="radio"
                                value="Yes"
                                aria-labelledby={`answer04-yes-label_${data.reportingUnit}`}
                                aria-describedby="answer04-error-alert"
                              />
                              <label
                                id={`answer04-yes-label_${data.reportingUnit}`}
                                className="usa-radio__label"
                                htmlFor={`answer04-yes_${data.reportingUnit}`}
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer04 === 'Yes'
                            ? 'usa-fieldset display-block'
                            : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question05_${data.reportingUnit}`}
                      >
                        <legend id={`legend-question05_${data.reportingUnit}`} className="text-bold">
                          {config?.attestation?.question05}
                        </legend>
                        <div className="display-flex">
                          <div className="usa-text margin-right-3">
                            <input
                              {...register('answer05', { onChange: (e) => handleQuestionChange(e) })}
                              id={`answer05_${data.reportingUnit}`}
                              name="answer05"
                              className="usa-input"
                              type="number"
                              min="0"
                              step="1"
                            />
                          </div>
                        </div>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer05 ? 'usa-fieldset display-block' : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question06_${data.reportingUnit}`}
                      >
                        <legend id={`legend-question06_${data.reportingUnit}`} className="text-bold">
                          {config?.attestation?.question06}
                        </legend>
                        <div className="display-flex">
                          <div className="usa-radio margin-right-3">
                            <input
                              {...register('answer06', { onChange: (e) => handleQuestionChange(e) })}
                              id={`answer06-no_${data.reportingUnit}`}
                              name="answer06"
                              className="usa-radio__input"
                              type="radio"
                              value="No"
                            />
                            <label className="usa-radio__label" htmlFor={`answer06-no_${data.reportingUnit}`}>
                              No
                            </label>
                          </div>
                          <div className="usa-radio margin-right-3">
                            <input
                              {...register('answer06', { onChange: (e) => handleQuestionChange(e) })}
                              id={`answer06-yes_${data.reportingUnit}`}
                              name="answer06"
                              className="usa-radio__input"
                              type="radio"
                              value="Yes"
                            />
                            <label className="usa-radio__label" htmlFor={`answer06-yes_${data.reportingUnit}`}>
                              Yes
                            </label>
                          </div>
                        </div>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer06 ? 'usa-fieldset display-block' : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question07_${data.reportingUnit}`}
                      >
                        <FormGroup error={errors.answer07}>
                          <Label className="usa-label text-bold" htmlFor={`answer07_${data.reportingUnit}`}>
                            {config?.attestation?.question07}
                          </Label>
                          {errors.answer07 && (
                            <ErrorMessage id="answer07-error-alert">{errors.answer07.message}</ErrorMessage>
                          )}
                          <div className="display-flex">
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer07', { onChange: (e) => handleQuestionChange(e) })}
                                id={`answer07-no_${data.reportingUnit}`}
                                name="answer07"
                                className="usa-radio__input"
                                type="radio"
                                value="No"
                              />
                              <label className="usa-radio__label" htmlFor={`answer07-no_${data.reportingUnit}`}>
                                No
                              </label>
                            </div>
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer07', { onChange: (e) => handleQuestionChange(e) })}
                                id={`answer07-yes_${data.reportingUnit}`}
                                name="answer07"
                                className="usa-radio__input"
                                type="radio"
                                value="Yes"
                              />
                              <label className="usa-radio__label" htmlFor={`answer07-yes_${data.reportingUnit}`}>
                                Yes
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer07 === 'Yes'
                            ? 'usa-fieldset display-block'
                            : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question08_${data.reportingUnit}`}
                      >
                        <legend id={`legend-question08_${data.reportingUnit}`} className="text-bold">
                          {config?.attestation?.question08}
                        </legend>
                        <span id="answer08-instructions" className="usa-small">
                          Please enter a correctly formatted reporting unit ID (e.g., &lt;IssuerID&gt;-&lt;QHP
                          State&gt;-&lt;Product Type&gt;; 12345-IA-PPO).
                        </span>
                        {errors.answer08 && (
                          <ErrorMessage id="answer08-error-alert">{errors.answer08.message}</ErrorMessage>
                        )}
                        <div className="display-flex">
                          <div className="usa-text margin-right-3">
                            <input
                              {...register('answer08', {
                                validate: (value) => {
                                  if (
                                    value &&
                                    attestation.data.answer07 === 'Yes' &&
                                    (value.length !== 12 || !value.match('[0-9]{5}-[a-zA-Z]{2}-[a-zA-Z]{3}'))
                                  ) {
                                    return 'Please enter a correctly formatted reporting unit ID'
                                  }
                                  return true
                                },
                                onChange: (e) => handleQuestionChange(e),
                              })}
                              id={`answer08_${data.reportingUnit}`}
                              name="answer08"
                              className="usa-input"
                              type="text"
                              aria-describedby="answer08-instructions answer08-error-alert"
                            />
                          </div>
                        </div>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer04 === 'No' ||
                          attestation.data.answer07 === 'No' ||
                          attestation.data.answer08
                            ? 'usa-fieldset display-block'
                            : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question09_${data.reportingUnit}`}
                      >
                        <FormGroup error={errors.answer09}>
                          <Label className="usa-label text-bold" htmlFor={`answer09_${data.reportingUnit}`}>
                            {config?.attestation?.question09}
                          </Label>
                          <span id="answer09-instructions" className="usa-small">
                            This count includes all enrollees, not just survey eligible enrollees, from both SHOP and
                            individual markets.
                          </span>
                          {errors.answer09 && (
                            <ErrorMessage id="answer09-error-alert">{errors.answer09.message}</ErrorMessage>
                          )}
                          <div className="display-flex">
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer09', { onChange: (e) => handleQuestionChange(e) })}
                                id={`answer09-no_${data.reportingUnit}`}
                                name="answer09"
                                className="usa-radio__input"
                                type="radio"
                                value="No"
                                aria-describedby="answer09-instructions answer07-error-alert"
                              />
                              <label className="usa-radio__label" htmlFor={`answer09-no_${data.reportingUnit}`}>
                                No
                              </label>
                            </div>
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer09', { onChange: (e) => handleQuestionChange(e) })}
                                id={`answer09-yes_${data.reportingUnit}`}
                                name="answer09"
                                className="usa-radio__input"
                                type="radio"
                                value="Yes"
                                aria-describedby="answer09-instructions answer07-error-alert"
                              />
                              <label className="usa-radio__label" htmlFor={`answer09-yes_${data.reportingUnit}`}>
                                Yes
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer09 ? 'usa-fieldset display-block' : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question10_${data.reportingUnit}`}
                      >
                        <legend id={`legend-question10_${data.reportingUnit}`} className="text-bold">
                          {config?.attestation?.question10}
                          <br />
                          <span id="answer10-instructions" className="usa-small">
                            This count should include all enrollees, not just survey eligible enrollees, for both SHOP
                            and individual markets.
                          </span>
                        </legend>
                        {errors.answer10 && (
                          <ErrorMessage id="answer10-error-alert">{errors.answer10.message}</ErrorMessage>
                        )}
                        <div className="display-flex">
                          <div className="usa-text margin-right-3">
                            <input
                              {...register('answer10', {
                                validate: (value) => {
                                  if (value && attestation.data.answer09 === 'Yes' && value <= 500) {
                                    return 'Please enter a value greater than 500 or review your answer to the previous question.'
                                  } else if (value && attestation.data.answer09 === 'No' && value > 500) {
                                    return 'Please enter a value less than 501 or review your answer to the previous question.'
                                  }
                                  return true
                                },
                                onChange: (e) => handleQuestionChange(e),
                              })}
                              id={`answer10_${data.reportingUnit}`}
                              name="answer10"
                              className="usa-input"
                              type="number"
                              min="0"
                              step="1"
                              aria-describedby="answer10-error-alert"
                            />
                          </div>
                        </div>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer10 ? 'usa-fieldset display-block' : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question11_${data.reportingUnit}`}
                      >
                        <FormGroup error={errors.answer11}>
                          <Label className="usa-label text-bold" htmlFor={`answer11_${data.reportingUnit}`}>
                            {config?.attestation?.question11}
                          </Label>
                          <span id="answer11-instructions" className="usa-small">
                            This count should include all enrollees, not just survey eligible enrollees, from both SHOP
                            and individual markets.
                          </span>
                          {errors.answer11 && (
                            <ErrorMessage id="answer11-error-alert">{errors.answer11.message}</ErrorMessage>
                          )}
                          <div className="display-flex">
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer11', { onChange: (e) => handleQuestionChange(e) })}
                                id={`answer11-no_${data.reportingUnit}`}
                                name="answer11"
                                className="usa-radio__input"
                                type="radio"
                                value="No"
                                aria-describedby="answer11-instructions answer07-error-alert"
                              />
                              <label className="usa-radio__label" htmlFor={`answer11-no_${data.reportingUnit}`}>
                                No
                              </label>
                            </div>
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer11', { onChange: (e) => handleQuestionChange(e) })}
                                id={`answer11-yes_${data.reportingUnit}`}
                                name="answer11"
                                className="usa-radio__input"
                                type="radio"
                                value="Yes"
                                aria-describedby="answer11-instructions answer07-error-alert"
                              />
                              <label className="usa-radio__label" htmlFor={`answer11-yes_${data.reportingUnit}`}>
                                Yes
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer11 ? 'usa-fieldset display-block' : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question12_${data.reportingUnit}`}
                      >
                        <FormGroup error={errors.question12}>
                          <Label className="usa-label text-bold" htmlFor={`answer12_${data.reportingUnit}`}>
                            {config?.attestation?.question12}
                          </Label>
                          <span id="answer11-instructions" className="usa-small">
                            This count should include all enrollees, not just survey eligible enrollees, from both SHOP
                            and individual markets.
                          </span>
                          {errors.answer12 && (
                            <ErrorMessage id="answer12-error-alert">{errors.answer12.message}</ErrorMessage>
                          )}
                          <div className="display-flex">
                            <div className="usa-text margin-right-3">
                              <input
                                {...register('answer12', {
                                  validate: (value) => {
                                    if (value && attestation.data.answer11 === 'Yes' && value <= 500) {
                                      return 'Please enter a value greater than 500 or review your answer to the previous question.'
                                    } else if (value && attestation.data.answer11 === 'No' && value > 500) {
                                      return 'Please enter a value less than 501 or review your answer to the previous question.'
                                    }
                                    return true
                                  },
                                  onChange: (e) => handleQuestionChange(e),
                                })}
                                id={`answer12_${data.reportingUnit}`}
                                name="answer12"
                                className="usa-input"
                                type="number"
                                min="0"
                                step="1"
                                aria-describedby="answer12-error-alert"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer12 ? 'usa-fieldset display-block' : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question13_${data.reportingUnit}`}
                      >
                        <FormGroup error={errors.question13}>
                          <Label className="usa-label text-bold" htmlFor={`answer13_${data.reportingUnit}`}>
                            {config?.attestation?.question13}
                          </Label>
                          <div className="display-flex">
                            <div className="margin-right-3">
                              <select
                                {...register('answer13', { onChange: (e) => handleQuestionChange(e) })}
                                className="usa-select"
                                id={`answer13_${data.reportingUnit}`}
                                name="answer13"
                              >
                                <option value="">- Please select a year -</option>
                                <option value="2025">2025</option>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                                <option value="2022">2022</option>
                                <option value="2021">2021</option>
                                <option value="2020">2020</option>
                                <option value="2019">2019</option>
                                <option value="2018">2018</option>
                                <option value="2017">2017</option>
                                <option value="2016">2016</option>
                                <option value="2015">2015</option>
                                <option value="2014">2014</option>
                              </select>
                            </div>
                          </div>
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer13 ? 'usa-fieldset display-block' : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question14_${data.reportingUnit}`}
                      >
                        <FormGroup error={errors.answer14}>
                          <Label className="usa-label text-bold" htmlFor={`answer14_${data.reportingUnit}`}>
                            {config?.attestation?.question14}
                          </Label>
                          <span id="answer14-instructions" className="usa-small text-wrap">
                            See Exhibit 1 in the 2025 Operational Instructions for definitions of operational, not
                            operational, and discontinued. This field asks whether this reporting unit has been
                            operational on the Exchange in 2023, 2024, and 2025 for the exact same product type. It does
                            not ask whether this reporting unit has reported or submitted QRS clinical measure data and
                            QHP Enrollee Survey response data to CMS for three consecutive years.
                          </span>
                          {errors.answer14 && (
                            <ErrorMessage id="answer14-error-alert">{errors.answer14.message}</ErrorMessage>
                          )}
                          <div className="display-flex">
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer14', { onChange: (e) => handleQuestionChange(e) })}
                                id={`answer14-no_${data.reportingUnit}`}
                                name="answer14"
                                className="usa-radio__input"
                                type="radio"
                                value="No"
                                aria-labelledby={`answer14-no-label_${data.reportingUnit}`}
                                aria-describedby="answer14-instructions answer14-error-alert"
                              />
                              <label
                                id={`answer14-no-label_${data.reportingUnit}`}
                                className="usa-radio__label"
                                htmlFor={`answer14-no_${data.reportingUnit}`}
                              >
                                No
                              </label>
                            </div>
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer14', {
                                  validate: (value) => {
                                    //Only validate if elgible

                                    if (
                                      value &&
                                      attestation.data.answer01 === 'Yes' &&
                                      //attestation.data.answer04 === 'No' &&
                                      attestation.data.answer09 === 'Yes' &&
                                      attestation.data.answer11 === 'Yes'
                                    ) {
                                      if (
                                        value === 'Yes' &&
                                        (attestation.data.answer13 === '2024' || attestation.data.answer13 === '2025')
                                      ) {
                                        return 'Please select No or select a year prior to 2024 for the the previous question.'
                                      } else if (
                                        value === 'No' &&
                                        (attestation.data.answer13 === '2023' ||
                                          attestation.data.answer13 === '2022' ||
                                          attestation.data.answer13 === '2021' ||
                                          attestation.data.answer13 === '2020' ||
                                          attestation.data.answer13 === '2019' ||
                                          attestation.data.answer13 === '2018' ||
                                          attestation.data.answer13 === '2017' ||
                                          attestation.data.answer13 === '2016' ||
                                          attestation.data.answer13 === '2015' ||
                                          attestation.data.answer13 === '2014')
                                      ) {
                                        return 'Please select Yes or select a year more recent than 2023 for the the previous question.'
                                      }
                                    }
                                    return true
                                  },
                                  onChange: (e) => handleQuestionChange(e),
                                })}
                                id={`answer14-yes_${data.reportingUnit}`}
                                name="answer14"
                                className="usa-radio__input"
                                type="radio"
                                value="Yes"
                                aria-labelledby={`answer14-yes-label_${data.reportingUnit}`}
                                aria-describedby="answer14-instructions answer14-error-alert"
                              />
                              <label
                                id={`answer14-yes-label_${data.reportingUnit}`}
                                className="usa-radio__label"
                                htmlFor={`answer14-yes_${data.reportingUnit}`}
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={
                          (attestation.data.answer01 === 'No' && attestation.data.answer02 === 'Yes') ||
                          (attestation.data.answer14 &&
                            attestation.data.answer01 === 'Yes' &&
                            attestation.data.answer04 === 'Yes') ||
                          (attestation.data.answer14 &&
                            attestation.data.answer01 === 'Yes' &&
                            attestation.data.answer04 === 'No' &&
                            (attestation.data.answer09 === 'No' || attestation.data.answer11 === 'No'))
                            ? 'usa-fieldset display-block'
                            : 'usa-fieldset display-none'
                        }
                      >
                        <FormGroup error={errors.ineligibilityReason}>
                          <Label
                            htmlFor={`ineligibility-reason_${data.reportingUnit}`}
                            className="text-bold"
                            error={errors.ineligibilityReason}
                          >
                            Based on your selection, this reporting unit is ineligible. Please confirm your reason for
                            ineligibility:{' '}
                            <span className="usa-hint usa-hint--required" aria-hidden="true">
                              *
                            </span>
                          </Label>
                          {errors.ineligibilityReason && (
                            <ErrorMessage id="ineligibility-reason-error-alert">
                              {errors.ineligibilityReason.message}
                            </ErrorMessage>
                          )}
                          <select
                            {...register('ineligibilityReason', {
                              validate: (value) => {
                                if (
                                  !value &&
                                  ((attestation.data.answer01 === 'No' && attestation.data.answer02 === 'Yes') ||
                                    (attestation.data.answer14 &&
                                      attestation.data.answer01 === 'Yes' &&
                                      attestation.data.answer04 === 'Yes') ||
                                    (attestation.data.answer14 &&
                                      attestation.data.answer01 === 'Yes' &&
                                      attestation.data.answer04 === 'No' &&
                                      (attestation.data.answer09 === 'No' || attestation.data.answer11 === 'No')))
                                ) {
                                  return 'Please select an Ineligibility Reason'
                                }
                                return true
                              },
                              onChange: (e) => handleQuestionChange(e),
                            })}
                            className="usa-select"
                            id={`ineligibility-reason_${data.reportingUnit}`}
                            name="ineligibilityReason"
                            aria-describedby="ineligibility-reason-error-alert"
                            aria-required="true"
                          >
                            <option value="">- Please Select an Ineligibility Reason -</option>
                            {attestation.data.answer01 === 'No' && attestation.data.answer02 === 'Yes' ? (
                              <option
                                key={1}
                                value="Reporting Unit Did Not Operate in Current Year (2024) but Will Operate in Upcoming Year (2025)"
                              >
                                Reporting Unit Did Not Operate in Current Year (2024) but Will Operate in Upcoming Year
                                (2025)
                              </option>
                            ) : attestation.data.answer04 === 'Yes' ? (
                              <option key={3} value="Reporting Unit Discontinued Prior to June 15, 2025">
                                Reporting Unit Discontinued Prior to June 15, 2025
                              </option>
                            ) : attestation.data.answer09 === 'No' && attestation.data.answer11 === 'No' ? (
                              <option
                                key={3}
                                value="Reporting Unit Operates in Current and Upcoming Years but Did Not Meet Minimum Enrollment Threshold as of July 1, 2024, AND January 1, 2025"
                              >
                                Reporting Unit Operates in Current and Upcoming Years but Did Not Meet Minimum
                                Enrollment Threshold as of July 1, 2024, AND January 1, 2025
                              </option>
                            ) : attestation.data.answer09 === 'No' ? (
                              <option
                                key={3}
                                value="Reporting Unit Operates in Current and Upcoming Years but Did Not Meet Minimum Enrollment Threshold as of July 1, 2024"
                              >
                                Reporting Unit Operates in Current and Upcoming Years but Did Not Meet Minimum
                                Enrollment Threshold as of July 1, 2024
                              </option>
                            ) : attestation.data.answer11 === 'No' ? (
                              <option
                                key={3}
                                value="Reporting Unit Operates in Current and Upcoming Years but Did Not Meet Minimum Enrollment Threshold as of January 1, 2025"
                              >
                                Reporting Unit Operates in Current and Upcoming Years but Did Not Meet Minimum
                                Enrollment Threshold as of January 1, 2025
                              </option>
                            ) : (
                              ''
                            )}
                            <option value="Other">Other</option>
                          </select>
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.ineligibilityReason === 'Other'
                            ? 'usa-fieldset display-block'
                            : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-note_${data.note}`}
                      >
                        <FormGroup error={errors.note}>
                          <Label className="usa-label text-bold" htmlFor={`note_${data.reportingUnit}`}>
                            Other Ineligibility Reason:{' '}
                            <span className="usa-hint usa-hint--required" aria-hidden="true">
                              *
                            </span>
                          </Label>
                          {errors.note && <ErrorMessage id="note-error-alert">{errors.note.message}</ErrorMessage>}
                          <div className="display-flex">
                            <div className="usa-text margin-right-3">
                              <textarea
                                {...register('note', {
                                  validate: (value) => {
                                    if (!value && attestation.data.ineligibilityReason === 'Other') {
                                      return 'Please enter your ineligibility reason'
                                    }
                                    return true
                                  },
                                  onChange: (e) => handleQuestionChange(e),
                                })}
                                className="usa-textarea"
                                id={`note_${data.reportingUnit}`}
                                name="note"
                                aria-describedby="note-error-alert"
                                aria-required="true"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer01 === 'Yes' &&
                          attestation.data.answer04 === 'No' &&
                          attestation.data.answer09 === 'Yes' &&
                          attestation.data.answer11 === 'Yes' &&
                          attestation.data.answer14
                            ? 'usa-fieldset display-block'
                            : 'usa-fieldset display-none'
                        }
                        aria-labelledby={`legend-vendor_${data.reportingUnit}`}
                      >
                        <FormGroup error={errors.vendor}>
                          <Label className="usa-label text-bold" htmlFor={`vendor_${data.reportingUnit}`}>
                            Based on your selection, this reporting unit is eligible. Please confirm your Authorized
                            Vendor:{' '}
                            <span className="usa-hint usa-hint--required" aria-hidden="true">
                              *
                            </span>
                          </Label>
                          {errors.vendor && (
                            <ErrorMessage id="vendor-error-alert">{errors.vendor.message}</ErrorMessage>
                          )}
                          {config?.vendors.length > 0 ? (
                            <>
                              <select
                                {...register('vendor', {
                                  validate: (value) => {
                                    if (
                                      !value &&
                                      attestation.data.answer01 === 'Yes' &&
                                      attestation.data.answer04 === 'No' &&
                                      attestation.data.answer09 === 'Yes' &&
                                      attestation.data.answer11 === 'Yes' &&
                                      attestation.data.answer14
                                    ) {
                                      return 'Please select an Authorized Vendor'
                                    }
                                    return true
                                  },
                                  onChange: (e) => handleQuestionChange(e),
                                })}
                                className="usa-select"
                                id={`vendor_${data.reportingUnit}`}
                                name="vendor"
                                aria-describedby="vendor-error-alert"
                                aria-required="true"
                              >
                                <option value="">- Please Select an Approved Vendor -</option>
                                {config.vendors.map((option, index) => (
                                  <option key={index} value={option.name_}>
                                    {option.name_}
                                  </option>
                                ))}
                              </select>
                            </>
                          ) : (
                            <Alert type="warning" headingLevel="h4" slim className="margin-top-2">
                              We&apos;re sorry, there are currently no approved vendors available for selection. Please
                              check back later.
                            </Alert>
                          )}
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={attestation.data.vendor ? 'usa-fieldset display-block' : 'usa-fieldset display-none'}
                        role="radiogroup"
                        aria-labelledby={`legend-question15_${data.reportingUnit}`}
                      >
                        <FormGroup error={errors.answer15}>
                          <Label className="usa-label text-bold" htmlFor={`answer15_${data.reportingUnit}`}>
                            {config?.attestation?.question15}
                          </Label>
                          {errors.answer15 && (
                            <ErrorMessage id="answer15-error-alert">{errors.answer15.message}</ErrorMessage>
                          )}
                          <div className="display-flex">
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer15', { onChange: (e) => handleQuestionChange(e) })}
                                id={`answer15-no_${data.reportingUnit}`}
                                name="answer15"
                                className="usa-radio__input"
                                type="radio"
                                value="No"
                                aria-labelledby={`answer15-no-label_${data.reportingUnit}`}
                                aria-describedby="answer15-instructions answer15-error-alert"
                              />
                              <label
                                id={`answer15-no-label_${data.reportingUnit}`}
                                className="usa-radio__label"
                                htmlFor={`answer15-no_${data.reportingUnit}`}
                              >
                                No
                              </label>
                            </div>
                            <div className="usa-radio margin-right-3">
                              <input
                                {...register('answer15', {
                                  validate: (value) => {
                                    if (attestation.data.vendor !== '' && !value) {
                                      return 'Please select Yes or No.'
                                    }
                                    return true
                                  },
                                  onChange: (e) => handleQuestionChange(e),
                                })}
                                id={`answer15-yes_${data.reportingUnit}`}
                                name="answer15"
                                className="usa-radio__input"
                                type="radio"
                                value="Yes"
                                aria-labelledby={`answer15-yes-label_${data.reportingUnit}`}
                                aria-describedby="answer15-instructions answer15-error-alert"
                              />
                              <label
                                id={`answer15-yes-label_${data.reportingUnit}`}
                                className="usa-radio__label"
                                htmlFor={`answer15-yes_${data.reportingUnit}`}
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        </FormGroup>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.answer15 === 'No'
                            ? 'usa-fieldset display-block'
                            : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-question02_error`}
                      >
                        <Alert className="margin-bottom-3" type="warning" headingLevel="h4">
                          CMS requires that QHP issuers contract with a HEDIS® Compliance Auditor to validate the QHP
                          Enrollee Survey sample frame and the QRS clinical measure data.
                        </Alert>
                      </fieldset>
                      <fieldset
                        className={
                          attestation.data.ineligibilityReason || attestation.data.answer15
                            ? 'usa-fieldset display-block'
                            : 'usa-fieldset display-none'
                        }
                        role="radiogroup"
                        aria-labelledby={`legend-answer16_${data.answer16}`}
                      >
                        <FormGroup error={errors.answer16}>
                          <Label className="usa-label text-bold" htmlFor={`answer16_${data.reportingUnit}`}>
                            Additional Comments (optional):
                          </Label>
                          {errors.answer16 && (
                            <ErrorMessage id="additionalcomment-error-alert">{errors.answer16.message}</ErrorMessage>
                          )}
                          <div className="display-flex">
                            <div className="usa-text margin-right-3">
                              <textarea
                                {...register('answer16', { onChange: (e) => handleQuestionChange(e) })}
                                className="usa-textarea"
                                id={`answer16_${data.reportingUnit}`}
                                name="answer16"
                                aria-describedby="additionalcomment-error-alert"
                                aria-required="true"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </fieldset>
                      <ButtonGroup>
                        <Button type="submit">
                          {isSubmitting ? (
                            <>
                              <Spinner />
                              Saving
                            </>
                          ) : (
                            'Save'
                          )}
                        </Button>
                        <ModalToggleButton modalRef={modalRef} opener outline>
                          Cancel
                        </ModalToggleButton>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </>
              )}
              {config?.vendorSelection === 'CLOSED' && (
                <>
                  <Grid row gap>
                    <Grid tablet={{ col: true }}>
                      <Alert type="info" headingLevel="h4" slim>
                        Issuer attestation and vendor selection is currently closed.
                      </Alert>
                      <p>
                        <em>To the best of my knowledge, I can demonstrate my reporting unit (RU):</em>
                      </p>
                      <ul className="qhp-list">
                        {attestation.data.answer01 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question01}</strong>
                            <br />
                            {attestation.data.answer01}
                          </li>
                        )}
                        {attestation.data.answer02 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question02}</strong>
                            <br />
                            {attestation.data.answer02}
                          </li>
                        )}
                        {attestation.data.answer03 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question03}</strong>
                            <br />
                            {attestation.data.answer03}
                          </li>
                        )}
                        {attestation.data.answer04 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question04}</strong>
                            <br />
                            {attestation.data.answer04}
                          </li>
                        )}
                        {attestation.data.answer05 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question05}</strong>
                            <br />
                            {attestation.data.answer05}
                          </li>
                        )}
                        {attestation.data.answer06 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question06}</strong>
                            <br />
                            {attestation.data.answer06}
                          </li>
                        )}
                        {attestation.data.answer07 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question07}</strong>
                            <br />
                            {attestation.data.answer07}
                          </li>
                        )}
                        {attestation.data.answer08 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question08}</strong>
                            <br />
                            {attestation.data.answer08}
                          </li>
                        )}
                        {attestation.data.answer09 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question09}</strong>
                            <br />
                            {attestation.data.answer09}
                          </li>
                        )}
                        {attestation.data.answer10 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question10}</strong>
                            <br />
                            {attestation.data.answer10}
                          </li>
                        )}
                        {attestation.data.answer11 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question11}</strong>
                            <br />
                            {attestation.data.answer11}
                          </li>
                        )}
                        {attestation.data.answer12 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question12}</strong>
                            <br />
                            {attestation.data.answer12}
                          </li>
                        )}
                        {attestation.data.answer13 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question13}</strong>
                            <br />
                            {attestation.data.answer13}
                          </li>
                        )}
                        {attestation.data.ineligibilityReason !== '' && (
                          <li>
                            <strong>
                              Based on your selection, this reporting unit ID is ineligible. Please confirm your reason
                              for ineligibility:
                            </strong>
                            <br />
                            {attestation.data.ineligibilityReason !== 'Other'
                              ? attestation.data.ineligibilityReason
                              : attestation.data.note === ''
                              ? 'Other Selected - No Other Reason Entered'
                              : attestation.data.note}
                          </li>
                        )}
                        {(attestation.data.answer13 === 'Yes' || attestation.data.answer13 === 'No') && (
                          <li>
                            <strong>
                              Based on your selection, this reporting unit ID is eligible. Please confirm your
                              Authorized Vendor:
                            </strong>
                            <br />
                            {attestation.data.vendor !== '' ? attestation.data.vendor : 'None Selected'}
                          </li>
                        )}
                        {attestation.data.answer14 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question14}</strong>
                            <br />
                            {attestation.data.answer14}
                          </li>
                        )}
                        {attestation.data.answer15 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question15}</strong>
                            <br />
                            {attestation.data.answer15}
                          </li>
                        )}
                        {attestation.data.answer16 !== '' && (
                          <li>
                            <strong>{config?.attestation?.question16}</strong>
                            <br />
                            {attestation.data.answer16}
                          </li>
                        )}
                      </ul>
                    </Grid>
                  </Grid>
                </>
              )}
            </GridContainer>
          </form>

          <Modal
            ref={modalRef}
            forceAction
            aria-labelledby="attestation-cancel-heading"
            aria-describedby="attestation-cancel-description"
            id="attestation-cancel"
          >
            <ModalHeading id="attestation-cancel-heading">Confirm Cancel</ModalHeading>
            <div className="usa-prose">
              <p id="attestation-cancel-description">
                If you cancel your changes, the attestation questions will be returned to their last saved state.
                <br />
                <br />
                Do you wish to cancel your changes?
              </p>
            </div>
            <ModalFooter>
              <ButtonGroup>
                <Button onClick={handleCancel} aria-controls="attestation-cancel">
                  Yes
                </Button>
                <ModalToggleButton modalRef={modalRef} closer outline>
                  No
                </ModalToggleButton>
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  )
}

IssuerAttestation.propTypes = {
  data: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  setStatusMessage: PropTypes.func.isRequired,
}

export default IssuerAttestation
