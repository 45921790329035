import { Helmet } from 'react-helmet-async'
import { Link, Grid, GridContainer } from '@trussworks/react-uswds'
import Main from '../components/Main'

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <GridContainer>
        <Grid row>
          <Grid tablet={{ col: true }}>
            <Main>
              <section className="usa-prose padding-y-4">
                <h1>Page Not Found</h1>
                <p className="usa-intro">
                  We&apos;re sorry, we can&apos;t find the page you&apos;re looking for. It might have been removed,
                  changed its name, or is otherwise unavailable.
                </p>
                <p>If you typed the URL directly, check your spelling and capitalization.</p>
                <p>
                  Visit our homepage for helpful tools and resources, or contact us and we&apos;ll point you in the
                  right direction.
                </p>
                <Link className="usa-button" variant="unstyled" href={'/'}>
                  Go to Home Page
                </Link>
                <Link className="usa-button usa-button--outline" variant="unstyled" href={'contact'}>
                  Contact Us
                </Link>
                <p className="text-base">
                  <strong>Error code:</strong> 404
                </p>
              </section>
            </Main>
          </Grid>
        </Grid>
      </GridContainer>
    </>
  )
}

export default NotFoundPage
