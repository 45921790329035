import { Helmet } from 'react-helmet-async'
import { Grid, GridContainer, Link } from '@trussworks/react-uswds'

const ContactConfirmPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact Confirmation</title>
      </Helmet>
      <GridContainer>
        <Grid row gap>
          <Grid tablet={{ col: true }}>
            <section className="usa-prose usa-prose--wide padding-y-4">
              <h1>Thank You</h1>
              <p>
                Thank you for contacting the Qualified Health Plan Enrollee Experience Survey System. We have received
                your message.
              </p>
              <Link className="usa-button" variant="unstyled" href="/">
                Go to Home Page
              </Link>
            </section>
          </Grid>
        </Grid>
      </GridContainer>
    </>
  )
}

export default ContactConfirmPage
