import { createContext, useContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { API, Auth } from 'aws-amplify'
import { decryptUser } from '../lib/ciphertextLib'
import config from '../config'

const AuthStateContext = createContext()
const AuthDispatchContext = createContext()

let currentUser = sessionStorage.getItem('currentUser')
let isLoading = currentUser ? JSON.parse(sessionStorage.getItem('currentUser')).isLoading : true
let isLoggedIn = currentUser ? JSON.parse(sessionStorage.getItem('currentUser')).isLoggedIn : false
let isIdmSignIn = currentUser ? JSON.parse(sessionStorage.getItem('currentUser')).isIdmSignIn : false
let info = currentUser ? JSON.parse(sessionStorage.getItem('currentUser')).info : null

const initialState = {
  isLoading: isLoading ? true : false,
  isLoggedIn: isLoggedIn ? true : false,
  isIdmSignIn: isIdmSignIn ? true : false,
  info: info || null,
  loginError: null,
}

// Actions
const userLogin = async (dispatch, payload) => {
  try {
    dispatch({ type: 'REQUEST_LOGIN' })
    let response = await Auth.signIn(payload.username, payload.password)
    if (response.username) {
      const userEncrypted = await API.get('qhp-survey-auth', '/user/get')
      //Check to see if user in Enabled
      if (userEncrypted.status_ === 'Enabled') {
        const userDecrypted = await decryptUser(userEncrypted)
        dispatch({ type: 'LOGIN_SUCCESS', payload: userDecrypted })
        sessionStorage.setItem(
          'currentUser',
          JSON.stringify({ isLoading: false, isLoggedIn: true, isIdmSignIn: false, info: userDecrypted })
        )
        return userDecrypted
      }
    }

    await Auth.signOut()
    dispatch({ type: 'LOGIN_ERROR', error: { message: 'Error retrieving user data' } })
    return null
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', payload: { userRole: 'NONE' } })
    sessionStorage.setItem(
      'currentUser',
      JSON.stringify({ isLoading: false, isLoggedIn: true, isIdmSignIn: false, info: { userRole: 'NONE' } })
    )
  }
}

const userLoginIdm = async (dispatch) => {
  try {
    dispatch({ type: 'REQUEST_LOGIN_IDM' })
    sessionStorage.setItem(
      'currentUser',
      JSON.stringify({ isLoading: false, isLoggedIn: false, isIdmSignIn: true, info: null })
    )
    await Auth.federatedSignIn({ provider: config.cognito.IDP })
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error })
  }
}

const isAuthenticated = async (dispatch) => {
  // Is user logged into QHP Survey system?
  let user = JSON.parse(sessionStorage.getItem('currentUser')) || {}

  // TODO: Update this to run only once after successful IDM login (using Amplify Hub?)
  if (user.isIdmSignIn && !user.info) {
    user = { ...user, isLoggedIn: true, info: { blah: 'foo' } }
    try {
      const response = await Auth.currentAuthenticatedUser()
      if (response) {
        const userEncrypted = await API.get('qhp-survey-auth', '/user/get')
        const userDecrypted = await decryptUser(userEncrypted)
        dispatch({ type: 'LOGIN_SUCCESS_IDM', payload: userDecrypted })
        user = { ...user, isLoggedIn: true, info: userDecrypted }
        sessionStorage.setItem('currentUser', JSON.stringify(user))
      }
    } catch (error) {
      dispatch({ type: 'LOGIN_ERROR', payload: { userRole: 'NONE' } })
      user = { ...user, isLoggedIn: true, info: { userRole: 'NONE' } }
      sessionStorage.setItem('currentUser', JSON.stringify(user))
    }
  }

  if (user?.isLoggedIn) {
    // Is AWS session also still active?
    try {
      await Auth.currentSession()
      return true
    } catch {
      // Log user out of QHP Survey system if AWS session is not active
      userLogout(dispatch)
      return false
    }
  }

  return false
}

const userLogout = async (dispatch) => {
  dispatch({ type: 'LOGOUT' })
  currentUser = null
  isLoading = false
  isLoggedIn = false
  isIdmSignIn = false
  info = null
  sessionStorage.removeItem('currentUser')
  await Auth.signOut()
  // Clearing of storage objects must be done after Auth.signOut
  sessionStorage.clear()
  localStorage.clear()
}

// Context Providers and Hooks
const useAuth = () => {
  const context = useContext(AuthStateContext)

  // If context is 'undefined', throw an error
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthDispatchProvider')
  }

  return context
}

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState)

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

// Reducer
const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST_LOGIN':
      return {
        ...state,
        isLoading: true,
      }
    case 'REQUEST_LOGIN_IDM':
      return {
        ...state,
        isLoading: true,
        isIdmSignIn: true,
      }
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        info: action.payload,
      }
    case 'LOGIN_SUCCESS_IDM':
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        isIdmSignIn: true,
        info: action.payload,
      }
    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
        isIdmSignIn: false,
        info: null,
      }

    case 'LOGIN_ERROR':
      return {
        ...state,
        isLoading: false,
        loginError: action.payload,
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export { AuthProvider, useAuth, useAuthDispatch, userLogin, userLoginIdm, isAuthenticated, userLogout }
