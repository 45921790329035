import { create } from 'zustand'
import * as uuid from 'uuid'

/**
 * Zustand store to manage the state of global items.
 *
 * @todo Consider implementing Immer for state changes.
 */
const useGlobalStore = create((set) => ({
  notifications: [],
  setNotification: ({ message, heading = null, headingLevel = null, type = null, slim = false, scope = null }) => {
    // Add a notification to the queue
    const id = uuid.v1()
    set((state) => ({
      notifications: [
        ...state.notifications,
        {
          id: id,
          message: message,
          heading: heading,
          headingLevel: headingLevel,
          type: type,
          slim: slim,
          scope: scope,
        },
      ],
    }))
  },
  clearNotifications: () => set(() => ({ notifications: [] })),
}))

export default useGlobalStore
