import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'
import {
  Alert,
  Button,
  ButtonGroup,
  Checkbox,
  Dropdown,
  ErrorMessage,
  Fieldset,
  Form,
  FormGroup,
  Grid,
  GridContainer,
  Label,
  Modal,
  ModalHeading,
  ModalFooter,
  ModalToggleButton,
  Tag,
  Textarea,
} from '@trussworks/react-uswds'
import { useForm, Controller } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import Spinner from './Spinner'
import classNames from 'classnames'

const AnalystSurveyNotes = ({ data, analyst, updatedFile, handleSave, setStatusMessage, handleDisableCollapse }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [submitMessage, setSubmitMessage] = useState(null)
  const [showFailureReasons, setShowFailureReasons] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  // Because of some quirks with React Hook Form, keep track of some undo information
  const [undo, setUndo] = useState({ showFailureReasons: false, showDetails: false })

  const modalRef = useRef()

  const {
    register,
    reset,
    control,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting, isSubmitSuccessful },
  } = useForm({
    reValidateMode: 'onSubmit',
  })

  useEffect(() => {
    setIsLoading(true)
    const onLoad = () => {
      if (data.status_ === 'Failed Validation') {
        setShowFailureReasons(true)
        setUndo((prev) => {
          return { ...prev, showFailureReasons: true }
        })
      }

      if (data.note.includes('Other')) {
        setShowDetails(true)
        setUndo((prev) => {
          return { ...prev, showDetails: true }
        })
      }

      if (data.name_ === updatedFile) {
        setSubmitMessage({ type: 'success', message: 'File validation information saved successfully.' })
        const rowId = 'row-' + (updatedFile.substring(0, updatedFile.lastIndexOf('.')) || updatedFile)
        const row = document.getElementById(rowId)
        row.scrollIntoView({ behavior: 'smooth' })
      }

      setIsLoading(false)
    }

    onLoad()
  }, [])

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true })
    }
  }, [isSubmitSuccessful, reset])

  useEffect(() => {
    const id = getValues('id')
    if (isDirty) {
      handleDisableCollapse(id, true)
    } else {
      handleDisableCollapse(id, false)
    }
  }, [isDirty])

  useEffect(() => {
    setStatusMessage(submitMessage?.message)
  }, [submitMessage])

  const onSubmit = async (data) => {
    setSubmitMessage(null)

    data['note'] = []
    if (data.status_ === 'Failed Validation') {
      if (data.failureReasons.includes('Other')) {
        // Combine failureReasons and details form fields to create note data
        data.failureReasons.push(data.details)
      }
      // Remove "falsy" values from array
      const filteredNotes = data.failureReasons.filter((val) => val)
      data['note'] = filteredNotes
    }
    // Don't send failureReasons or details to API to be saved
    delete data.failureReasons
    delete data.details

    try {
      await API.post('qhp-survey-auth', '/analyst/survey/update', { body: data })
      handleSave(data)
      setSubmitMessage({ type: 'success', message: 'File validation information saved successfully.' })
    } catch (error) {
      setSubmitMessage({
        type: 'error',
        message: "We're sorry, there was a problem saving your file validation information.",
      })
    }
  }

  const validateFailureReasons = () => {
    const reasons = getValues('failureReasons')
    const anySelected = !reasons.every((el) => !el)
    if (anySelected || !showFailureReasons) {
      return true
    }
    setError('failureReasons', { type: 'validate', message: 'Please select at least one Validation Failure Reason' })
    return false
  }

  const validateDetails = (value) => {
    if (value !== '' || !showDetails) {
      return true
    }
    return false
  }

  const toggleFailureReasons = (show) => {
    if (show) {
      setShowFailureReasons(true)
    } else {
      clearErrors(['failureReasons', 'details'])
      setShowFailureReasons(false)
    }
  }

  const toggleDetails = (show) => {
    if (show) {
      setShowDetails(true)
    } else {
      clearErrors('details')
      setShowDetails(false)
    }
  }

  const handleStatusChange = (event) => {
    toggleFailureReasons(event.target.value === 'Failed Validation')
  }

  const handleReasonChange = (event) => {
    if (event.target.value === 'Other') {
      toggleDetails(event.target.checked)
    }
  }

  const handleCancel = () => {
    setSubmitMessage(null)
    modalRef.current.toggleModal()
    reset()
    toggleFailureReasons(undo.showFailureReasons)
    toggleDetails(undo.showDetails)
  }

  return (
    <>
      {isLoading ? (
        <Skeleton height="8rem" className="margin-bottom-3" />
      ) : (
        <>
          <Form
            className="usa-form--full padding-y-4 border-bottom border-base-lighter"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input {...register('id')} id={`file_${data.id}`} name="id" type="hidden" value={data.id} />
            <input {...register('name_')} id={`name_${data.id}`} name="name_" type="hidden" value={data.name_} />
            <input
              {...register('vendorId')}
              id={`vendorId_${data.id}`}
              name="vendorId"
              type="hidden"
              value={data.vendorId}
            />
            <GridContainer className="flex-fill">
              <Grid row gap>
                <Grid tablet={{ col: true }}>
                  {submitMessage && (
                    <Alert type={submitMessage.type} headingLevel="h3" slim>
                      {submitMessage.message}
                    </Alert>
                  )}
                </Grid>
              </Grid>
              <Grid row gap>
                <Grid tablet={{ col: true }} aria-describedby={`modified_${data.reportingUnit}`}>
                  <div id={`modified_${data.reportingUnit}`} className="float-right margin-top-2">
                    {isDirty && (
                      <Tag className="usa-tag--error">
                        <span className="usa-sr-only">This CSV upload record has been </span>modified{' '}
                        <span className="usa-sr-only">
                          Be sure to save or cancel your changes before leaving the page.
                        </span>
                      </Tag>
                    )}
                  </div>
                  <p id={`instruction_${data.reportingUnit}`}>
                    <em>
                      Update <strong>file status</strong> and select at least one{' '}
                      <strong>validation failure reason</strong>, if applicable. Required fields are marked with an
                      asterisk (
                      <span className="usa-hint usa-hint--required" aria-hidden="true">
                        *
                      </span>
                      ).
                    </em>
                  </p>
                </Grid>
              </Grid>
              <Grid row gap>
                <Grid tablet={{ col: true }}>
                  <FormGroup className="margin-top-0" error={errors.status_}>
                    <Label htmlFor={`status_${data.id}`} className="text-bold" error={errors.status_}>
                      Status{' '}
                      <span className="usa-hint usa-hint--required" aria-hidden="true">
                        *
                      </span>
                    </Label>
                    {errors.status_ && <ErrorMessage id="status-error-alert">{errors.status_.message}</ErrorMessage>}
                    <Controller
                      control={control}
                      name="status_"
                      defaultValue={data.status_}
                      rules={{
                        required: 'Please select a status',
                      }}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <Dropdown
                          {...field}
                          inputRef={ref}
                          id={`status_${data.id}`}
                          className={classNames({ 'usa-input--error': errors.status_ })}
                          aria-describedby={`status-error-alert instruction_${data.reportingUnit} modified_${data.reportingUnit}`}
                          aria-required="true"
                          onChange={(event) => {
                            onChange(event)
                            handleStatusChange(event)
                          }}
                        >
                          <option value="">- Please Select a File Status -</option>
                          <option value="Pending Validation">Pending Validation</option>
                          <option value="Passed Validation">Passed Validation</option>
                          <option value="Failed Validation">Failed Validation</option>
                        </Dropdown>
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid row gap className={showFailureReasons ? 'display-block' : 'display-none'}>
                <Grid tablet={{ col: true }}>
                  <FormGroup error={errors.failureReasons}>
                    <Fieldset>
                      <legend className="usa-legend text-bold margin-top-0">
                        Validation Failure Reasons{' '}
                        <span className="usa-hint usa-hint--required" aria-hidden="true">
                          *
                        </span>
                        <span className="usa-sr-only">Required</span>
                      </legend>
                      {errors.failureReasons && (
                        <ErrorMessage id="failure-reasons-error-alert">{errors.failureReasons.message}</ErrorMessage>
                      )}
                      {analyst.config.failedValidationReasons.map((reason, index) => (
                        <Controller
                          key={`failure-reason-${data.id}-${index}`}
                          control={control}
                          name={`failureReasons.${index}`}
                          rules={{
                            validate: validateFailureReasons,
                          }}
                          defaultValue={data.note.includes(reason) ? reason : false}
                          render={({ field: { ref, value, onChange, ...field } }) => (
                            <Checkbox
                              {...field}
                              inputRef={ref}
                              id={`failure-reason-${data.id}-${index}`}
                              label={reason}
                              defaultValue={reason}
                              checked={value === reason}
                              onChange={(event) => {
                                onChange(event.target.checked ? event.target.value : false)
                                handleReasonChange(event)
                              }}
                            />
                          )}
                        />
                      ))}
                    </Fieldset>
                  </FormGroup>
                  <FormGroup error={errors.details} className={showDetails ? 'display-block' : 'display-none'}>
                    <Label htmlFor={`details_${data.id}`} className="text-bold" error={errors.details}>
                      Additional Details{' '}
                      <span className="usa-hint usa-hint--required" aria-hidden="true">
                        *
                      </span>
                    </Label>
                    <span className="usa-hint font-body-2xs" id="details-hint">
                      Please provide additional details if selecting &quot;Other&quot; as a validation failure reason.
                      Maximum of 1000 characters allowed.
                    </span>
                    {errors.details && <ErrorMessage id="details-error-alert">{errors.details.message}</ErrorMessage>}
                    <Controller
                      control={control}
                      name="details"
                      defaultValue={data.note.includes('Other') ? data.note[data.note.length - 1] : ''}
                      rules={{
                        maxLength: { value: 1000, message: 'A maximum of 1000 characters is allowed' },
                        validate: (value) => validateDetails(value) || 'Please provide additional details',
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <Textarea
                          {...field}
                          inputRef={ref}
                          id={`details_${data.id}`}
                          name="details"
                          maxLength="1000"
                          className={classNames({ 'usa-input--error': errors.details })}
                          aria-describedby="details-hint details-error-alert"
                          aria-required="true"
                        />
                      )}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid row gap>
                <Grid tablet={{ col: true }}>
                  <ButtonGroup>
                    <Button type="submit" onClick={() => clearErrors()}>
                      {isSubmitting ? (
                        <>
                          <Spinner />
                          Saving
                        </>
                      ) : (
                        'Save'
                      )}
                    </Button>
                    <ModalToggleButton modalRef={modalRef} opener outline>
                      Cancel
                    </ModalToggleButton>
                  </ButtonGroup>
                </Grid>
              </Grid>
            </GridContainer>
          </Form>
          <Modal
            ref={modalRef}
            forceAction
            aria-labelledby="attestation-cancel-heading"
            aria-describedby="attestation-cancel-description"
            id="attestation-cancel"
          >
            <ModalHeading id="attestation-cancel-heading">Confirm Cancel</ModalHeading>
            <div className="usa-prose">
              <p id="attestation-cancel-description">
                If you cancel your changes, the survey file data will be returned to its last saved state.
                <br />
                <br />
                Do you wish to cancel your changes?
              </p>
            </div>
            <ModalFooter>
              <ButtonGroup>
                <Button onClick={handleCancel}>Yes</Button>
                <ModalToggleButton modalRef={modalRef} closer outline>
                  No
                </ModalToggleButton>
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

AnalystSurveyNotes.propTypes = {
  data: PropTypes.object.isRequired,
  analyst: PropTypes.object.isRequired,
  updatedFile: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  setStatusMessage: PropTypes.func.isRequired,
  handleDisableCollapse: PropTypes.func.isRequired,
}

export default AnalystSurveyNotes
