import PropTypes from 'prop-types'
import { Alert, Grid, GridContainer } from '@trussworks/react-uswds'
import { nl2br, createMarkup } from '../util/html'

const VendorSurveyNotes = ({ data }) => {
  if (data.status_ === 'Failed Validation' && data.note.includes('Other')) {
    const other = data.note.pop()
    data.note[data.note.length - 1] = other
  }

  return (
    <GridContainer className="flex-fill">
      <Grid row gap>
        <Grid tablet={{ col: true }}>
          <div className="margin-y-4">
            {data.note?.length ? (
              <>
                {data.status_ === 'File Error' && (
                  <p>
                    The <strong>{data.name_}</strong> file has failed the initial quality checks for the following
                    reasons. Please correct any errors and upload the file again.
                  </p>
                )}
                {data.status_ === 'Failed Validation' && (
                  <p>
                    The <strong>{data.name_}</strong> file has failed validation for the following reasons. Please
                    correct any errors and upload the file again.
                  </p>
                )}
                <ul className="usa-list">
                  {data.note.map((error, index) => (
                    <li key={index} dangerouslySetInnerHTML={createMarkup(nl2br(error))}></li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <Alert type="info" headingLevel="h4" slim>
                  There is no note information for this record.
                </Alert>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </GridContainer>
  )
}

VendorSurveyNotes.propTypes = {
  data: PropTypes.object.isRequired,
}

export default VendorSurveyNotes
