// import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
// import { Auth } from 'aws-amplify'
import { useAuth } from '../contexts/AuthContext'
import IssuerDashboard from '../components/IssuerDashboard'
import VendorDashboard from '../components/VendorDashboard'
import AnalystDashboard from '../components/AnalystDashboard'
import ManagerDashboard from '../components/ManagerDashboard'
import AdminDashboard from '../components/admin/AdminDashboard'
import NoDashboard from '../components/NoDashboard'

const DashboardPage = () => {
  // Select the appropriate dashboard component based on user role
  const user = useAuth()

  const UserDashboard = () => {
    switch (user.info?.userRole) {
      case 'Issuer':
        return <IssuerDashboard />
      case 'Vendor':
        return <VendorDashboard />
      case 'Analyst':
        return <AnalystDashboard />
      case 'Manager':
        return <ManagerDashboard />
      case 'Administrator':
        return <AdminDashboard />
      default:
        return <NoDashboard />
    }
  }

  return (
    <>
      <Helmet>
        <title>{user.info?.userRole} Dashboard</title>
      </Helmet>
      <UserDashboard />
    </>
  )
}

export default DashboardPage
