import { Helmet } from 'react-helmet-async'
import { Grid, GridContainer } from '@trussworks/react-uswds'
import Main from '../components/Main'

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <GridContainer>
        <Grid row gap>
          <Grid tablet={{ col: true }}>
            <Main>
              <section className="usa-section">
                <div className="usa-prose usa-prose--wide">
                  <h1>Contact</h1>
                  <p>
                    For questions or technical assistance about the Qualified Health Plan Enrollee Experience Survey,
                    please contact us at <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a>.
                  </p>
                </div>
              </section>
            </Main>
          </Grid>
        </Grid>
      </GridContainer>
    </>
  )
}

export default ContactPage
