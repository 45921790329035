import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardGroup,
  CardHeader,
  ComboBox,
  ErrorMessage,
  FormGroup,
  Icon,
  Label,
  Tooltip,
} from '@trussworks/react-uswds'
import Skeleton from 'react-loading-skeleton'
import { useForm, Controller } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { decryptUsers } from '../../../lib/ciphertextLib'
import useAdminStore from '../../../stores/useAdminStore'
import useGlobalStore from '../../../stores/useGlobalStore'
import Notifications from '../../Notifications'
import confirm from '../../ConfirmDialog'

const ReportingUnitUsers = ({ config }) => {
  const data = useAdminStore((state) => state.currentReportingUnit)
  const addReportingUnitUser = useAdminStore((state) => state.addReportingUnitUser)
  const deleteReportingUnitUser = useAdminStore((state) => state.deleteReportingUnitUser)
  const isAddMode = useAdminStore((state) => state.isAddMode)
  const setNotification = useGlobalStore((state) => state.setNotification)
  const clearNotifications = useGlobalStore((state) => state.clearNotifications)
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const setAvailableUsers = useAdminStore((state) => state.setAvailableUsers)
  const reportingUnitUsers = useAdminStore((state) => state.reportingUnitUsers)
  const setReportingUnitUsers = useAdminStore((state) => state.setReportingUnitUsers)
  const [usersOptions, setUsersOptions] = useState([])

  const formRef = useRef(null)

  const {
    register,
    // reset,
    resetField,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      reportingUnitPK: '',
      year_: '',
      user: '',
    },
  })

  const onSubmit = async (data, e) => {
    e.preventDefault()
    clearNotifications()
    try {
      await API.post('qhp-survey-auth', '/attestation/reporting-unit/user/create', { body: data })
      addReportingUnitUser(data.user)
      setNotification({
        message: 'Associated user added successfully.',
        type: 'success',
        scope: 'modal',
        slim: true,
        headingLevel: 'h3',
      })
    } catch (error) {
      setNotification({
        message: "We're sorry, there was a problem adding associated user.",
        type: 'error',
        scope: 'modal',
        slim: true,
      })
    }
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({
        inline: 'start',
        behavior: 'smooth',
      })
    }
  }

  const handleDelete = async (reportingUnitPK, user) => {
    if (
      await confirm({
        dialogTitle: 'Remove User',
        confirmation: `Remove user ${user.nameFirst} ${user.nameLast} (${user.email})?`,
        proceedLabel: 'Remove',
      })
    ) {
      clearNotifications()
      const data = { PK: reportingUnitPK, SK: user.PK }
      try {
        await API.post('qhp-survey-auth', '/attestation/reporting-unit/user/delete', { body: data })
        deleteReportingUnitUser(user.PK)
        setNotification({
          message: 'Associated user removed successfully.',
          type: 'success',
          scope: 'modal',
          slim: true,
          headingLevel: 'h3',
        })
      } catch (error) {
        console.error(error)
        setNotification({
          message: "We're sorry, there was a problem removing associated user.",
          type: 'error',
          scope: 'modal',
          slim: true,
        })
      }
    }
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({
        inline: 'start',
        behavior: 'smooth',
      })
    }
  }

  const sortUsersByLastName = (a, b) => {
    return a.nameLast.localeCompare(b.nameLast)
  }

  useEffect(() => {
    setIsLoading(true)
    setHasError(false)
    const onLoad = async () => {
      if (!isEmpty(data)) {
        resetField('reportingUnitPK', { defaultValue: data.PK })
        resetField('year_', { defaultValue: config?.sys?.year_ })
        // Get a list of available users for current year.
        // Use that data to build a list of available users.
        try {
          const allUsers = await API.get('qhp-survey-auth', '/user/list')
          const decryptedAllUsers = decryptUsers(allUsers)
          decryptedAllUsers.sort(sortUsersByLastName)
          if (decryptedAllUsers.length) {
            const availableUsers = decryptedAllUsers.filter(
              (user) => user.userRole === 'Issuer' && user.status_ === 'Enabled'
            )
            setAvailableUsers(availableUsers)
            const availableUsersOptions = availableUsers.map((user) => ({
              value: user.PK,
              label: `${user.nameFirst} ${user.nameLast} (${user.email})`,
            }))
            setUsersOptions(availableUsersOptions)
          }
        } catch (error) {
          console.error(error)
        }

        if (!isAddMode) {
          try {
            // Get a list of users related to reporting unit.
            const result = await API.get(
              'qhp-survey-auth',
              `/attestation/reporting-unit/${encodeURIComponent(data.id)}/users`
            )
            const decryptedUsers = decryptUsers(result)
            decryptedUsers.sort(sortUsersByLastName)
            setReportingUnitUsers(decryptedUsers)
          } catch (error) {
            setNotification({
              message: "We're sorry, there was a problem loading associated user data.",
              type: 'error',
              scope: 'component',
              slim: true,
            })
            setHasError(true)
            console.error(error)
          }
        }
      }
      setIsLoading(false)
    }

    onLoad()
  }, [data])

  return (
    <>
      {isLoading ? (
        <Skeleton height="20rem" />
      ) : (
        <CardGroup>
          <Card gridLayout={{ tablet: { col: true } }} className="margin-top-3">
            <CardHeader>
              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                <Icon.Topic className="margin-right-05" role="presentation" focusable="false" size={3} /> Associated
                Users
              </h2>
            </CardHeader>
            <CardBody>
              {hasError ? (
                <Notifications scope="component" />
              ) : (
                <>
                  <p>Users associated with this reporting unit</p>
                  {isAddMode ? (
                    <Alert type="info" slim>
                      Please save the new reporting unit before adding users.
                    </Alert>
                  ) : (
                    <div ref={formRef} style={{ scrollMarginTop: '12rem' }}>
                      <form className="usa-form usa-form--full" onSubmit={handleSubmit(onSubmit)} noValidate>
                        <input
                          {...register('reportingUnitPK')}
                          id="reportingUnitPK"
                          name="reportingUnitPK"
                          type="hidden"
                        />
                        <input {...register('year_')} id="year" name="year_" type="hidden" />
                        <div className="display-flex flex-align-end">
                          <div className="flex-grow-1">
                            <FormGroup error={errors.user} className="margin-top-0">
                              <Label htmlFor="availableUsers" className="usa-sr-only">
                                Select a User
                              </Label>
                              {errors.user && <ErrorMessage id="user-error-alert">{errors.user.message}</ErrorMessage>}
                              <Controller
                                control={control}
                                name="user"
                                defaultValue=""
                                rules={{
                                  required: 'Please select a user',
                                }}
                                render={({ field: { onChange, value, ref } }) => (
                                  <>
                                    <ComboBox
                                      inputRef={ref}
                                      id="user"
                                      name="user"
                                      options={usersOptions}
                                      className="margin-top-0"
                                      value={value}
                                      onChange={onChange}
                                    />
                                  </>
                                )}
                              />
                            </FormGroup>
                          </div>
                          <Button type="submit" className="margin-left-1 margin-right-0" style={{ marginTop: '0' }}>
                            Add
                          </Button>
                        </div>
                      </form>
                      {reportingUnitUsers?.length ? (
                        <ul className="qhp-list qhp-list--hover">
                          {reportingUnitUsers.map((user, index) => (
                            <li key={`user-${index}`}>
                              <div className="display-flex flex-align-start">
                                <div>
                                  {user.nameFirst} {user.nameLast}{' '}
                                  {user.status_ === 'Disabled' ? (
                                    <span className="font-body-2xs text-error">
                                      (<Icon.ErrorOutline role="presentation" focusable="false" /> User Disabled)
                                    </span>
                                  ) : null}
                                  <div className="text-base font-body-2xs margin-top-neg-05">{user.email}</div>
                                </div>
                                <div className="margin-left-auto">
                                  <Tooltip
                                    label="Remove User"
                                    type="button"
                                    className="usa-button--unstyled"
                                    style={{ marginTop: '0' }}
                                    onClick={() => {
                                      handleDelete(data.PK, user)
                                    }}
                                  >
                                    <Icon.Delete role="presentation" focusable="false" />{' '}
                                    <span className="usa-sr-only">Remove User</span>
                                  </Tooltip>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <Alert type="info" slim>
                          There are no users associated with this reporting unit.
                        </Alert>
                      )}
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </CardGroup>
      )}
    </>
  )
}

ReportingUnitUsers.propTypes = {
  config: PropTypes.object.isRequired,
  reportingUnitsOptions: PropTypes.array.isRequired,
}

export default ReportingUnitUsers
