import { Helmet } from 'react-helmet-async'
import { Grid, GridContainer, Link } from '@trussworks/react-uswds'
import { useAuth } from '../contexts/AuthContext'
import Main from '../components/Main'

const VendorsPage = () => {
  const user = useAuth()

  return (
    <>
      <Helmet>
        <title>Vendors</title>
      </Helmet>
      <section className="usa-section">
        <GridContainer>
          <Grid row gap>
            <Grid tablet={{ col: 7 }}>
              <Main>
                <div className="usa-prose usa-prose--wide">
                  <h1>Vendors</h1>
                  <h2>QHP Enrollee Survey Vendors</h2>
                  <p>
                    The U.S. Department of Health &amp; Human Services (HHS)-approved vendors administer the QHP
                    Enrollee Experience Survey by using a standardized mixed-mode survey with mail, telephone, and
                    internet survey protocols to facilitate comparison of QHPs both within and across Exchanges. QHP
                    issuers with reporting units required to participate in the QHP Enrollee Survey must contract with
                    an HHS-approved vendor to administer the survey on the issuer&apos;s behalf. A list of the 2025
                    HHS-approved vendors is available on the{' '}
                    <Link
                      variant="external"
                      href={
                        'https://www.cms.gov/Medicare/Quality-Initiatives-Patient-Assessment-Instruments/QualityInitiativesGenInfo/ACA-MQI/ACA-MQI-Landing-Page'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="CMS Marketplace Quality Initiatives (MQI) website, external"
                    >
                      CMS Marketplace Quality Initiatives (MQI) website.
                    </Link>
                    .
                  </p>
                  <p>
                    To maximize the scope of the survey responses, the QHP Enrollee Experience Survey is available in
                    three languages: English, Spanish, and optionally in Chinese. The 2025 survey includes approximately
                    67 items. HHS-approved vendors will draw a random sample of enrollees based on a sample frame
                    provided by each QHP issuer and validated by an independent third party.
                  </p>
                </div>
              </Main>
            </Grid>
            <Grid tablet={{ col: 5 }}>
              <div className="qhp-callout">
                {user.isLoggedIn ? (
                  <div className="usa-prose">
                    <h2>Welcome, {user.info.nameFirst}</h2>
                    <p>Access your QHP Enrollee Experience Survey Dashboard.</p>
                    <Link className="usa-button qhp-link--signin" variant="unstyled" href={'dashboard'}>
                      Go to Dashboard
                    </Link>
                  </div>
                ) : (
                  <div className="usa-prose">
                    <h2>Sign In</h2>
                    <p>QHP Enrollee Survey vendors may log in here to complete the data submission process.</p>
                    <Link className="usa-button qhp-link--signin" variant="unstyled" href={'login'}>
                      Sign In
                    </Link>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </GridContainer>
      </section>
    </>
  )
}

export default VendorsPage
