import { NavLink } from 'react-router-dom'
import { SideNav } from '@trussworks/react-uswds'

const AdminNav = () => {
  const adminNavItems = [
    <NavLink
      key="adminUsers"
      variant="nav"
      className={({ isActive }) => (isActive ? 'usa-current' : null)}
      to="/dashboard/admin/users"
    >
      Users
    </NavLink>,
    <NavLink
      key="adminReportingUnits"
      variant="nav"
      className={({ isActive }) => (isActive ? 'usa-current' : null)}
      to="/dashboard/admin/reporting-units"
    >
      Reporting Units
    </NavLink>,
    <NavLink
      key="adminReports"
      variant="nav"
      className={({ isActive }) => (isActive ? 'usa-current' : null)}
      to="/dashboard/admin/reports"
    >
      Admin Reports
    </NavLink>,
  ]

  return (
    <nav aria-label="Admin navigation">
      <SideNav items={adminNavItems} />
    </nav>
  )
}

export default AdminNav
