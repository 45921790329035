import PropTypes from 'prop-types'

const Main = ({ children, ...rest }) => {
  return (
    <main id="main-content" {...rest}>
      {children}
    </main>
  )
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
