import LogoCMS from './LogoCMS'
import LogoHHS from './LogoHHS'
import { Footer, FooterNav, Grid, GridContainer } from '@trussworks/react-uswds'

const SiteFooter = () => {
  return (
    <>
      <Footer
        size="slim"
        primary={
          <GridContainer>
            <Grid row gap>
              <Grid tablet={{ col: true }}>
                <FooterNav
                  size="slim"
                  links={[
                    <a
                      key="footer01"
                      className="usa-footer__primary-link"
                      href="https://www.cms.gov/About-CMS/Agency-Information/Aboutwebsite"
                    >
                      Site Policies &amp; Important Links
                    </a>,
                    <a key="footer02" className="usa-footer__primary-link" href="https://www.cms.gov/privacy/">
                      Privacy Policy
                    </a>,
                    <a
                      key="footer03"
                      className="usa-footer__primary-link"
                      href="https://www.medicare.gov/about-us/plain-writing"
                    >
                      Plain Language
                    </a>,
                    <a
                      key="footer04"
                      className="usa-footer__primary-link"
                      href="https://www.cms.gov/About-CMS/Agency-Information/Aboutwebsite/FOIA"
                    >
                      Freedom of Information Act
                    </a>,
                    <a
                      key="footer05"
                      className="usa-footer__primary-link"
                      href="http://www.cms.gov/About-CMS/Agency-Information/Aboutwebsite/NoFearAct"
                    >
                      No Fear Act
                    </a>,
                    <a
                      key="footer06"
                      className="usa-footer__primary-link"
                      href="https://www.cms.gov/About-CMS/Agency-Information/Aboutwebsite/CMSNondiscriminationNotice"
                    >
                      Nondiscrimination &amp; Accessibility
                    </a>,
                    <a
                      key="footer07"
                      className="usa-footer__primary-link"
                      href="https://www.cms.gov/vulnerability-disclosure-policy"
                    >
                      Vulnerability Disclosure Policy
                    </a>,
                  ]}
                  aria-label="Helpful Links"
                />
              </Grid>
            </Grid>
          </GridContainer>
        }
        secondary={
          <Grid row gap>
            <Grid tablet={{ col: 6 }}>
              <div className="qhp-logo-cms">
                <LogoCMS id="logo-cms-footer" />
              </div>
            </Grid>
            <Grid tablet={{ col: 6 }}>
              <div className="display-flex flex-justify-end">
                <div className="display-flex flex-row flex-align-center">
                  <div className="qhp-logo-hhs">
                    <LogoHHS />
                  </div>
                  <span className="qhp-footer-hhs">
                    A federal government website managed and paid for by the U.S. Centers for Medicare &amp; Medicaid
                    Services. 7500 Security Boulevard, Baltimore, MD 21244
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        }
      />
    </>
  )
}

export default SiteFooter
