import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Grid, GridContainer } from '@trussworks/react-uswds'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthDispatch, isAuthenticated, userLoginIdm } from '../contexts/AuthContext'
import Main from '../components/Main'

// TODO: Look into a way to redirect to different pages after successful login
const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useAuthDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()

  useEffect(() => {
    setIsLoading(true)
    const onLoad = async () => {
      try {
        const authenticated = await isAuthenticated(dispatch)
        if (authenticated) {
          navigate(state?.path || '/dashboard')
        } else {
          await userLoginIdm(dispatch)
        }
      } catch (error) {
        console.error(error)
      }

      setIsLoading(false)
    }

    onLoad()
  }, [])

  if (isLoading) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      <GridContainer>
        <Grid row gap>
          <Grid tablet={{ col: true }}>
            <Main>
              <div className="usa-prose usa-prose--wide padding-y-4">
                <h1>Sign In</h1>
                <p>Redirecting to sign in screen. One moment please&hellip;</p>
              </div>
            </Main>
          </Grid>
        </Grid>
      </GridContainer>
    </>
  )
}

export default LoginPage
