import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@trussworks/react-uswds'
import useGlobalStore from '../stores/useGlobalStore'

const Notifications = ({ scope = null, srOnly = false }) => {
  const notifications = useGlobalStore((state) => state.notifications)
  const [filteredNotifications, setFilteredNotifications] = useState([])

  useEffect(() => {
    const filtered = notifications.filter((notification) => !notification.scope || notification.scope === scope)
    setFilteredNotifications(filtered)
  }, [notifications, scope])

  if (!notifications.length && !srOnly) {
    return null
  }

  return (
    <>
      {srOnly ? (
        <div className="usa-sr-only" aria-live="polite">
          {notifications.length ? (
            <>
              {notifications.length === 1 ? (
                <>{notifications[0].message}</>
              ) : (
                <ul>
                  {notifications.map((notification) => {
                    return <li key={notification.id}>{notification.message}</li>
                  })}
                </ul>
              )}
            </>
          ) : null}
        </div>
      ) : (
        <>
          {filteredNotifications.map((notification) => {
            return (
              <Alert
                key={notification.id}
                type={notification.type}
                heading={notification.heading}
                headingLevel={notification.headingLevel}
                slim={notification.slim}
              >
                {notification.message}
              </Alert>
            )
          })}
        </>
      )}
    </>
  )
}

Notifications.propTypes = {
  scope: PropTypes.string,
  srOnly: PropTypes.bool,
}

export default Notifications
