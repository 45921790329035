import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { differenceInMilliseconds } from 'date-fns'
import { Button, ButtonGroup, Modal, ModalHeading, ModalFooter } from '@trussworks/react-uswds'
import { useAuthDispatch, isAuthenticated } from '../contexts/AuthContext'
import Countdown, { zeroPad } from 'react-countdown'

const IdleTimeOutHandler = (props) => {
  const [showModal, _setShowModal] = useState(false)

  const dispatch = useAuthDispatch()
  const navigate = useNavigate()

  const modalRef = useRef()
  const showModalRef = useRef(showModal)
  const countdownRef = useRef()

  let countdownApi = null
  let timerIdle = undefined
  let timerLogout = undefined
  const events = ['click', 'scroll', 'load', 'keydown', 'keypress']

  const setShowModal = (data) => {
    showModalRef.current = data
    _setShowModal(data)
    //updateAriaHidden(data)
  }

  // const updateAriaHidden = (hidden) => {
  //   //Accessibility
  //   document.getElementById('root')?.setAttribute('aria-hidden', hidden)
  //   document.getElementById('session-expire')?.setAttribute('aria-hidden', !hidden)
  //   document.getElementById('session-expire')?.setAttribute('aria-modal', true)
  // }

  const eventHandler = () => {
    if (showModalRef.current) {
      let lastInteractionTime = sessionStorage.getItem('lastInteractionTime')
      const diff = differenceInMilliseconds(new Date(), new Date(lastInteractionTime))
      let idleTimeoutInterval = props.idleTimeoutInterval ? props.idleTimeoutInterval : 1000 * 60 * 15
      if (diff >= idleTimeoutInterval) {
        handleLogout()
      }
    } else {
      sessionStorage.setItem('lastInteractionTime', new Date())
      if (timerIdle) {
        startIdleTimer()
      }
      if (timerLogout) {
        startLogoutTimer()
      }
    }
  }

  useEffect(() => {
    //updateAriaHidden(false)
    addEvents()
    sessionStorage.setItem('lastInteractionTime', new Date())
    countdownApi = countdownRef.current.getApi()

    return () => {
      removeEvents()
      clearTimeout(timerIdle)
      clearTimeout(timerLogout)
    }
  }, [])

  const startIdleTimer = () => {
    if (timerIdle) {
      clearTimeout(timerIdle)
    }
    timerIdle = setTimeout(
      () => {
        let lastInteractionTime = sessionStorage.getItem('lastInteractionTime')
        const diff = differenceInMilliseconds(new Date(), new Date(lastInteractionTime))
        let idleWarningInterval = props.idleWarningInterval ? props.idleWarningInterval : 1000 * 60 * 13
        if (diff < idleWarningInterval) {
          startIdleTimer()
          isAuthenticated(dispatch)
        } else {
          if (!showModal) {
            setShowModal(true)
            modalRef.current.toggleModal(true)
            countdownApi.start()
          }
        }
      },
      props.idleWarningInterval ? props.idleWarningInterval : 1000 * 60 * 13
    )
  }

  const startLogoutTimer = () => {
    if (timerLogout) {
      clearTimeout(timerLogout)
    }
    timerLogout = setTimeout(
      () => {
        let lastInteractionTime = sessionStorage.getItem('lastInteractionTime')
        const diff = differenceInMilliseconds(new Date(), new Date(lastInteractionTime))
        let idleTimeoutInterval = props.idleTimeoutInterval ? props.idleTimeoutInterval : 1000 * 60 * 15
        if (diff >= idleTimeoutInterval) {
          handleLogout(true)
        }
      },
      props.idleTimeoutInterval ? props.idleTimeoutInterval : 1000 * 60 * 15
    )
  }

  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler)
    })

    startIdleTimer()
    startLogoutTimer()
  }

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler)
    })
  }

  const handleContinueSession = () => {
    let lastInteractionTime = sessionStorage.getItem('lastInteractionTime')
    const diff = differenceInMilliseconds(new Date(), new Date(lastInteractionTime))
    let idleTimeoutInterval = props.idleTimeoutInterval ? props.idleTimeoutInterval : 1000 * 60 * 15
    if (diff >= idleTimeoutInterval) {
      handleLogout()
    } else {
      isAuthenticated(dispatch)
      startIdleTimer()
      startLogoutTimer()
      if (showModal) {
        setShowModal(false)
        modalRef.current.toggleModal(false)
      }
    }
  }

  const handleLogout = (timedOut) => {
    const isTimedOut = timedOut || false
    removeEvents()
    clearTimeout(timerIdle)
    clearTimeout(timerLogout)
    sessionStorage.setItem('timedOut', isTimedOut)
    if (showModal) {
      setShowModal(false)
      modalRef.current.toggleModal(false)
    }
    navigate('/logout')
  }

  const countdownRenderer = ({ minutes, seconds }) => {
    return (
      <span role="timer">
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    )
  }

  return (
    <div>
      <Modal
        ref={modalRef}
        forceAction
        aria-labelledby="session-expire-heading"
        aria-describedby="session-expire-description"
        id="session-expire"
      >
        <ModalHeading id="session-expire-heading">Your Session is About to Expire</ModalHeading>
        <div className="usa-prose">
          <p id="session-expire-description">
            Your session will expire in:{' '}
            <strong>
              <Countdown
                ref={countdownRef}
                date={Date.now() + (props.idleTimeoutInterval - props.idleWarningInterval)}
                autoStart={false}
                renderer={countdownRenderer}
              />
            </strong>
            <br />
            Do you want to extend your session another <strong>{props.idleTimeoutInterval / 60 / 1000}</strong> minutes?
          </p>
        </div>
        <ModalFooter>
          <ButtonGroup>
            <Button onClick={handleContinueSession} aria-controls="session-expire">
              Extend Session
            </Button>
            <Button secondary onClick={() => handleLogout()} aria-controls="session-expire">
              Sign Out
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    </div>
  )
}

IdleTimeOutHandler.propTypes = {
  idleWarningInterval: PropTypes.number.isRequired,
  idleTimeoutInterval: PropTypes.number.isRequired,
}

export default IdleTimeOutHandler
