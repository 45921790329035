import { create } from 'zustand'
import { generateUserId } from '../lib/ciphertextLib'

/**
 * Zustand store to manage the state of site admin functions.
 *
 * @todo Consider implementing Immer for state changes.
 * @todo Consider creating slices to make the store easier to manage: https://docs.pmnd.rs/zustand/guides/slices-pattern
 * @todo Research using derived sub-states with Zustand to eliminate the need to update 2 separate state variables.
 */
const useAdminStore = create((set) => ({
  isAddMode: false,
  allUsers: [],
  availableUsers: [],
  currentUser: {},
  allReportingUnits: [],
  currentReportingUnit: {},
  reportingUnitUsers: [],
  setAllUsers: (users) => set({ allUsers: users }),
  setAvailableUsers: (users) => set({ availableUsers: users }),
  setCurrentUser: (user) => set({ currentUser: user }),
  addUser: (data) => {
    // Add a new user.
    const newPK = generateUserId(data.email, data.year_)
    const newData = { ...data, PK: newPK, reportingUnits: [] }
    set((state) => ({
      allUsers: [...state.allUsers, newData],
      currentUser: newData,
    }))
  },
  updateUser: (data) => {
    // Update existing user record.
    set((state) => ({
      allUsers: state.allUsers.map((user) =>
        user.PK === data.PK
          ? {
              ...user,
              ...data,
            }
          : user
      ),
      currentUser: {
        ...state.currentUser,
        ...data,
      },
    }))
  },
  replaceUser: (data) => {
    // Replace a user record when the email changes.
    // In actuality, for the state, this involves simply updating the user with new data and new PK and SK.
    // May be able to add logic to update user to combine the two methods.
    const newPK = generateUserId(data.email, data.year_)
    set((state) => ({
      allUsers: state.allUsers.map((user) =>
        user.PK === data.PK
          ? {
              ...user,
              ...data,
              PK: newPK,
              SK: newPK,
            }
          : user
      ),
      currentUser: {
        ...state.currentUser,
        ...data,
        PK: newPK,
        SK: newPK,
      },
    }))
  },
  addUserReportingUnit: (data) => {
    // Add new reporting unit info to both allUsers and currentUser.
    const reportingUnitPK = `ATTESTATION#REPORTINGUNIT#${data.reportingUnit}#YEAR#${data.year_}`
    set((state) => ({
      allUsers: state.allUsers.map((user) =>
        user.PK === data.userPK
          ? {
              ...user,
              reportingUnits: [...user.reportingUnits, { PK: reportingUnitPK, SK: data.userPK }],
            }
          : user
      ),
      currentUser: {
        ...state.currentUser,
        reportingUnits: [...state.currentUser.reportingUnits, { PK: reportingUnitPK, SK: data.userPK }],
      },
    }))
  },
  deleteUserReportingUnit: (data) => {
    // Remove reporting unit info from both allUsers and currentUser.
    set((state) => ({
      allUsers: state.allUsers.map((user) =>
        user.PK === data.SK
          ? {
              ...user,
              reportingUnits: user.reportingUnits.filter((ru) => !(ru.PK === data.PK && ru.SK === data.SK)),
            }
          : user
      ),
      currentUser: {
        ...state.currentUser,
        reportingUnits: state.currentUser.reportingUnits.filter((ru) => !(ru.PK === data.PK && ru.SK === data.SK)),
      },
    }))
  },
  setAllReportingUnits: (reportingUnits) => set({ allReportingUnits: reportingUnits }),
  setCurrentReportingUnit: (reportingUnit) => set({ currentReportingUnit: reportingUnit }),
  addReportingUnit: (data) => {
    set((state) => ({
      allReportingUnits: [...state.allReportingUnits, data],
      currentReportingUnit: data,
    }))
  },
  updateReportingUnit: (data) => {
    // Update existing reporting unit record in both allReportingUnits and currentReportingUnit.
    set((state) => ({
      allReportingUnits: state.allReportingUnits.map((reportingUnit) =>
        reportingUnit.PK === data.PK
          ? {
              ...reportingUnit,
              ...data,
            }
          : reportingUnit
      ),
      currentReportingUnit: {
        ...state.currentReportingUnit,
        ...data,
      },
    }))
  },
  deleteReportingUnit: (data) => {
    // Remove reporting unit info from allReportingUnits.
    set((state) => ({
      allReportingUnits: state.allReportingUnits.filter(
        (reportingUnit) => !(reportingUnit.PK === data.PK && reportingUnit.SK === data.SK)
      ),
      // allReportingUnits: state.allReportingUnits.map((reportingUnit) =>
      //   reportingUnit.PK === data.SK
      //     ? {
      //         ...reportingUnit,
      //         // reportingUnits: user.reportingUnits.filter((ru) => !(ru.PK === data.PK && ru.SK === data.SK)),
      //       }
      //     : reportingUnit
      // ),
      // currentReportingUnit: {
      //   ...state.currentReportingUnit,
      //   // reportingUnits: state.currentUser.reportingUnits.filter((ru) => !(ru.PK === data.PK && ru.SK === data.SK)),
      // },
    }))
  },
  setReportingUnitUsers: (users) => set({ reportingUnitUsers: users }),
  addReportingUnitUser: (userId) => {
    set((state) => ({
      reportingUnitUsers: [...state.reportingUnitUsers, state.availableUsers.find((user) => user.PK === userId)],
    }))
  },
  deleteReportingUnitUser: (userId) => {
    set((state) => ({
      reportingUnitUsers: state.reportingUnitUsers.filter((user) => !(user.PK === userId)),
    }))
  },
  setIsAddMode: (value) => {
    set(() => ({ isAddMode: value }))
  },
}))

export default useAdminStore
