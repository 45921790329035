import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthDispatch, isAuthenticated } from '../contexts/AuthContext'

const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [showPage, setShowPage] = useState(false)

  const dispatch = useAuthDispatch()
  const location = useLocation()

  useEffect(() => {
    const onLoad = async () => {
      setIsLoading(true)
      setShowPage(false)
      const authenticated = await isAuthenticated(dispatch)
      if (authenticated) {
        setShowPage(true)
      }
      setIsLoading(false)
    }

    onLoad()
  }, [location.pathname])

  if (isLoading) {
    return null
  }

  return showPage ? children : <Navigate to="/login" replace state={{ path: location.pathname }} />
}

ProtectedRoute.propTypes = {
  children: PropTypes.object.isRequired,
}

export default ProtectedRoute
