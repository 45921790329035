import { Storage } from 'aws-amplify'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

const s3Upload = async (path, file) => {
  const filename = path + file.name

  // Amplify does not support changing the endpoint for S3, so upload to LocalStack using the AWS SDK
  // Set REACT_APP_IS_LOCAL=true in a .env.local file in the root directory to trigger local processing
  // TODO: Consider adding other local configurations as environment variables
  if (process.env.REACT_APP_IS_LOCAL && process.env.REACT_APP_IS_LOCAL === 'true') {
    const credentials = {
      accessKeyId: 'S3RVER',
      secretAccessKey: 'S3RVER',
    }

    const bucketName = 'local-qhp-survey-application-uploads'

    const client = new S3Client({
      region: 'us-east-1',
      endpoint: 'http://localhost:4569',
      forcePathStyle: true,
      credentials,
    })

    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: bucketName,
      Key: `uploads/${filename}`,
      ContentType: file.type,
    }

    const command = new PutObjectCommand(params)
    try {
      await client.send(command)
      return filename
    } catch (error) {
      console.error(error)
    }
  } else {
    const stored = await Storage.put(filename, file, {
      contentType: file.type,
      customPrefix: {
        public: 'uploads/',
      },
    })

    return stored.key
  }
}

const s3Delete = async (filename) => {
  await Storage.remove(filename)
    .then((result) => console.log(result))
    .catch((error) => console.error(error))
}

export { s3Upload, s3Delete }
