import { useLayoutEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Alert, Card, CardBody, CardGroup, CardHeader, Grid, GridContainer, Icon } from '@trussworks/react-uswds'
import Skeleton from 'react-loading-skeleton'
import { useConfig } from '../contexts/ConfigContext'
import { useAuth } from '../contexts/AuthContext'
import { formatDate } from '../util/datetime'
import Main from './Main'
import ContactInfo from './ContactInfo'
import ButtonCsvDownload from './ButtonCsvDownload'
import DataTable from 'react-data-table-component'
import AnalystSurveyNotes from './AnalystSurveyNotes'

const AnalystDashboard = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [analyst, setAnalyst] = useState(null)
  const [updatedFile, setUpdatedFile] = useState(null)
  const [disabledCollapse, setDisabledCollapse] = useState([])
  const [csvError, setCsvError] = useState(false)
  const [statusMessage, setStatusMessage] = useState(null)

  const config = useConfig()
  const user = useAuth()

  const customDataTableStyles = {
    tableWrapper: {
      style: { paddingTop: '0.25rem' },
    },
    expanderButton: {
      style: {
        color: '#757575',
        fill: '#757575',
      },
    },
    pagination: {
      style: {
        fontSize: 'unset',
      },
      pageButtonsStyle: { borderRadius: '0.25rem' },
    },
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,
  }

  const csvColumns = [
    {
      id: 'name',
      name: 'Name',
      selector: (row) => row.name_,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: 'Vendor',
      selector: (row) => row.vendorName,
      sortable: true,
      wrap: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status_,
      wrap: true,
    },
    {
      id: 'date',
      name: 'Uploaded Date',
      selector: (row) => row.createdAt,
      sortable: true,
      wrap: true,
      format: (row) => formatDate(row.createdAt),
    },
  ]

  useLayoutEffect(() => {
    // Get analyst information associated with logged in user
    const fetchAnalystData = async () => {
      setIsLoading(true)

      if (!config.isLoading && !user.isLoading) {
        if (user.info?.userRole === 'Analyst') {
          try {
            const dashboard = await API.get('qhp-survey-auth', '/analyst/dashboard')

            setAnalyst({
              configSubmissionType: dashboard.configSubmissionType,
              config: dashboard.config,
              user: user.info,
              pendingCsvs: dashboard.pendingCsvs,
              failedCsvs: dashboard.failedCsvs,
              passedCsvs: dashboard.passedCsvs,
            })
          } catch (error) {
            console.error(error)
          }
        }
      }
      setIsLoading(false)
    }

    fetchAnalystData()
  }, [config.isLoading, user.isLoading, user.info])

  // Update row data after saving in the expandable row
  const handleSave = (data) => {
    const combined = [...analyst.pendingCsvs, ...analyst.failedCsvs, ...analyst.passedCsvs]
    const updated = combined.map((survey) => (survey.name_ === data.name_ ? { ...survey, ...data } : survey))
    const pending = updated.filter((survey) => survey.status_ === 'Pending Validation')
    const failed = updated.filter((survey) => survey.status_ === 'Failed Validation')
    const passed = updated.filter((survey) => survey.status_ === 'Passed Validation')

    setAnalyst((prev) => {
      return { ...prev, pendingCsvs: pending, failedCsvs: failed, passedCsvs: passed }
    })

    setUpdatedFile(data.name_)
  }

  const handleDisableCollapse = (id, disable) => {
    if (disable) {
      setDisabledCollapse((prev) => {
        if (!prev.includes(id)) {
          return [...prev, id]
        }
        return [...prev]
      })
    } else {
      setDisabledCollapse((prev) => {
        return prev.filter((item) => item !== id)
      })
    }
  }

  return (
    <>
      <GridContainer>
        <Grid row gap>
          <Grid tablet={{ col: true }}>
            <Main>
              <section className="usa-section usa-prose usa-prose--wide">
                <h1>Dashboard</h1>
                {config.isLoading || user.isLoading || isLoading ? (
                  <>
                    <Grid row gap={2}>
                      <Grid tablet={{ col: 6 }}>
                        <Skeleton height="12rem" />
                      </Grid>
                      <Grid tablet={{ col: 6 }}>
                        <Skeleton height="12rem" />
                      </Grid>
                      <Grid tablet={{ col: 12 }} className="margin-top-1">
                        <Skeleton height="12rem" />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {!analyst && (
                      <Alert type="info">
                        Your account is not currently associated with any analysts. If you feel this is an error, please
                        contact us at <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a>
                      </Alert>
                    )}
                    {analyst && (
                      <>
                        <CardGroup>
                          <Card gridLayout={{ tablet: { col: 6 } }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.AccountCircle
                                  className="margin-right-05"
                                  role="presentation"
                                  focusable="false"
                                  size={3}
                                />{' '}
                                Analyst Information
                              </h2>
                            </CardHeader>
                            <CardBody>
                              <address>
                                <p>
                                  <ContactInfo contact={analyst.user} />
                                </p>
                              </address>
                            </CardBody>
                          </Card>
                          <Card gridLayout={{ tablet: { col: 6 } }} containerProps={{ className: 'usa-card--light' }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.FileDownload
                                  className="margin-right-05"
                                  role="presentation"
                                  focusable="false"
                                  size={3}
                                />{' '}
                                Analyst Reports
                              </h2>
                            </CardHeader>
                            <CardBody>
                              {csvError && (
                                <Alert className="margin-bottom-3" type="error" headingLevel="h4" slim>
                                  {csvError}
                                </Alert>
                              )}
                              <ul className="qhp-list qhp-list--unstyled qhp-list--buttons">
                                <li>
                                  <ButtonCsvDownload
                                    label="Vendor Survey Final Submission Report"
                                    filename="VendorSurveyFinalSubmissionReport.csv"
                                    apiPath="/reports/vendor/surveysubmissions"
                                    setCsvError={setCsvError}
                                  />
                                </li>
                                <li>
                                  <ButtonCsvDownload
                                    label="Issuer Attestation & Vendor Selection Summary Report"
                                    filename="IssuerAttestationVendorSelectionSummaryReport.csv"
                                    apiPath="/reports/issuer/attestationsummary"
                                    setCsvError={setCsvError}
                                  />
                                </li>
                                <li>
                                  <ButtonCsvDownload
                                    label="Issuer Attestation & Vendor Selection Detail Report "
                                    filename="IssuerAttestationVendorSelectionDetailReport.csv"
                                    apiPath="/reports/issuer/attestationdetails"
                                    setCsvError={setCsvError}
                                  />
                                </li>
                              </ul>
                            </CardBody>
                          </Card>
                          <Card gridLayout={{ tablet: { col: 12 } }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.FilePresent
                                  className="margin-right-05"
                                  role="presentation"
                                  focusable="false"
                                  size={3}
                                />{' '}
                                Pending Validation {analyst.configSubmissionType} CSV Files
                              </h2>
                            </CardHeader>
                            <CardBody>
                              <DataTable
                                columns={csvColumns}
                                data={analyst.pendingCsvs}
                                responsive
                                striped
                                pagination
                                paginationPerPage={25}
                                paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                paginationComponentOptions={paginationComponentOptions}
                                highlightOnHover
                                defaultSortFieldId="date"
                                defaultSortAsc={false}
                                sortIcon={<Icon.ArrowDownward role="presentation" />}
                                expandableRows
                                expandableRowsComponent={AnalystSurveyNotes}
                                expandableRowsComponentProps={{
                                  analyst: analyst,
                                  updatedFile: updatedFile,
                                  handleSave: handleSave,
                                  setStatusMessage: setStatusMessage,
                                  handleDisableCollapse: handleDisableCollapse,
                                }}
                                expandOnRowClicked
                                expandableRowExpanded={(row) => row.name_ === updatedFile}
                                expandableRowDisabled={(row) => disabledCollapse.includes(row.id)}
                                customStyles={customDataTableStyles}
                              />
                            </CardBody>
                          </Card>
                          <Card gridLayout={{ tablet: { col: 12 } }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.FilePresent
                                  className="margin-right-05"
                                  role="presentation"
                                  focusable="false"
                                  size={3}
                                />{' '}
                                Failed Validation {analyst.configSubmissionType} CSV Files
                              </h2>
                            </CardHeader>
                            <CardBody>
                              <DataTable
                                columns={csvColumns}
                                data={analyst.failedCsvs}
                                responsive
                                striped
                                pagination
                                paginationPerPage={25}
                                paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                paginationComponentOptions={paginationComponentOptions}
                                defaultSortFieldId="date"
                                defaultSortAsc={false}
                                sortIcon={<Icon.ArrowDownward role="presentation" />}
                                expandableRows
                                expandableRowsComponent={AnalystSurveyNotes}
                                expandableRowsComponentProps={{
                                  analyst: analyst,
                                  updatedFile: updatedFile,
                                  handleSave: handleSave,
                                  setStatusMessage: setStatusMessage,
                                  handleDisableCollapse: handleDisableCollapse,
                                }}
                                expandOnRowClicked
                                expandableRowExpanded={(row) => row.name_ === updatedFile}
                                expandableRowDisabled={(row) => disabledCollapse.includes(row.id)}
                                customStyles={customDataTableStyles}
                              />
                            </CardBody>
                          </Card>
                          <Card gridLayout={{ tablet: { col: 12 } }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.FilePresent
                                  className="margin-right-05"
                                  role="presentation"
                                  focusable="false"
                                  size={3}
                                />{' '}
                                Passed Validation {analyst.configSubmissionType} CSV Files
                              </h2>
                            </CardHeader>
                            <CardBody>
                              <DataTable
                                columns={csvColumns}
                                data={analyst.passedCsvs}
                                responsive
                                striped
                                pagination
                                paginationPerPage={25}
                                paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                paginationComponentOptions={paginationComponentOptions}
                                defaultSortFieldId="date"
                                defaultSortAsc={false}
                                sortIcon={<Icon.ArrowDownward role="presentation" />}
                                expandableRows
                                expandableRowsComponent={AnalystSurveyNotes}
                                expandableRowsComponentProps={{
                                  analyst: analyst,
                                  updatedFile: updatedFile,
                                  handleSave: handleSave,
                                  setStatusMessage: setStatusMessage,
                                  handleDisableCollapse: handleDisableCollapse,
                                }}
                                expandOnRowClicked
                                expandableRowExpanded={(row) => row.name_ === updatedFile}
                                expandableRowDisabled={(row) => disabledCollapse.includes(row.id)}
                                customStyles={customDataTableStyles}
                              />
                            </CardBody>
                          </Card>
                        </CardGroup>
                      </>
                    )}
                  </>
                )}
                <span className="usa-sr-only" aria-live="polite">
                  {statusMessage}
                </span>
              </section>
            </Main>
          </Grid>
        </Grid>
      </GridContainer>
    </>
  )
}

export default AnalystDashboard
