import { useState } from 'react'
import LogoCMS from './LogoCMS'
import SiteNav from './SiteNav'
import { GovBanner, Header, NavMenuButton, Title } from '@trussworks/react-uswds'

const SiteHeader = () => {
  const [expanded, setExpanded] = useState(false)
  const toggleNav = () => setExpanded((prvExpanded) => !prvExpanded)

  return (
    <>
      <a className="usa-skipnav" href="#main-content">
        Skip to main content
      </a>
      <GovBanner aria-label="Official government website" />
      <div className={`usa-overlay ${expanded ? 'is-visible' : ''}`}></div>
      <Header extended role="banner" aria-label="Site header">
        <div className="usa-navbar">
          <Title>
            <a
              className="display-flex flex-align-center"
              href="/"
              aria-label="Link to the CMS.gov Qualified Health Plan Enrollee Experience Survey System home page"
            >
              <div className="qhp-logo-cms">
                <LogoCMS id="logo-cms-header" />
              </div>
              <span className="display-none tablet:display-block">
                Qualified Health Plan Enrollee Experience Survey System
              </span>
              <span
                className="display-block tablet:display-none font-body-lg"
                title="Qualified Health Plan Enrollee Experience Survey"
              >
                QHP EES
              </span>
            </a>
          </Title>
          <NavMenuButton onClick={toggleNav} label="Menu" />
        </div>
        <SiteNav expanded={expanded} toggleNav={toggleNav} />
      </Header>
    </>
  )
}

export default SiteHeader
