import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Icon } from '@trussworks/react-uswds'
import DataTable from 'react-data-table-component'
import IssuerAttestation from './IssuerAttestation'

const IssuerAttestationsTable = ({ data, config }) => {
  const [attestations, setAttestations] = useState()
  const [statusMessage, setStatusMessage] = useState(null)

  const customDataTableStyles = {
    tableWrapper: {
      style: { paddingTop: '0.25rem' },
    },
    expanderButton: {
      style: {
        color: '#757575',
        fill: '#757575',
      },
    },
    pagination: {
      style: {
        fontSize: 'unset',
      },
      pageButtonsStyle: { borderRadius: '0.25rem' },
    },
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,
  }

  const columns = [
    {
      name: 'Reporting Unit',
      selector: (row) => row.reportingUnit,
      sortable: true,
    },
    {
      name: 'Issuer',
      selector: (row) => row.name_,
      sortable: true,
      wrap: true,
      grow: 2,
    },
    {
      name: 'Attestation Status',
      selector: (row) =>
        row.vendor
          ? 'Complete/Eligible'
          : row.ineligibilityReason
          ? 'Complete/Ineligible'
          : row.answer01
          ? 'In Progress'
          : 'Not Started',
      sortable: true,
      wrap: true,
    },
    // {
    //   name: 'Eligibility Status',
    //   selector: (row) => (row.vendor ? 'Eligible' : row.ineligibilityReason ? 'Ineligible' : 'Incomplete'),
    //   wrap: true,
    // },
    {
      name: 'Authorized Vendor',
      selector: (row) => (row.vendor ? row.vendor : row.ineligibilityReason ? 'N/A' : ''),
      sortable: true,
      wrap: true,
    },
    {
      name: 'Ineligibility Reason',
      selector: (row) => (row.ineligibilityReason ? row.ineligibilityReason : row.vendor ? 'N/A' : ''),
      sortable: true,
      wrap: true,
      grow: 2,
    },
  ]

  // Update row data after saving in the expandable row
  const handleSave = (data) => {
    setAttestations((prev) =>
      prev.map((row) => {
        if (row.reportingUnit === data.reportingUnit) {
          return { ...row, ...data }
        }

        return row
      })
    )
  }

  useEffect(() => {
    const onLoad = async () => {
      // Set the list of reporting units
      setAttestations(data || [])
    }

    onLoad()
  }, [data])

  return (
    <>
      <DataTable
        columns={columns}
        data={attestations}
        keyField="reportingUnit"
        responsive
        striped
        pagination
        paginationPerPage={25}
        paginationRowsPerPageOptions={[25, 50, 75, 100]}
        paginationComponentOptions={paginationComponentOptions}
        sortIcon={<Icon.ArrowDownward role="presentation" />}
        defaultSortFieldId={2}
        expandableRows
        expandableRowDisabled={() => {
          return config?.vendorSelection === 'NONE' || false
        }}
        expandableRowsComponent={IssuerAttestation}
        expandableRowsComponentProps={{
          config: config,
          handleSave: handleSave,
          setStatusMessage: setStatusMessage,
        }}
        expandOnRowClicked
        customStyles={customDataTableStyles}
      />
      <span className="usa-sr-only" aria-live="polite">
        {statusMessage}
      </span>
    </>
  )
}

IssuerAttestationsTable.propTypes = {
  data: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
}

export default IssuerAttestationsTable
