import { useLayoutEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Alert, Card, CardBody, CardGroup, CardHeader, Grid, GridContainer, Icon, Link } from '@trussworks/react-uswds'
import Skeleton from 'react-loading-skeleton'
import { useConfig } from '../contexts/ConfigContext'
import { useAuth } from '../contexts/AuthContext'
import Main from './Main'
import ContactInfo from './ContactInfo'
import IssuerAttestationsTable from './IssuerAttestationsTable'
import ButtonCsvDownload from './ButtonCsvDownload'

// TODO: Break this dashboard down into smaller individual components
// TODO: Look into managing user feedback messages more efficiently
const IssuerDashboard = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [dashboardData, setDashboardData] = useState({})
  const [csvError, setCsvError] = useState(false)

  const config = useConfig()
  const user = useAuth()

  useLayoutEffect(() => {
    const onLoad = async () => {
      setIsLoading(true)

      try {
        const result = await API.get('qhp-survey-auth', '/issuer/dashboard')
        setDashboardData(result)
      } catch (err) {
        console.error(err)
      }

      setIsLoading(false)
    }

    onLoad()
  }, [])

  return (
    <>
      <GridContainer>
        <Grid row gap>
          <Grid tablet={{ col: true }}>
            <Main>
              <section className="usa-section usa-prose usa-prose--wide">
                <h1>Dashboard</h1>
                {config.isLoading || user.isLoading || isLoading ? (
                  <>
                    <Grid row gap={2}>
                      <Grid tablet={{ col: 5 }}>
                        <Skeleton height="12rem" />
                      </Grid>
                      <Grid tablet={{ col: 7 }}>
                        <Skeleton height="12rem" />
                      </Grid>
                      <Grid tablet={{ col: 12 }} className="margin-top-1">
                        <Skeleton height="12rem" />
                      </Grid>
                      <Grid tablet={{ col: 12 }} className="margin-top-1">
                        <Skeleton height="12rem" />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {dashboardData.attestations?.length === 0 && (
                      <Alert type="info">
                        Your account is not currently associated with any issuers. If you feel this is an error, please
                        contact us at <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a>
                      </Alert>
                    )}
                    <CardGroup>
                      <Card gridLayout={{ tablet: { col: 5 } }}>
                        <CardHeader>
                          <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                            <Icon.AccountCircle
                              className="margin-right-05"
                              role="presentation"
                              focusable="false"
                              size={3}
                            />{' '}
                            Authorized Contact
                          </h2>
                        </CardHeader>
                        <CardBody>
                          <address>
                            <p>
                              <ContactInfo contact={user?.info} />
                            </p>
                            <p className="font-body-2xs text-base">
                              For changes to contact information or any questions related to this process, please
                              contact us at <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a>
                            </p>
                          </address>
                        </CardBody>
                      </Card>
                      <Card gridLayout={{ tablet: { col: 7 } }} containerProps={{ className: 'usa-card--light' }}>
                        <CardHeader>
                          <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                            <Icon.Info className="margin-right-05" role="presentation" focusable="false" size={3} />{' '}
                            Instructions
                          </h2>
                        </CardHeader>
                        <CardBody>
                          <p className="font-body-xs">
                            The 2025 Quality Rating System (QRS) and Qualified Health Plan Enrollee Experience Survey
                            (QHP Enrollee Survey) Operational Instructions are available on the{' '}
                            <Link
                              variant="external"
                              href={'https://www.cms.gov/medicare/quality/health-insurance-marketplace-initiatives'}
                              target="_blank"
                              rel="noopener noreferrer"
                              aria-label="CMS Marketplace Quality Initiatives (MQI) website, external"
                            >
                              CMS Marketplace Quality Initiatives (MQI) website.
                            </Link>{' '}
                            Please review the instructions in this document thoroughly to prepare reporting units to
                            collect and submit QHP Enrollee Survey response data, generate and validate sample frames,
                            confirm reporting unit information and select a survey vendor, and provide information
                            regarding reporting unit ineligibility, if applicable.
                          </p>
                          <p className="font-body-xs">
                            Select a reporting unit below and respond to each question to attest to the QRS and QHP
                            Enrollee Survey Issuer Eligibility Criteria.{' '}
                            <b>Additional questions may appear on screen based on each of your responses.</b> If the
                            reporting unit is eligible, you will be prompted to confirm your authorized HHS-approved
                            vendor. If the reporting unit is ineligible, you will be prompted to confirm the reason for
                            ineligibility.
                          </p>
                          <p className="font-body-xs">
                            <b>
                              Please complete all questions until the &quot;Attestation Status&quot; for the reporting
                              unit is marked &quot;Complete/Eligible&quot; or &quot;Complete/Ineligible.&quot;
                            </b>
                          </p>
                        </CardBody>
                      </Card>
                      <Card gridLayout={{ tablet: { col: 12 } }}>
                        <CardHeader>
                          <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                            <Icon.Topic className="margin-right-05" role="presentation" focusable="false" size={3} />{' '}
                            Reporting Units
                          </h2>
                          {dashboardData.config?.vendorSelection === 'NONE' ? (
                            <h4>Attestations Open: {dashboardData.config?.attestation.dateStartEdit}</h4>
                          ) : (
                            ''
                          )}
                        </CardHeader>
                        <CardBody>
                          <IssuerAttestationsTable data={dashboardData.attestations} config={dashboardData.config} />
                          <p className="font-body-2xs text-base">
                            If a reporting unit is not listed, please notify{' '}
                            <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a>.
                          </p>
                        </CardBody>
                      </Card>

                      <Card gridLayout={{ tablet: { col: 12 } }}>
                        <CardHeader>
                          <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                            <Icon.FileDownload
                              className="margin-right-05"
                              role="presentation"
                              focusable="false"
                              size={3}
                            />{' '}
                            CSV Reports
                          </h2>
                        </CardHeader>
                        <CardBody>
                          <p className="font-body-2xs text-base">
                            Reports include data for all issuers and reporting units associated to your login.
                          </p>
                          {csvError && (
                            <Alert className="margin-bottom-3" type="error" headingLevel="h4" slim>
                              {csvError}
                            </Alert>
                          )}
                          <ul className="qhp-list qhp-list--unstyled qhp-list--buttons">
                            <li>
                              <ButtonCsvDownload
                                label="Attestations Eligibility Vendor Selection Report"
                                filename="QHPAttestationsEligibilityVendorSelectionReport.csv"
                                apiPath="/reports/issuer/attestationissuersdetails"
                                setCsvError={setCsvError}
                              />
                            </li>
                          </ul>
                        </CardBody>
                      </Card>
                    </CardGroup>
                    <Alert type="info" heading="Note" headingLevel="h3">
                      Qualified Health Plan (QHP) Enrollee Experience Survey enrollee response data must be submitted
                      for each product type offered through an Exchange for two consecutive years (i.e., 2024 and 2025)
                      that had more than 500 enrollees as of <strong>July 1, 2024</strong>, and more than 500 enrollees
                      as of <strong>January 1, 2025</strong>.<br />
                      <br />
                      The minimum enrollment threshold is determined by the total number of enrollees within the
                      reporting unit, not by the number of survey-eligible enrollees.
                      <br />
                      <br />
                      Reporting units discontinued before June 15 of the ratings year (i.e., June 15, 2025) are exempt
                      from QHP Enrollee Experience Survey requirements. For an eligible reporting unit impacted by a QHP
                      issuer change in ownership (e.g., merger, acquisition) effective as of January 1 of the ratings
                      year, the QHP issuer that assumes the reporting unit is responsible for meeting these
                      requirements.
                      <br />
                      <br />
                      The Centers for Medicare &amp; Medicaid Services (CMS) will <strong>not</strong> accept voluntary
                      data submissions for reporting units that do not meet eligibility criteria as defined above.
                    </Alert>
                  </>
                )}
              </section>
            </Main>
          </Grid>
        </Grid>
      </GridContainer>
      <section className="usa-section qhp-section--light usa-prose usa-prose--wide">
        <GridContainer>
          <Grid row gap>
            <Grid tablet={{ col: true }}>
              <p>
                [1] Pursuant to 45 C.F.R. §§ 156.1120(a)(3) and 156.1125(b)(3), QHP issuers participating in the
                Exchange must include information in their respective QRS and QHP Enrollee Experience Survey data
                submissions only for those enrollees at the level specified by HHS.
              </p>
              <p>
                [2] For purposes of QRS and QHP Enrollee Experience Survey participation eligibility, the term “offered”
                includes all reporting units that are operational through an Exchange (i.e., reporting units that are
                available for purchase through an Exchange [SHOP or individual], accepting new members or groups, or
                have active or existing members).
              </p>
              <p>[3] 45 C.F.R. §§ 156.1120(a) and 156.1125(b).</p>
              <p>
                [4] The QHP Enrollee Experience Survey minimum enrollment requirement aligns with standards set forth in
                45 C.F.R. § 156.1125(b)(1). CMS established the minimum enrollment requirement for QRS to align with the
                QHP Enrollee Experience Survey minimum enrollment requirement and to support a sufficient size for
                credible and reliable results.
              </p>
            </Grid>
          </Grid>
        </GridContainer>
      </section>
    </>
  )
}

export default IssuerDashboard
