import { formatInTimeZone } from 'date-fns-tz'

// Format UTC timestamp to human-readable date in U.S. Eastern time zone
const formatDate = (timestamp) => {
  const date = new Date(timestamp)
  const formattedDate = formatInTimeZone(date, 'America/New_York', 'MM/dd/yyyy hh:mm:ss a zzz')

  return formattedDate
}

export { formatDate }
