import { useEffect, useState } from 'react'
import { Alert, Grid } from '@trussworks/react-uswds'
import { useConfig } from '../../../contexts/ConfigContext'
import Skeleton from 'react-loading-skeleton'

const VendorsList = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)

  const config = useConfig()

  useEffect(() => {
    setIsLoading(true)
    setError(false)

    const onLoad = async () => {
      try {
        const vendors = []
        setData(vendors)
      } catch (error) {
        setError(error)
      }

      setIsLoading(false)
    }

    onLoad()
  }, [])

  return (
    <>
      <h2>Vendors</h2>
      {error ? (
        <Alert type="error" headingLevel="h2" heading="Error">
          {error?.message ? error.message : 'An error has occurred.'}
        </Alert>
      ) : (
        <>
          {config.isLoading || isLoading ? (
            <>
              <Grid row gap>
                <Grid tablet={{ col: 12 }} className="margin-top-1">
                  <Skeleton height="3rem" count={3} />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Alert type="info">This feature has not yet been implemented.</Alert>
              {data.length ? <p>Vendor Data</p> : null}
            </>
          )}
        </>
      )}
    </>
  )
}

export default VendorsList
