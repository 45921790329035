import { useEffect } from 'react'
import { Grid, GridContainer } from '@trussworks/react-uswds'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import Main from '../Main'
import AdminNav from './AdminNav'

const AdminDashboard = () => {
  // Is there a better way to do this in the <Routes> in App.js?
  // Maybe by checking user roles in App.js?
  const navigate = useNavigate()
  const match = useMatch('dashboard')

  useEffect(() => {
    if (match) {
      navigate('admin/users', { replace: true })
    }
  }, [match])

  return (
    <>
      <section className="usa-section">
        <GridContainer>
          <Grid row gap>
            <Grid tablet={{ col: true }}></Grid>
          </Grid>
          <Grid row gap>
            <Grid tablet={{ col: 2 }}>
              <AdminNav />
            </Grid>
            <Grid tablet={{ col: 10 }}>
              <Main className="usa-prose usa-prose--wide">
                <h1>Dashboard</h1>
                <Outlet />
              </Main>
            </Grid>
          </Grid>
        </GridContainer>
      </section>
    </>
  )
}

export default AdminDashboard
