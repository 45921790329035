import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Grid, GridContainer, Link } from '@trussworks/react-uswds'
import { useAuthDispatch, userLogout } from '../contexts/AuthContext'
import Main from '../components/Main'

const LogoutPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isTimedOut, setIsTimedOut] = useState(false)
  const dispatch = useAuthDispatch()

  useEffect(() => {
    setIsLoading(true)
    const onLoad = async () => {
      const timedOut = JSON.parse(sessionStorage.getItem('timedOut')) || false
      setIsTimedOut(timedOut)
      await userLogout(dispatch)
      setIsLoading(false)
    }

    onLoad()
  }, [])

  return (
    <>
      <Helmet>
        <title>Logged Out</title>
      </Helmet>
      <GridContainer>
        <Grid row>
          <Grid tablet={{ col: true }}>
            <Main>
              <section className="usa-prose padding-y-4">
                {isLoading ? (
                  <>
                    <h2>Signing Out</h2>
                    <p>Logging you out. One moment please&hellip;</p>
                  </>
                ) : (
                  <>
                    {isTimedOut ? (
                      <>
                        <h1>Session Timed Out</h1>
                        <p className="usa-intro">Your session has timed out due to inactivity.</p>
                      </>
                    ) : (
                      <>
                        <h1>Signed Out</h1>
                        <p className="usa-intro">You have successfully signed out.</p>
                      </>
                    )}

                    <p>
                      Please <Link href={'login'}>sign in</Link> again to continue working in the Qualified Health Plan
                      Enrollee Experience Survey System website.
                    </p>
                    <p>
                      Contact us at <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a> if you need assistance.
                    </p>
                    <Link className="usa-button" variant="unstyled" href={'/'}>
                      Go to Home Page
                    </Link>
                    <Link className="usa-button usa-button--outline" variant="unstyled" href={'login'}>
                      Sign In
                    </Link>
                  </>
                )}
              </section>
            </Main>
          </Grid>
        </Grid>
      </GridContainer>
    </>
  )
}

export default LogoutPage
