import { useCallback, useEffect, useRef, useState } from 'react'
import { API } from 'aws-amplify'
import { decryptUsers } from '../../../lib/ciphertextLib'
import { Button, ButtonGroup, Grid, Icon, Modal, ModalFooter, ModalHeading, Tooltip } from '@trussworks/react-uswds'
import { useConfig } from '../../../contexts/ConfigContext'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import UserForm from './UserForm'
import Notifications from '../../Notifications'
import Skeleton from 'react-loading-skeleton'
import useAdminStore from '../../../stores/useAdminStore'
import useGlobalStore from '../../../stores/useGlobalStore'
import classNames from 'classnames'
import { toast } from 'react-toastify'

const UsersList = () => {
  const users = useAdminStore((state) => state.allUsers)
  const setAllUsers = useAdminStore((state) => state.setAllUsers)
  const setCurrentUser = useAdminStore((state) => state.setCurrentUser)
  const updateUser = useAdminStore((state) => state.updateUser)
  const isAddMode = useAdminStore((state) => state.isAddMode)
  const setIsAddMode = useAdminStore((state) => state.setIsAddMode)
  const clearNotifications = useGlobalStore((state) => state.clearNotifications)
  const [isLoading, setIsLoading] = useState(true)
  const [reportingUnitsOptions, setReportingUnitsOptions] = useState([])

  const config = useConfig()

  const modalRef = useRef(null)
  const initModalRef = useCallback((node) => {
    if (node !== null && !node.modalIsOpen) {
      clearNotifications()
      setCurrentUser({})
    }

    modalRef.current = node
  }, [])
  const formRef = useRef(null)

  const customDataTableStyles = {
    tableWrapper: {
      style: { paddingTop: '0.25rem' },
    },
    pagination: {
      style: {
        fontSize: 'unset',
      },
      pageButtonsStyle: { borderRadius: '0.25rem' },
    },
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,
  }

  const rowActions = (row) => {
    return (
      <>
        <Tooltip
          label="Edit User"
          type="button"
          className="usa-button--unstyled padding-1"
          onClick={(e) => handleEdit(e, row)}
        >
          <Icon.Edit role="presentation" focusable="false" size={3} /> <span className="usa-sr-only">Edit</span>
        </Tooltip>
        <Tooltip
          label={`${row.status_ === 'Enabled' ? 'Disable' : 'Enable'} User`}
          type="button"
          className={classNames('usa-button--unstyled padding-1', { 'text-gray-50': row.status_ === 'Disabled' })}
          onClick={(e) => handleToggleUserStatus(e, row)}
        >
          {row.status_ === 'Enabled' ? (
            <>
              <Icon.ToggleOn role="presentation" focusable="false" size={3} />{' '}
              <span className="usa-sr-only">Disable</span>
            </>
          ) : (
            <>
              <Icon.ToggleOff role="presentation" focusable="false" size={3} />{' '}
              <span className="usa-sr-only">Enable</span>
            </>
          )}
        </Tooltip>
      </>
    )
  }

  const columns = [
    {
      name: 'First Name',
      selector: (row) => row.nameFirst,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: (row) => row.nameLast,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      grow: 3,
    },
    {
      name: 'Role',
      selector: (row) => row.userRole,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status_,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => rowActions(row),
    },
  ]

  const tableData = {
    columns,
    data: users,
    print: false,
    export: false,
    filterPlaceholder: 'Search users...',
  }

  const handleAdd = (e) => {
    e.preventDefault()
    setIsAddMode(true)
    setCurrentUser({})
    modalRef.current.toggleModal()
  }

  const handleEdit = (e, row) => {
    e.preventDefault()
    setIsAddMode(false)
    setCurrentUser(row)
    modalRef.current.toggleModal()
  }

  const handleToggleUserStatus = async (e, row) => {
    e.preventDefault()
    const data = { ...row, status_: row.status_ === 'Enabled' ? 'Disabled' : 'Enabled' }

    try {
      await API.post('qhp-survey-auth', '/user/update', { body: data })
      updateUser(data)
      toast.success('User status changed successfully.')
    } catch (error) {
      toast.error("We're sorry, there was a problem changing user status.")
      console.error(error)
    }
  }

  const toggleModal = () => {
    modalRef.current.toggleModal()
  }

  useEffect(() => {
    setIsLoading(true)
    // setError(false)

    const onLoad = async () => {
      try {
        // Get a list of users for current year.
        const users = await API.get('qhp-survey-auth', '/user/list')
        const decryptedUsers = decryptUsers(users)
        setAllUsers(decryptedUsers)
        // Get a list of available attestations for current year.
        // Use that data to build a list of available reporting units.
        const attestations = await API.get('qhp-survey-auth', '/attestation/reporting-unit/list')
        if (attestations.length) {
          let reportingUnits = attestations.map((attestation) => ({
            value: attestation.reportingUnit,
            label: attestation.reportingUnit,
          }))
          setReportingUnitsOptions(reportingUnits)
        }
      } catch (error) {
        // setError(error)
      }

      setIsLoading(false)
    }

    onLoad()
  }, [])

  return (
    <>
      <h2 className="margin-0">Users</h2>
      <Notifications scope="page" />
      {config.isLoading || isLoading ? (
        <>
          <Grid row gap>
            <Grid tablet={{ col: 12 }} className="margin-top-1">
              <Skeleton height="3rem" count={3} />
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="position-relative">
          <div className="position-absolute right-0 margin-top-05">
            <Button type="button" className="usa-button--small usa-button--icon" onClick={(e) => handleAdd(e)}>
              <Icon.AddCircle className="margin-right-05" role="presentation" focusable="false" />
              Add User
            </Button>
          </div>
          <DataTableExtensions {...tableData}>
            <DataTable
              keyField="PK"
              responsive
              striped
              pagination
              paginationPerPage={25}
              paginationRowsPerPageOptions={[25, 50, 75, 100]}
              paginationComponentOptions={paginationComponentOptions}
              sortIcon={<Icon.ArrowDownward role="presentation" />}
              defaultSortFieldId={2}
              customStyles={customDataTableStyles}
            />
          </DataTableExtensions>
          <Notifications srOnly />
          <Modal
            ref={initModalRef}
            isLarge
            id="user-edit"
            className="usa-modal--wide"
            aria-labelledby="user-edit-heading"
            aria-describedby="user-edit-description"
          >
            <ModalHeading id="user-edit-heading" className="margin-bottom-0">
              {isAddMode ? 'Add' : 'Edit'} User
            </ModalHeading>
            <UserForm
              config={config}
              reportingUnitsOptions={reportingUnitsOptions}
              toggleModal={toggleModal}
              ref={formRef}
            />
            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    formRef.current.querySelector('#buttonSubmit').click()
                  }}
                >
                  Save
                </Button>
                <Button
                  outline
                  onClick={() => {
                    formRef.current.querySelector('#buttonCancel').click()
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  )
}

export default UsersList
