import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardGroup,
  CardHeader,
  ComboBox,
  ErrorMessage,
  FormGroup,
  Icon,
  Label,
  Tooltip,
} from '@trussworks/react-uswds'
import { useForm, Controller } from 'react-hook-form'
import { parseReportingUnit } from '../../../util/string'
import { isEmpty } from 'lodash'
import useAdminStore from '../../../stores/useAdminStore'
import useGlobalStore from '../../../stores/useGlobalStore'
import confirm from '../../ConfirmDialog'

const UserReportingUnits = ({ config, reportingUnitsOptions }) => {
  const data = useAdminStore((state) => state.currentUser)
  const addUserReportingUnit = useAdminStore((state) => state.addUserReportingUnit)
  const deleteUserReportingUnit = useAdminStore((state) => state.deleteUserReportingUnit)
  const isAddMode = useAdminStore((state) => state.isAddMode)
  const setNotification = useGlobalStore((state) => state.setNotification)
  const clearNotifications = useGlobalStore((state) => state.clearNotifications)

  const formRef = useRef(null)

  const {
    register,
    reset,
    resetField,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      userPK: '',
      year_: '',
      reportingUnit: '',
    },
  })

  const onSubmit = async (data, e) => {
    e.preventDefault()
    clearNotifications()
    try {
      await API.post('qhp-survey-auth', '/user/attestation/reporting-unit/create', { body: data })
      addUserReportingUnit(data)
      setNotification({
        message: 'Reporting unit added successfully.',
        type: 'success',
        scope: 'modal',
        slim: true,
        headingLevel: 'h3',
      })
    } catch (error) {
      setNotification({
        message: "We're sorry, there was a problem adding reporting unit.",
        type: 'error',
        scope: 'modal',
        slim: true,
      })
    }
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({
        inline: 'start',
        behavior: 'smooth',
      })
    }
  }

  const handleDelete = async (PK, SK) => {
    if (
      await confirm({
        dialogTitle: 'Delete Reporting Unit',
        confirmation: `Delete reporting unit ${parseReportingUnit(PK)}?`,
        proceedLabel: 'Delete',
      })
    ) {
      clearNotifications()
      const data = { PK: PK, SK: SK }
      try {
        await API.post('qhp-survey-auth', '/user/attestation/reporting-unit/delete', { body: data })
        deleteUserReportingUnit(data)
        setNotification({
          message: 'Reporting unit deleted successfully.',
          type: 'success',
          scope: 'modal',
          slim: true,
          headingLevel: 'h3',
        })
      } catch (error) {
        setNotification({
          message: "We're sorry, there was a problem removing reporting unit.",
          type: 'error',
          scope: 'modal',
          slim: true,
        })
      }
    }
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({
        inline: 'start',
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    // setIsLoading(true)
    if (!isEmpty(data)) {
      resetField('userPK', { defaultValue: data.PK })
      resetField('year_', { defaultValue: config?.sys?.year_ })
    }

    // setIsLoading(false)
  }, [data, reset])

  return (
    <CardGroup>
      <Card gridLayout={{ tablet: { col: true } }} className="margin-top-3">
        <CardHeader>
          <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
            <Icon.Topic className="margin-right-05" role="presentation" focusable="false" size={3} /> Reporting Units
          </h2>
        </CardHeader>
        <CardBody>
          <p>Reporting units associated with this user</p>
          {isAddMode ? (
            <Alert type="info" slim>
              Please save the new user before adding reporting units.
            </Alert>
          ) : (
            <div ref={formRef} style={{ scrollMarginTop: '12rem' }}>
              <form className="usa-form usa-form--full" onSubmit={handleSubmit(onSubmit)} noValidate>
                <input {...register('userPK')} id={`userPK`} name="userPK" type="hidden" />
                <input {...register('year_')} id={`year`} name="year_" type="hidden" />
                <div className="display-flex flex-align-end">
                  <div className="flex-grow-1">
                    <FormGroup error={errors.reportingUnit} className="margin-top-0">
                      <Label htmlFor="availableReportingUnits" className="usa-sr-only">
                        Select a Reporting Unit
                      </Label>
                      {errors.reportingUnit && (
                        <ErrorMessage id="first-name-error-alert">{errors.reportingUnit.message}</ErrorMessage>
                      )}
                      <Controller
                        control={control}
                        name="reportingUnit"
                        defaultValue=""
                        rules={{
                          required: 'Please select a reporting unit',
                        }}
                        render={({ field: { onChange, value, ref } }) => (
                          <>
                            <ComboBox
                              inputRef={ref}
                              id="reportingUnit"
                              name="reportingUnit"
                              options={reportingUnitsOptions}
                              className="margin-top-0"
                              value={value}
                              onChange={onChange}
                            />
                          </>
                        )}
                      />
                    </FormGroup>
                  </div>
                  <Button type="submit" className="margin-left-1 margin-right-0" style={{ marginTop: '0' }}>
                    Add
                  </Button>
                </div>
              </form>
              {data?.reportingUnits?.length ? (
                <ul className="qhp-list qhp-list--hover">
                  {data.reportingUnits.map((ru, index) => (
                    <li key={`ru-${index}`}>
                      <div className="display-flex flex-align-center">
                        <div className="font-family-mono">{parseReportingUnit(ru.PK)}</div>
                        <div className="margin-left-auto">
                          <Tooltip
                            label="Delete Reporting Unit"
                            type="button"
                            className="usa-button--unstyled"
                            style={{ marginTop: '0' }}
                            onClick={() => {
                              handleDelete(ru.PK, ru.SK)
                            }}
                          >
                            <Icon.Delete role="presentation" focusable="false" />{' '}
                            <span className="usa-sr-only">Delete Reporting Unit</span>
                          </Tooltip>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <Alert type="info" slim>
                  There are no reporting units associated with this user.
                </Alert>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </CardGroup>
  )
}

UserReportingUnits.propTypes = {
  config: PropTypes.object.isRequired,
  reportingUnitsOptions: PropTypes.array.isRequired,
}

export default UserReportingUnits
