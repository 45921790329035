import { useEffect, useState } from 'react'
import { Alert, Card, CardBody, CardGroup, CardHeader, Grid, GridContainer, Icon } from '@trussworks/react-uswds'
import ButtonCsvDownload from '../../ButtonCsvDownload'

const Reports = () => {
  const [csvError, setCsvError] = useState(false)

  useEffect(() => {}, [])

  return (
    <GridContainer>
      <Grid row gap>
        <Grid tablet={{ col: true }}>
          <h2 className="margin-0">Admin Reports</h2>
          <CardGroup>
            <Card gridLayout={{ tablet: { col: 6 } }} containerProps={{ className: 'usa-card--light' }}>
              <CardHeader>
                <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                  <Icon.FileDownload className="margin-right-05" role="presentation" focusable="false" size={3} />
                  Issuer CSV Reports
                </h2>
              </CardHeader>
              <CardBody>
                {csvError && (
                  <Alert className="margin-bottom-3" type="error" headingLevel="h4" slim>
                    {csvError}
                  </Alert>
                )}
                <ul className="qhp-list qhp-list--unstyled qhp-list--buttons">
                  <li>
                    <ButtonCsvDownload
                      label="Attestation & Vendor Selection Summary Report"
                      filename="IssuerAttestationVendorSelectionSummaryReport.csv"
                      apiPath="/reports/issuer/attestationsummary"
                      setCsvError={setCsvError}
                    />
                  </li>
                  <li>
                    <ButtonCsvDownload
                      label="Attestation & Vendor Selection Detail Report "
                      filename="IssuerAttestationVendorSelectionDetailReport.csv"
                      apiPath="/reports/issuer/attestationdetails"
                      setCsvError={setCsvError}
                    />
                  </li>
                </ul>
              </CardBody>
            </Card>
            <Card gridLayout={{ tablet: { col: 6 } }} containerProps={{ className: 'usa-card--light' }}>
              <CardHeader>
                <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                  <Icon.FileDownload className="margin-right-05" role="presentation" focusable="false" size={3} />
                  Vendor CSV Reports
                </h2>
              </CardHeader>
              <CardBody>
                {csvError && (
                  <Alert className="margin-bottom-3" type="error" headingLevel="h4" slim>
                    {csvError}
                  </Alert>
                )}
                <ul className="qhp-list qhp-list--unstyled qhp-list--buttons">
                  <li>
                    <ButtonCsvDownload
                      label="Survey Final Submission Report"
                      filename="VendorSurveyFinalSubmissionReport.csv"
                      apiPath="/reports/vendor/surveysubmissions"
                      setCsvError={setCsvError}
                    />
                  </li>
                </ul>
              </CardBody>
            </Card>
          </CardGroup>
        </Grid>
      </Grid>
    </GridContainer>
  )
}

export default Reports
