import { useLayoutEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Alert, Card, CardBody, CardGroup, CardHeader, Grid, GridContainer, Icon } from '@trussworks/react-uswds'
import Skeleton from 'react-loading-skeleton'
import { useConfig } from '../contexts/ConfigContext'
import { useAuth } from '../contexts/AuthContext'
import Main from './Main'
import ContactInfo from './ContactInfo'

const ManagerDashboard = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [manager, setManager] = useState(null)
  const config = useConfig()
  const user = useAuth()

  useLayoutEffect(() => {
    // Get list of managers associated with logged in user
    const fetchManagerData = async () => {
      setIsLoading(true)

      if (!config.isLoading && !user.isLoading) {
        if (user.info?.userRole === 'Manager') {
          try {
            const dashboard = await API.get('qhp-survey-auth', '/manager/dashboard')

            setManager({
              config: dashboard.config,
              user: user.info,
            })
          } catch (error) {
            console.error(error)
          }
        }
      }
      setIsLoading(false)
    }

    fetchManagerData()
  }, [config.isLoading, user.isLoading, user.info])

  return (
    <>
      <GridContainer>
        <Grid row gap>
          <Grid tablet={{ col: true }}>
            <Main>
              <section className="usa-section usa-prose usa-prose--wide">
                <h1>Dashboard</h1>
                {config.isLoading || user.isLoading || isLoading ? (
                  <>
                    <Grid row gap={2}>
                      <Grid tablet={{ col: 6 }}>
                        <Skeleton height="12rem" />
                      </Grid>
                      <Grid tablet={{ col: 6 }}>
                        <Skeleton height="12rem" />
                      </Grid>
                      <Grid tablet={{ col: 12 }} className="margin-top-1">
                        <Skeleton height="12rem" />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {!manager && (
                      <Alert type="info">
                        Your account is not currently have access to this site. If you feel this is an error, please
                        contact us at <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a>
                      </Alert>
                    )}
                    {manager && (
                      <>
                        <CardGroup>
                          <Card gridLayout={{ tablet: { col: 4 } }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.AccountCircle
                                  className="margin-right-05"
                                  role="presentation"
                                  focusable="false"
                                  size={3}
                                />{' '}
                                Manager Information
                              </h2>
                            </CardHeader>
                            <CardBody>
                              <address>
                                <p>
                                  <strong className="text-base">Contact:</strong>
                                  <br />
                                  <ContactInfo contact={manager.user} />
                                </p>
                              </address>
                            </CardBody>
                          </Card>
                          <Card gridLayout={{ tablet: { col: 8 } }} containerProps={{ className: 'usa-card--light' }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.Build
                                  className="margin-right-05"
                                  role="presentation"
                                  focusable="false"
                                  size={3}
                                />{' '}
                                Manage Website
                              </h2>
                            </CardHeader>
                            <CardBody>
                              <p>Manage Vendors</p>
                              <p>Manage Issuers</p>
                              <p>Manage FAQs</p>
                              <p>
                                <br />
                              </p>
                            </CardBody>
                          </Card>
                          <Card gridLayout={{ tablet: { col: 12 } }}>
                            <CardHeader>
                              <h2 className="usa-card__heading display-flex flex-row flex-align-center text-primary">
                                <Icon.Edit className="margin-right-05" role="presentation" focusable="false" size={3} />
                              </h2>
                            </CardHeader>
                            <CardBody></CardBody>
                          </Card>
                        </CardGroup>
                        <Alert type="info" heading="Note" headingLevel="h4"></Alert>
                      </>
                    )}
                  </>
                )}
              </section>
            </Main>
          </Grid>
        </Grid>
      </GridContainer>
      <section className="usa-section qhp-section--light usa-prose usa-prose--wide">
        <GridContainer>
          <Grid row gap>
            <Grid tablet={{ col: true }}>
              <p></p>
            </Grid>
          </Grid>
        </GridContainer>
      </section>
    </>
  )
}

export default ManagerDashboard
