import { useState } from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'
import { Button } from '@trussworks/react-uswds'
import useCsvDownloader from 'use-csv-downloader'

const ButtonCsvDownload = ({ label, filename, apiPath, setCsvError }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadCsv = async () => {
    setIsLoading(true)
    setCsvError(false)

    const downloadCsv = useCsvDownloader()

    try {
      const data = await API.get('qhp-survey-auth', apiPath)
      downloadCsv(data, filename)
    } catch (err) {
      setCsvError("We're sorry, there was an error generating the report.")
    }

    setIsLoading(false)
  }

  return (
    <Button className="usa-button usa-button--block" onClick={() => handleDownloadCsv()}>
      {isLoading ? 'Generating File\u2026' : label}
    </Button>
  )
}

ButtonCsvDownload.propTypes = {
  label: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  apiPath: PropTypes.string.isRequired,
  setCsvError: PropTypes.func.isRequired,
}

export default ButtonCsvDownload
