var CryptoJS = require('crypto-js')

const passphrase = 'QHP Surv3y Encrypt10n P422phr4s3 2Encrypt D3crypt S3n21t1v3 D4t4'

/**
 * Encrypt Export Functions
 */

/**
 * Utility function to encrypt text using the AES encryption algorithm.
 *
 * @param {string} text - Text to encrypt using AES algorithm.
 * @returns  {string} AES encrypted string.
 */
export function encrypt(text) {
  return encryptText(text)
}

/**
 * Encrypt user's personal information, but leave other non-sensitive data as-is.
 *
 * @param {object} user - User data to be encrypted.
 * @returns {object} A user object with encrypted data. Includes only fields that have data (are defined).
 */
export function encryptUser(user) {
  // Encrypt selected personal data.
  const encryptedData = {
    nameFirst: encryptText(user.nameFirst),
    nameLast: encryptText(user.nameLast),
    email: encryptText(user.email),
    note: encryptText(user.note),
  }

  // Merge encrypted data back into the original user object.
  // This approach doesn't lock the user object into a particular "shape," and
  // allows for a varying number of unencrypted attributes in the user object.
  // This method would need to be refactored only if new encrypted fields are introduced.
  const userData = { ...user, ...encryptedData }
  return JSON.parse(JSON.stringify(userData))
}

/**
 * Encrypt a list of users.
 *
 * @param {array} users - An array of unencrypted user objects.
 * @returns An array of encrypted user objects.
 */
export function encryptUsers(users) {
  let ciphered = []
  for (const user of users) {
    let jsonItem = encryptUser(user)
    ciphered.push(jsonItem)
  }
  return ciphered
}

/**
 * Generate a user ID based on the user's email and provided year.
 * Used as the PK and SK on user records, and SK on other related records.
 *
 * @param {string} email - User's email address.
 * @param {string} year - Year that user is active (usually current program year).
 * @returns {string} A user ID including lowercase hashed user email address.
 * @todo Consider adding some error checking or a default value if email or year are not provided.
 */
export function generateUserId(email, year) {
  return 'USER#' + CryptoJS.SHA3(email.toLowerCase()).toString() + '#YEAR#' + year
}

/**
 * Decrypt Export Functions
 */
export function decrypt(encryptedText) {
  return decryptText(encryptedText)
}

/**
 * Decrypts user's personal information, but leaves other data as-is.
 *
 * @param {object} user - User data to be decrypted.
 * @returns {object} A user object with decrypted data. Includes only fields that have data (are defined).
 */
export function decryptUser(user) {
  // Decrypt selected personal data.
  const decryptedData = {
    nameFirst: decryptText(user.nameFirst),
    nameLast: decryptText(user.nameLast),
    email: decryptText(user.email),
    note: decryptText(user.note),
  }

  // Merge decrypted data back into the original user object.
  // This approach doesn't lock the user object into a particular "shape," and
  // allows for a varying number of unencrypted attributes in the user object.
  // This method would need to be refactored only if new encrypted fields are introduced.
  const userData = { ...user, ...decryptedData }
  return JSON.parse(JSON.stringify(userData))
}

/**
 * Decrypt a list of users.
 *
 * @param {array} users - An array of encrypted user objects
 * @returns An array of decrypted user objects.
 */
export function decryptUsers(users) {
  let ciphered = []
  for (const user of users) {
    let jsonItem = decryptUser(user)
    ciphered.push(jsonItem)
  }
  return ciphered
}

/**
 * Hash Function
 *
 * @param {string} text - A string to be hashed.
 * @returns {string} A hashed string.
 */
export function hash(text) {
  return hashText(text)
}

/**
 * Local Functions
 */

/**
 * Encrypt text using the AES encryption algorithm.
 *
 * @param {string} text - Text to encrypt using AES algorithm.
 * @returns {string} AES encrypted string.
 */
function encryptText(text) {
  return CryptoJS.AES.encrypt(text, passphrase, { mode: CryptoJS.mode.CBC }).toString()
}

/**
 * Decrypt text that was encrypted using the AES encryption algorithm.
 *
 * @param {string} text - Text to decrypt using AES algorithm.
 * @returns {string} AES decrypted string.
 */
function decryptText(text) {
  const bytes = CryptoJS.AES.decrypt(text, passphrase, { mode: CryptoJS.mode.CBC })
  return bytes.toString(CryptoJS.enc.Utf8)
}

/**
 * Hash text using SHA3 hash algorithm.
 *
 * @param {string} text - Text to hash using SHA3 hash algorithm.
 * @returns {string} Text hashed using SHA3 hash algorithm.
 */
function hashText(text) {
  return CryptoJS.SHA3(text).toString()
}
