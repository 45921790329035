import { Helmet } from 'react-helmet-async'
import { Grid, GridContainer, Link } from '@trussworks/react-uswds'
import { useAuth } from '../contexts/AuthContext'
import Main from '../components/Main'

const IssuersPage = () => {
  const user = useAuth()

  return (
    <>
      <Helmet>
        <title>Issuers</title>
      </Helmet>
      <section className="usa-section">
        <GridContainer>
          <Grid row gap>
            <Grid tablet={{ col: 8 }}>
              <Main>
                <div className="usa-prose usa-prose--wide">
                  <h1>Issuers</h1>
                  <p>
                    Qualified Health Plan (QHP) issuers that offered coverage through an Exchange in the previous two
                    years are required to submit QHP Enrollee Experience Survey response data to CMS as a condition of
                    certification. QHP issuers are required to collect and submit QHP Enrollee Experience Survey
                    response data for each unique combination of product type and state. Product types include Exclusive
                    Provider Organization (EPO), Health Maintenance Organization (HMO), Point of Service (POS), and
                    Preferred Provider Organization (PPO). For more information, please visit the{' '}
                    <Link
                      variant="external"
                      href={
                        'https://www.cms.gov/Medicare/Quality-Initiatives-Patient-Assessment-Instruments/QualityInitiativesGenInfo/ACA-MQI/ACA-MQI-Landing-Page'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="CMS Marketplace Quality Initiatives (MQI) website, external"
                    >
                      CMS Marketplace Quality Initiatives (MQI) website.
                    </Link>
                  </p>
                  <p>
                    QHP issuers with <b>eligible</b> reporting units that meet the participation criteria for the 2025
                    QRS and QHP Enrollee Survey must:
                  </p>
                  <ol>
                    <li>
                      Contract with a HEDIS® Compliance Auditor to validate the QHP Enrollee Survey sample frame and the
                      QRS clinical measure data.
                    </li>
                    <li>
                      Contract with an HHS-approved vendor to conduct the QHP Enrollee Survey and submit survey response
                      data to CMS.
                    </li>
                    <li>
                      Generate QHP Enrollee Survey sample frame on or after January 7, 2025. HEDIS Compliance Auditor
                      completes sample frame validation by January 31, 2025.
                    </li>
                    <li>
                      Attest to the QRS and QHP Enrollee Survey eligibility criteria via this website by January 31,
                      2025. This includes confirming reporting unit information, reporting survey vendor selection to
                      CMS, and confirming sample frame validation.
                    </li>
                    <li>
                      Complete the National Committee for Quality Assurance (NCQA)’s Healthcare Organization
                      Questionnaire (HOQ) to request QRS submission.
                    </li>
                  </ol>
                  <p>
                    QHP issuers with <b>ineligible</b> reporting units that do not meet the participation criteria for
                    the 2025 QRS and QHP Enrollee Survey must report ineligibility status to CMS via this website by
                    January 31, 2025.
                  </p>
                  <p>
                    Only authorized points of contact identified by each issuer will receive an email to create an
                    account and access this website for completion of these steps. To update the designated contacts for
                    your organization or for any questions related to accessing this website and completing the
                    attestation, please contact us at <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a>.
                  </p>
                </div>
              </Main>
            </Grid>
            <Grid tablet={{ col: 4 }}>
              <div className="qhp-callout">
                {user.isLoggedIn ? (
                  <div className="usa-prose">
                    <h2>Welcome, {user.info.nameFirst}</h2>
                    <p>Access your QHP Enrollee Experience Survey Dashboard.</p>
                    <Link className="usa-button qhp-link--signin" variant="unstyled" href={'dashboard'}>
                      Go to Dashboard
                    </Link>
                  </div>
                ) : (
                  <div className="usa-prose">
                    <h3>Sign In</h3>
                    <p>
                      QHP issuers may log in here to complete the attestation process for eligible <b>and</b> ineligible
                      reporting units by January 31, 2025.
                    </p>
                    <Link className="usa-button qhp-link--signin" variant="unstyled" href={'login'}>
                      Sign In
                    </Link>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </GridContainer>
      </section>
    </>
  )
}

export default IssuersPage
