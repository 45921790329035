import { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'

const ConfigContext = createContext({ isLoading: true, sys: {} })

const initialState = {
  isLoading: true,
  sys: {},
}

// Context Providers and Hooks
const useConfig = () => {
  const context = useContext(ConfigContext)

  // If context is 'undefined', throw an error
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }

  return context
}

const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(initialState)

  useEffect(() => {
    const onLoad = async () => {
      // Get global system configurations
      try {
        const configSystem = await API.get('qhp-survey', '/config/system')
        setConfig({ sys: configSystem })
      } catch (error) {
        console.error('SYS CONFIG ERROR:', error)
      }

      setConfig((prev) => {
        return { ...prev, isLoading: false }
      })
    }

    onLoad()
  }, [])

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { ConfigProvider, useConfig }
