import { forwardRef, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'
import { generateUserId } from '../../../lib/ciphertextLib'
import { Dropdown, FormGroup, ErrorMessage, Grid, Label, Textarea, TextInput } from '@trussworks/react-uswds'
import { useForm, Controller } from 'react-hook-form'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import UserReportingUnits from './UserReportingUnits'
import useAdminStore from '../../../stores/useAdminStore'
import useGlobalStore from '../../../stores/useGlobalStore'
import Notifications from '../../Notifications'
import confirm from '../../ConfirmDialog'
import { toast } from 'react-toastify'

const UserForm = forwardRef(({ config, reportingUnitsOptions, toggleModal }, ref) => {
  const data = useAdminStore((state) => state.currentUser)
  const setCurrentUser = useAdminStore((state) => state.setCurrentUser)
  const addUser = useAdminStore((state) => state.addUser)
  const updateUser = useAdminStore((state) => state.updateUser)
  const replaceUser = useAdminStore((state) => state.replaceUser)
  const isAddMode = useAdminStore((state) => state.isAddMode)
  const setIsAddMode = useAdminStore((state) => state.setIsAddMode)
  const setNotification = useGlobalStore((state) => state.setNotification)
  const clearNotifications = useGlobalStore((state) => state.clearNotifications)
  const [showReportingUnits, setShowReportingUnits] = useState(false)

  const formRef = useRef(null)

  const defaultValues = {
    PK: '',
    year_: '',
    nameFirst: '',
    nameLast: '',
    email: '',
    userRole: '',
    note: '',
    status_: '',
  }

  const {
    register,
    reset,
    resetField,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: defaultValues,
  })

  const emailExists = async (value) => {
    const newPK = generateUserId(value, config?.sys?.year_)
    let exists = false
    if (data.PK !== newPK) {
      try {
        exists = await API.get('qhp-survey-auth', `/user/get/${encodeURIComponent(newPK)}`)
      } catch (error) {
        console.error(error)
        return 'There was an error validating this email address'
      }
    }
    return !exists || 'This email address is already in use for another user'
  }

  const onSubmit = async (data, e) => {
    e.preventDefault()
    clearNotifications()
    if (isAddMode) {
      try {
        await API.post('qhp-survey-auth', '/user/create', { body: data })
        setNotification({
          message: 'User added successfully. Add associated reporting units or close this dialog to continue.',
          type: 'success',
          scope: 'modal',
          slim: true,
        })
        addUser(data)
        setIsAddMode(false)
      } catch (error) {
        setNotification({
          message: "We're sorry, there was a problem adding user.",
          type: 'error',
          scope: 'modal',
          slim: true,
        })
      }
    } else {
      try {
        await API.post('qhp-survey-auth', '/user/update', { body: data })
        // If the email has changed, delete old row and insert a new one.
        const newPK = generateUserId(data.email, config?.sys?.year_)
        if (data.PK !== newPK) {
          replaceUser(data)
        } else {
          // Simply update existing row in state.
          updateUser(data)
        }
        toggleModal()
        toast.success('User information saved successfully.')
      } catch (error) {
        setNotification({
          message: "We're sorry, there was a problem saving user information.",
          type: 'error',
          scope: 'modal',
          slim: true,
        })
      }
    }
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({
        inline: 'start',
        behavior: 'smooth',
      })
    }
  }

  const handleCancel = () => {
    toggleModal()
    setCurrentUser({})
  }

  const handleRoleChange = async (e) => {
    if (e.target.value !== 'Issuer' && data?.reportingUnits?.length) {
      if (
        await confirm({
          dialogTitle: 'Associated Reporting Units',
          confirmation:
            "This user has associated reporting units. Please delete all associated reporting units before changing the user's role.",
          proceedLabel: 'OK',
          alertOnly: true,
        })
      ) {
        resetField('userRole')
      }
    } else {
      setShowReportingUnits(false)
      if (e.target.value === 'Issuer') {
        setShowReportingUnits(true)
      }
    }
  }

  const sortReportingUnits = () => {
    data.reportingUnits.sort((a, b) => a.PK.localeCompare(b.PK))
  }

  useEffect(() => {
    // setIsLoading(true)
    setShowReportingUnits(false)
    if (!isEmpty(data)) {
      reset({
        PK: data.PK,
        year_: data.year_,
        nameFirst: data.nameFirst,
        nameLast: data.nameLast,
        email: data.email,
        note: data.note,
        userRole: data.userRole,
        status_: data.status_,
      })
      if (data?.userRole === 'Issuer' && !isAddMode) {
        sortReportingUnits()
        setShowReportingUnits(true)
      }
    } else {
      reset({
        PK: '',
        year_: config?.sys?.year_,
        nameFirst: '',
        nameLast: '',
        email: '',
        note: '',
        userRole: '',
        status_: '',
      })
    }

    // setIsLoading(false)
  }, [data, reset])

  return (
    <div ref={formRef} style={{ scrollMarginTop: '8rem' }}>
      <Grid row gap>
        <Grid tablet={{ col: true }}>
          <Notifications scope="modal" />
        </Grid>
      </Grid>
      <Grid row gap>
        <Grid tablet={{ col: 6 }}>
          <form ref={ref} className="usa-form usa-form--full" onSubmit={handleSubmit(onSubmit)} noValidate>
            <input {...register('PK')} id="PK" name="PK" type="hidden" />
            <input {...register('year_')} id="year" name="year_" type="hidden" />
            <FormGroup error={errors.nameFirst}>
              <Label htmlFor="first-name" className="text-bold" error={errors.nameFirst}>
                First Name{' '}
                <span className="usa-hint usa-hint--required" aria-hidden="true">
                  *
                </span>
              </Label>
              {errors.nameFirst && <ErrorMessage id="first-name-error-alert">{errors.nameFirst.message}</ErrorMessage>}
              <Controller
                control={control}
                name="nameFirst"
                defaultValue=""
                rules={{
                  required: 'Please enter a first name',
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <TextInput
                      inputRef={ref}
                      id="first-name"
                      className={classNames({ 'usa-input--error': errors.nameFirst })}
                      aria-describedby="first-name-error-alert"
                      aria-required="true"
                      value={value}
                      onChange={onChange}
                    />
                  </>
                )}
              />
            </FormGroup>
            <FormGroup error={errors.nameLast}>
              <Label htmlFor="last-name" className="text-bold" error={errors.nameLast}>
                Last Name{' '}
                <span className="usa-hint usa-hint--required" aria-hidden="true">
                  *
                </span>
              </Label>
              {errors.nameLast && <ErrorMessage id="last-name-error-alert">{errors.nameLast.message}</ErrorMessage>}
              <Controller
                control={control}
                name="nameLast"
                defaultValue=""
                rules={{
                  required: 'Please enter a last name',
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <TextInput
                    inputRef={ref}
                    id="last-name"
                    className={classNames({ 'usa-input--error': errors.nameLast })}
                    aria-describedby="last-name-error-alert"
                    aria-required="true"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormGroup>
            <FormGroup error={errors.email}>
              <Label htmlFor="email" className="text-bold" error={errors.email}>
                Email{' '}
                <span className="usa-hint usa-hint--required" aria-hidden="true">
                  *
                </span>
              </Label>
              {errors.email && <ErrorMessage id="email-error-alert">{errors.email.message}</ErrorMessage>}
              <Controller
                control={control}
                name="email"
                defaultValue=""
                rules={{
                  required: 'Please enter an email address',
                  pattern: {
                    value: /^([A-Z0-9_+-]+\.?)*[A-Z0-9_+-]@([A-Z0-9][A-Z0-9-]*\.)+[A-Z]{2,}$/i,
                    message: 'Please enter a valid email address',
                  },
                  validate: emailExists,
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <TextInput
                    type="email"
                    inputRef={ref}
                    id="email"
                    className={classNames({ 'usa-input--error': errors.email })}
                    aria-describedby="email-error-alert"
                    aria-required="true"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </FormGroup>
            <FormGroup error={errors.userRole}>
              <Label htmlFor="user-role" className="text-bold" error={errors.userRole}>
                Role{' '}
                <span className="usa-hint usa-hint--required" aria-hidden="true">
                  *
                </span>
              </Label>
              {errors.userRole && <ErrorMessage id="user-role-error-alert">{errors.userRole.message}</ErrorMessage>}
              <Controller
                control={control}
                name="userRole"
                defaultValue=""
                rules={{
                  required: 'Please select a user role',
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <Dropdown
                    inputRef={ref}
                    id="user-role"
                    className={classNames({ 'usa-input--error': errors.userRole })}
                    aria-describedby="user-role-error-alert"
                    aria-required="true"
                    value={value}
                    onChange={(e) => {
                      handleRoleChange(e)
                      onChange(e)
                    }}
                  >
                    <option value="">- Please Select a User Role -</option>
                    <option value="Issuer">Issuer</option>
                    <option value="Vendor">Vendor</option>
                    <option value="Analyst">Analyst</option>
                    <option value="Administrator">Administrator</option>
                  </Dropdown>
                )}
              />
            </FormGroup>
            <FormGroup error={errors.status_}>
              <Label htmlFor="status" className="text-bold" error={errors.status_}>
                User Status{' '}
                <span className="usa-hint usa-hint--required" aria-hidden="true">
                  *
                </span>
              </Label>
              {errors.status_ && <ErrorMessage id="user-role-error-alert">{errors.status_.message}</ErrorMessage>}
              <Controller
                control={control}
                name="status_"
                defaultValue=""
                rules={{
                  required: 'Please select a user status',
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <Dropdown
                    inputRef={ref}
                    id="status"
                    className={classNames({ 'usa-input--error': errors.status_ })}
                    aria-describedby="user-role-error-alert"
                    aria-required="true"
                    value={value}
                    onChange={onChange}
                  >
                    <option value="">- Please Select a User Status -</option>
                    <option value="Enabled">Enabled</option>
                    <option value="Disabled">Disabled</option>
                  </Dropdown>
                )}
              />
            </FormGroup>
            <FormGroup error={errors.note}>
              <Label htmlFor="note" className="text-bold" error={errors.note}>
                Note
              </Label>
              {errors.note && <ErrorMessage id="user-role-error-alert">{errors.note.message}</ErrorMessage>}
              <Controller
                control={control}
                name="note"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Textarea
                    inputRef={ref}
                    id="note"
                    className={classNames({ 'usa-input--error': errors.note })}
                    aria-describedby="note-error-alert"
                    value={value}
                    onChange={onChange}
                    style={{ width: '100%', height: '150px' }}
                  />
                )}
              />
            </FormGroup>
            <button id="buttonSubmit" className="display-none" type="submit">
              Save
            </button>
            <button id="buttonCancel" className="display-none" type="button" onClick={() => handleCancel()}>
              Cancel
            </button>
          </form>
        </Grid>
        <Grid tablet={{ col: 6 }}>
          {showReportingUnits ? (
            <UserReportingUnits config={config} reportingUnitsOptions={reportingUnitsOptions} />
          ) : null}
        </Grid>
      </Grid>
    </div>
  )
})

UserForm.displayName = 'UserForm'

UserForm.propTypes = {
  config: PropTypes.object.isRequired,
  reportingUnitsOptions: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired,
}

export default UserForm
