/**
 * Generate a reporting unit ID based on the reporting unit and provided year.
 * Used as the PK and SK on reporting unit records, and SK on other related records.
 *
 * @param {string} reportingUnit - Reporting unit.
 * @param {string} year - Year that user is active (usually current program year).
 * @returns {string} Returns a reporting unit ID.
 */
const generateReportingUnitId = (reportingUnit, year) => {
  return `ATTESTATION#REPORTINGUNIT#${reportingUnit}#YEAR#${year}`
}

/**
 * Parse the reporting unit ID out of the PK string from the DynamoDb record.
 *
 * @param {string} reportingUnitId - The reporting unit PK from the DynamoDb record.
 * @returns {string} Returns the base reporting unit ID or an empty string.
 */
const parseReportingUnit = (reportingUnitId) => {
  const regex = /^ATTESTATION#REPORTINGUNIT#(.*)#YEAR#(\d{4})$/i
  const matches = reportingUnitId.match(regex)
  const reportingUnit = (matches && matches[1]) || ''
  return reportingUnit
}

export { generateReportingUnitId, parseReportingUnit }
