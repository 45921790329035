import { Helmet } from 'react-helmet-async'
import { Grid, GridContainer } from '@trussworks/react-uswds'
import Main from '../components/Main'
import FaqList from '../components/FaqList'

const FaqPage = () => {
  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions (FAQ)</title>
      </Helmet>
      <GridContainer>
        <Grid row gap>
          <Grid tablet={{ col: true }}>
            <Main>
              <section className="usa-section usa-prose">
                <h1>Frequently Asked Questions (FAQ)</h1>
                <FaqList />
              </section>
            </Main>
          </Grid>
        </Grid>
      </GridContainer>
    </>
  )
}

export default FaqPage
