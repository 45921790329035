import { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Accordion, Alert } from '@trussworks/react-uswds'
import { createMarkup } from '../util/html'
import Skeleton from 'react-loading-skeleton'

const FaqList = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)

  const loadData = () => {
    return API.get('qhp-survey', '/faq/list')
  }

  // Format the results for use with the react-uswds <Accordion /> component
  const formatData = (items) => {
    let categories = []
    items.faqs.forEach((faq) => {
      let formatted = []
      faq.faqItems.forEach((item) => {
        let jsonItem = {
          id: item.PK,
          title: item.question,
          content: <span dangerouslySetInnerHTML={createMarkup(item.answer)} />,
          expanded: false,
          headingLevel: 'h3',
        }
        formatted.push(jsonItem)
      })
      categories.push({
        categoryName: faq.category,
        faqs: formatted,
      })
    })
    return categories
  }

  useEffect(() => {
    setIsLoading(true)
    setError(false)

    const onLoad = async () => {
      try {
        const result = await loadData()
        const formatted = formatData(result)
        setData(formatted)
      } catch (error) {
        setError(error)
      }

      setIsLoading(false)
    }

    onLoad()
  }, [])

  return (
    <>
      {error ? (
        <Alert type="error" headingLevel="h2" heading="Error">
          {error?.message ? error.message : 'An error has occurred.'}
        </Alert>
      ) : (
        <>
          {isLoading ? (
            <div className="margin-top-2">
              <Skeleton height="3rem" count={3} className="margin-bottom-1" />
            </div>
          ) : (
            <>
              {}
              {data?.length > 0 ? (
                data.map(
                  (category) =>
                    category?.faqs?.length > 0 && (
                      <div key={category.categoryName}>
                        <h2>{category.categoryName}</h2>
                        <div className="usa-accordion__wrapper">
                          <Accordion items={category.faqs} bordered={false} multiselectable={true} />
                        </div>
                      </div>
                    )
                )
              ) : (
                <Alert type="info">No FAQ records found.</Alert>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default FaqList
