import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Alert } from '@trussworks/react-uswds'

const ContactInfo = ({ contact }) => {
  return (
    <>
      {!isEmpty(contact) ? (
        <>
          {contact.nameFirst} {contact.nameLast}
          <br />
          Email: <a href={`mailto:${contact.email}`}>{contact.email}</a>
        </>
      ) : (
        <Alert type="error">There was an error loading contact information.</Alert>
      )}
    </>
  )
}

ContactInfo.propTypes = {
  contact: PropTypes.object.isRequired,
}

export default ContactInfo
