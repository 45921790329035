import * as React from 'react'
import PropTypes from 'prop-types'

const LogoCMS = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 171 41"
    style={{
      enableBackground: 'new 0 0 171 41',
    }}
    xmlSpace="preserve"
    role="img"
    aria-labelledby={`${props.id}-title`}
    {...props}
  >
    <title id={`${props.id}-title`}>CMS.gov Logo</title>
    <style>
      {
        '.st1,.st5,.st6{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}.st5,.st6{fill:#383838}.st6{fill:#0d2499}.st7{fill:none;stroke:#0d2499}'
      }
    </style>
    <g id="CMSgov-Logo">
      <g id="Group-4" transform="translate(90 9)">
        <defs>
          <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x={0.8} y={18.8} width={5} height={5.1}>
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
          </filter>
        </defs>
        <mask
          maskUnits="userSpaceOnUse"
          x={0.8}
          y={18.8}
          width={5}
          height={5.1}
          id="mask-2_00000181083692473065748320000005638640274782412213_"
        >
          <g
            style={{
              filter: 'url(#Adobe_OpacityMaskFilter)',
            }}
          >
            <path
              id="path-1_00000174595355092566419560000002786709597218609550_"
              className="st1"
              d="M.8.4h31v31.5H.8z"
            />
          </g>
        </mask>
        <path
          id="Fill-1"
          d="M3.3 18.8c.7 0 1.3.2 1.8.7s.7 1.1.7 1.8-.2 1.3-.7 1.8c-.5.5-1.1.7-1.8.7s-1.3-.2-1.8-.7C1 22.6.8 22 .8 21.3s.2-1.3.7-1.8 1.1-.7 1.8-.7"
          style={{
            mask: 'url(#mask-2_00000181083692473065748320000005638640274782412213_)',
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: '#383838',
          }}
        />
        <defs>
          <filter
            id="Adobe_OpacityMaskFilter_00000158018136521635780700000008857142368500477862_"
            filterUnits="userSpaceOnUse"
            x={8.5}
            y={0.4}
            width={23.3}
            height={31.6}
          >
            <feColorMatrix values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0" />
          </filter>
        </defs>
        <mask
          maskUnits="userSpaceOnUse"
          x={8.5}
          y={0.4}
          width={23.3}
          height={31.6}
          id="mask-2_00000109730938504462504710000004793253776933360276_"
        >
          <g
            style={{
              filter: 'url(#Adobe_OpacityMaskFilter_00000158018136521635780700000008857142368500477862_)',
            }}
          >
            <path
              id="path-1_00000049936832477608746580000016713505633821355909_"
              className="st1"
              d="M.8.4h31v31.5H.8z"
            />
          </g>
        </mask>
        <path
          id="Fill-3"
          d="M20.5 3.1c-1.6 0-3.1.4-4.5 1.2-1.4.8-2.5 1.9-3.3 3.3-.8 1.4-1.2 2.9-1.2 4.5 0 2.4.8 4.5 2.5 6.1 1.6 1.6 3.8 2.4 6.4 2.4 2.6 0 4.8-.8 6.4-2.4 1.6-1.6 2.4-3.7 2.4-6.3 0-1.7-.4-3.2-1.1-4.5-.7-1.3-1.8-2.4-3.1-3.1-1.5-.8-3-1.2-4.5-1.2zM28.9 1h2.9v17.8c0 3.1-.3 5.4-.8 6.9-.8 2.1-2.1 3.6-3.9 4.7-1.9 1-4.1 1.6-6.8 1.6-1.9 0-3.6-.3-5.2-.8-1.5-.5-2.8-1.3-3.7-2.2s-1.8-2.2-2.6-4h3.1c.8 1.5 1.9 2.6 3.3 3.3 1.3.7 3 1.1 5 1.1s3.6-.4 5-1.1c1.3-.7 2.3-1.6 2.9-2.7.6-1.1.9-2.9.9-5.3V19c-1.1 1.4-2.4 2.4-4 3.1-1.6.7-3.2 1.1-5 1.1-2.1 0-4-.5-5.8-1.5-1.8-1-3.2-2.4-4.2-4.1-1-1.7-1.5-3.6-1.5-5.7s.5-4 1.5-5.8c1-1.8 2.4-3.2 4.3-4.2C16.1.9 18 .3 20 .3c1.7 0 3.2.3 4.7 1 1.4.7 2.8 1.8 4.2 3.4V1z"
          style={{
            mask: 'url(#mask-2_00000109730938504462504710000004793253776933360276_)',
            fillRule: 'evenodd',
            clipRule: 'evenodd',
            fill: '#383838',
          }}
        />
      </g>
      <path
        id="Fill-5"
        className="st5"
        d="M136.4 12.2c-2.4 0-4.4.9-6.2 2.6-1.7 1.8-2.6 3.9-2.6 6.4 0 1.6.4 3.1 1.2 4.5.8 1.4 1.8 2.5 3.2 3.2 1.3.8 2.8 1.1 4.4 1.1 1.6 0 3.1-.4 4.4-1.1 1.3-.8 2.4-1.8 3.2-3.2.8-1.4 1.2-2.9 1.2-4.5 0-2.5-.9-4.6-2.6-6.4-1.8-1.7-3.8-2.6-6.2-2.6zm0-2.8c3.4 0 6.3 1.2 8.5 3.7 2.1 2.3 3.1 5 3.1 8.1 0 3.1-1.1 5.8-3.3 8.2s-5 3.5-8.4 3.5c-3.4 0-6.2-1.2-8.4-3.5s-3.3-5.1-3.3-8.2c0-3.1 1-5.8 3.1-8 2.4-2.6 5.3-3.8 8.7-3.8z"
      />
      <path id="Fill-6" className="st5" d="M149.1 10h3.1l7.5 16.2 7.4-16.2h3.1l-10.3 22.3h-.5L149.1 10" />
      <path
        id="Fill-7"
        className="st6"
        d="m29.7 7.4-4 3.8c-2.7-2.9-5.8-4.3-9.2-4.3-2.9 0-5.3 1-7.3 3s-3 4.4-3 7.3c0 2 .4 3.8 1.3 5.4.9 1.6 2.1 2.8 3.7 3.7 1.6.9 3.4 1.3 5.3 1.3 1.7 0 3.2-.3 4.6-.9 1.4-.6 2.9-1.8 4.5-3.4l3.9 4.1c-2.2 2.2-4.3 3.7-6.3 4.5-2 .8-4.2 1.3-6.8 1.3-4.7 0-8.5-1.5-11.5-4.5S.4 21.9.4 17.3c0-3 .7-5.7 2-8C3.8 7 5.7 5.1 8.2 3.7c2.5-1.4 5.3-2.1 8.2-2.1 2.5 0 4.9.5 7.2 1.6s4.5 2.3 6.1 4.2"
      />
      <path
        id="Stroke-8"
        className="st7"
        d="m29.7 7.4-4 3.8c-2.7-2.9-5.8-4.3-9.2-4.3-2.9 0-5.3 1-7.3 3s-3 4.4-3 7.3c0 2 .4 3.8 1.3 5.4.9 1.6 2.1 2.8 3.7 3.7 1.6.9 3.4 1.3 5.3 1.3 1.7 0 3.2-.3 4.6-.9 1.4-.6 2.9-1.8 4.5-3.4l3.9 4.1c-2.2 2.2-4.3 3.7-6.3 4.5-2 .8-4.2 1.3-6.8 1.3-4.7 0-8.5-1.5-11.5-4.5S.4 21.9.4 17.3c0-3 .7-5.7 2-8C3.8 7 5.7 5.1 8.2 3.7c2.5-1.4 5.3-2.1 8.2-2.1 2.5 0 4.9.5 7.2 1.6s4.5 2.3 6.1 4.2z"
      />
      <path
        id="Fill-10"
        className="st6"
        d="M37.7 2.1h5.6l7 21.1 7-21.1h5.6L68 32.3h-5.6l-3.2-19.1-6.4 19.1h-5.1l-6.3-19.1L38 32.3h-5.5l5.2-30.2"
      />
      <path
        id="Stroke-11"
        className="st7"
        d="M37.7 2.1h5.6l7 21.1 7-21.1h5.6L68 32.3h-5.6l-3.2-19.1-6.4 19.1h-5.1l-6.3-19.1L38 32.3h-5.5z"
      />
      <path
        id="Fill-13"
        className="st6"
        d="M88.4 6.2 84.1 10c-1.5-2.1-3-3.1-4.6-3.1-.8 0-1.4.2-1.8.6-.5.4-.7.8-.7 1.3s.2 1 .5 1.4c.5.6 1.9 1.9 4.2 3.9 2.2 1.8 3.5 3 4 3.5 1.2 1.2 2 2.3 2.5 3.4s.7 2.2.7 3.5c0 2.5-.9 4.5-2.6 6.1S82.4 33 79.6 33c-2.1 0-4-.5-5.6-1.6-1.6-1.1-3-2.7-4.1-5l4.8-2.9c1.5 2.7 3.1 4 5 4 1 0 1.8-.3 2.5-.9.7-.6 1-1.2 1-2 0-.7-.3-1.4-.8-2-.4-.6-1.5-1.6-3.2-3-3.3-2.7-5.4-4.7-6.4-6.2s-1.4-2.9-1.4-4.4c0-2.1.8-3.9 2.4-5.4 1.6-1.5 3.6-2.2 5.9-2.2 1.5 0 2.9.3 4.3 1 1.3.7 2.8 2 4.4 3.8"
      />
      <path
        id="Stroke-14"
        className="st7"
        d="M88.4 6.2 84.1 10c-1.5-2.1-3-3.1-4.6-3.1-.8 0-1.4.2-1.8.6-.5.4-.7.8-.7 1.3s.2 1 .5 1.4c.5.6 1.9 1.9 4.2 3.9 2.2 1.8 3.5 3 4 3.5 1.2 1.2 2 2.3 2.5 3.4s.7 2.2.7 3.5c0 2.5-.9 4.5-2.6 6.1S82.4 33 79.6 33c-2.1 0-4-.5-5.6-1.6-1.6-1.1-3-2.7-4.1-5l4.8-2.9c1.5 2.7 3.1 4 5 4 1 0 1.8-.3 2.5-.9.7-.6 1-1.2 1-2 0-.7-.3-1.4-.8-2-.4-.6-1.5-1.6-3.2-3-3.3-2.7-5.4-4.7-6.4-6.2s-1.4-2.9-1.4-4.4c0-2.1.8-3.9 2.4-5.4 1.6-1.5 3.6-2.2 5.9-2.2 1.5 0 2.9.3 4.3 1 1.3.7 2.8 2 4.4 3.8z"
      />
    </g>
  </svg>
)

LogoCMS.propTypes = {
  id: PropTypes.string.isRequired,
}

export default LogoCMS
