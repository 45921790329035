import { useCallback, useEffect, useRef, useState } from 'react'
import { API } from 'aws-amplify'
// import { decryptUsers } from '../../../lib/ciphertextLib'
import { Button, ButtonGroup, Grid, Icon, Modal, ModalFooter, ModalHeading, Tooltip } from '@trussworks/react-uswds'
import { useConfig } from '../../../contexts/ConfigContext'
import DataTable from 'react-data-table-component'
import DataTableExtensions from 'react-data-table-component-extensions'
import ReportingUnitForm from './ReportingUnitForm'
import Notifications from '../../Notifications'
import Skeleton from 'react-loading-skeleton'
import useAdminStore from '../../../stores/useAdminStore'
import useGlobalStore from '../../../stores/useGlobalStore'
import { parseReportingUnit } from '../../../util/string'
import confirm from '../../ConfirmDialog'
import { toast } from 'react-toastify'

const ReportingUnitsList = () => {
  const reportingUnits = useAdminStore((state) => state.allReportingUnits)
  const setAllReportingUnits = useAdminStore((state) => state.setAllReportingUnits)
  const setCurrentReportingUnit = useAdminStore((state) => state.setCurrentReportingUnit)
  const deleteReportingUnit = useAdminStore((state) => state.deleteReportingUnit)
  const isAddMode = useAdminStore((state) => state.isAddMode)
  const setIsAddMode = useAdminStore((state) => state.setIsAddMode)
  const setNotification = useGlobalStore((state) => state.setNotification)
  const clearNotifications = useGlobalStore((state) => state.clearNotifications)
  const [isLoading, setIsLoading] = useState(true)

  const config = useConfig()

  const modalRef = useRef(null)
  const initModalRef = useCallback((node) => {
    if (node !== null && !node.modalIsOpen) {
      clearNotifications()
      setCurrentReportingUnit({})
    }

    modalRef.current = node
  }, [])
  const formRef = useRef(null)
  const alertRef = useRef(null)

  const customDataTableStyles = {
    tableWrapper: {
      style: { paddingTop: '0.25rem' },
    },
    pagination: {
      style: {
        fontSize: 'unset',
      },
      pageButtonsStyle: { borderRadius: '0.25rem' },
    },
  }

  const paginationComponentOptions = {
    selectAllRowsItem: true,
  }

  const rowActions = (row) => {
    return (
      <>
        <Tooltip
          label="Edit Reporting Unit"
          type="button"
          className="usa-button--unstyled padding-1"
          onClick={(e) => handleEdit(e, row)}
        >
          <Icon.Edit role="presentation" focusable="false" size={3} /> <span className="usa-sr-only">Edit</span>
        </Tooltip>
        <Tooltip
          label="Delete Reporting Unit"
          type="button"
          className="usa-button--unstyled padding-1"
          onClick={(e) => handleDelete(e, row)}
        >
          <Icon.Delete role="presentation" focusable="false" size={3} /> <span className="usa-sr-only">Delete</span>
        </Tooltip>
      </>
    )
  }

  const columns = [
    {
      name: 'Reporting Unit ID',
      selector: (row) => row.id,
      sortable: true,
      grow: 1,
      cell: (row) => <span className="font-family-mono">{row.id}</span>,
    },
    {
      name: 'Organization Name',
      selector: (row) => row.name_,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Attestation Status',
      selector: (row) => row.status_,
      sortable: true,
      grow: 1,
    },
    {
      name: 'Actions',
      cell: (row) => rowActions(row),
    },
  ]

  const tableData = {
    columns,
    data: reportingUnits,
    print: false,
    export: false,
    filterPlaceholder: 'Search reporting units...',
  }

  const handleAdd = (e) => {
    e.preventDefault()
    setIsAddMode(true)
    setCurrentReportingUnit({})
    modalRef.current.toggleModal()
  }

  const handleEdit = (e, row) => {
    e.preventDefault()
    setIsAddMode(false)
    setCurrentReportingUnit(row)
    modalRef.current.toggleModal()
  }

  const handleDelete = async (e, row) => {
    e.preventDefault()
    clearNotifications()
    try {
      // Get a list of users related to reporting unit.
      const result = await API.get('qhp-survey-auth', `/attestation/reporting-unit/${encodeURIComponent(row.id)}/users`)
      if (result?.length) {
        if (
          await confirm({
            dialogTitle: 'Associated Users',
            confirmation: `This reporting unit has ${result?.length} ${
              result?.length === 1 ? 'user' : 'users'
            } associated with it. Please remove all associated users before deleting this reporting unit.`,
            proceedLabel: 'View Reporting Unit',
          })
        ) {
          handleEdit(e, row)
        }
      } else {
        if (
          await confirm({
            dialogTitle: 'Delete Reporting Unit',
            confirmation: `Delete reporting unit ${parseReportingUnit(
              row.PK
            )}? NOTE: This will also delete any related attestation responses.`,
            proceedLabel: 'Delete',
          })
        ) {
          clearNotifications()
          const data = { PK: row.PK, SK: row.SK }
          try {
            await API.post('qhp-survey-auth', '/user/attestation/reporting-unit/delete', { body: data })
            deleteReportingUnit(data)
            toast.success('Reporting unit deleted successfully!')
          } catch (error) {
            toast.error("We're sorry, there was a problem deleting this reporting unit.")
          }
        }
      }
    } catch (error) {
      toast.error("We're sorry, there was a problem deleting this reporting unit.")
      console.error(error)
    }

    if (alertRef && alertRef.current) {
      alertRef.current.scrollIntoView({
        inline: 'start',
        behavior: 'smooth',
      })
    }
  }

  const toggleModal = () => {
    modalRef.current.toggleModal()
  }

  useEffect(() => {
    setIsLoading(true)
    clearNotifications()

    const onLoad = async () => {
      try {
        // Get a list of attestations (reporting units) for current year.
        const attestations = await API.get('qhp-survey-auth', '/attestation/reporting-unit/list')
        setAllReportingUnits(attestations)
      } catch (error) {
        setNotification({
          message: "We're sorry, there was a problem loading reporting units data.",
          type: 'error',
          scope: 'page',
          slim: true,
        })
        console.error(error)
      }

      setIsLoading(false)
    }

    onLoad()
  }, [])

  return (
    <>
      <h2 className="margin-0">Reporting Units</h2>
      <div ref={alertRef}>
        <Notifications scope="page" />
      </div>
      {config.isLoading || isLoading ? (
        <>
          <Grid row gap>
            <Grid tablet={{ col: 12 }} className="margin-top-1">
              <Skeleton height="3rem" count={3} />
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="position-relative">
          <div className="position-absolute right-0 margin-top-05">
            <Button type="button" className="usa-button--small usa-button--icon" onClick={(e) => handleAdd(e)}>
              <Icon.AddCircle className="margin-right-05" role="presentation" focusable="false" />
              Add Reporting Unit
            </Button>
          </div>
          <DataTableExtensions {...tableData}>
            <DataTable
              keyField="PK"
              responsive
              striped
              pagination
              paginationPerPage={25}
              paginationRowsPerPageOptions={[25, 50, 75, 100]}
              paginationComponentOptions={paginationComponentOptions}
              sortIcon={<Icon.ArrowDownward role="presentation" />}
              defaultSortFieldId={1}
              customStyles={customDataTableStyles}
            />
          </DataTableExtensions>
          <Notifications srOnly />
          <Modal
            ref={initModalRef}
            isLarge
            id="user-edit"
            className="usa-modal--wide"
            aria-labelledby="user-edit-heading"
            aria-describedby="user-edit-description"
          >
            <ModalHeading id="user-edit-heading" className="margin-bottom-0">
              {isAddMode ? 'Add' : 'Edit'} Reporting Unit
            </ModalHeading>
            <ReportingUnitForm config={config} reportingUnitsOptions={[]} toggleModal={toggleModal} ref={formRef} />
            <ModalFooter>
              <ButtonGroup>
                <Button
                  onClick={() => {
                    formRef.current.querySelector('#buttonSubmit').click()
                  }}
                >
                  Save
                </Button>
                <Button
                  outline
                  onClick={() => {
                    formRef.current.querySelector('#buttonCancel').click()
                  }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  )
}

export default ReportingUnitsList
