// TODO: Figure out a way to make this environment-aware.
const config = {
  s3: {
    REGION: 'us-east-1',
    // *** Uncomment for LOCAL environment ***
    // BUCKET: 'local-qhp-survey-application-uploads',
    // *** Uncomment for DEV environment ***
    // BUCKET: 'dev-qhp-survey-application-uploads',
    //*** Uncomment for TEST environment ***
    //BUCKET: 'test-qhp-survey-application-uploads',
    // *** Uncomment for PROD environment ***
    BUCKET: 'prod-qhp-survey-application-uploads',
  },
  apiGateway: {
    REGION: 'us-east-1',
    // *** Uncomment for LOCAL environment ***
    //URL: 'http://localhost:4000',
    // *** Uncomment for DEV environment ***
    // URL: 'https://n28jf2l2zj.execute-api.us-east-1.amazonaws.com/dev',
    // APIKEY: 'P5VCAQODkS56R4iVPQC7P35hcfo0Ndj3a9AlDfTC',
    // *** Uncomment for TEST environment ***
    //URL: 'https://mhiq289vik.execute-api.us-east-1.amazonaws.com/test',
    //APIKEY: '41MA5f9Xj47Zjtcd19iqzazATnmbmx6c9bcFunt0',
    // *** Uncomment for PROD environment ***
    URL: 'https://rvlb9o6ty3.execute-api.us-east-1.amazonaws.com/prod',
    APIKEY: 'qWRXn8pSMl3iZVCQb47Kg9B0bC2nQnlm60X7rz4u',
  },
  cognito: {
    REGION: 'us-east-1',
    // *** Uncomment for LOCAL & DEV environment ***
    // USER_POOL_ID: 'us-east-1_ftggF1blr',
    // APP_CLIENT_ID: '3vcnsqt2cf1tdsn0r00rlgosnv',
    // IDENTITY_POOL_ID: 'us-east-1:e09484a1-553e-4a24-a328-35405929b0d9',
    // IDP: 'okta-cms-test',
    // *** Uncomment for TEST environment ***
    //USER_POOL_ID: 'us-east-1_R5kQSZsev',
    //APP_CLIENT_ID: '3d89ugrkmdkgtnm78pl4d0voh5',
    //IDENTITY_POOL_ID: 'us-east-1:ca1c7b27-b914-4366-8dd0-4b49b7eb243a',
    //IDP: 'cms-idm-saml-impl',
    // *** Uncomment for PROD environment ***
    USER_POOL_ID: 'us-east-1_58PwkLs0c',
    APP_CLIENT_ID: '27urb49l51587r5nopctkj985t',
    IDENTITY_POOL_ID: 'us-east-1:51996990-da11-4f47-bc77-217fa67dea04',
    IDP: 'cms-idm-saml-prod',
    oauth: {
      scope: ['email', 'openid', 'profile'],
      responseType: 'token',
      // *** Uncomment for LOCAL environment ***
      // domain: 'qhp-survey-dev.auth.us-east-1.amazoncognito.com',
      // redirectSignIn: 'http://localhost:3000/login',
      // redirectSignOut: 'http://localhost:3000/logout',
      // *** Uncomment for DEV environment ***
      // domain: 'qhp-survey-dev.auth.us-east-1.amazoncognito.com',
      // redirectSignIn: 'https://d2naa9112m64qn.cloudfront.net/login',
      // redirectSignOut: 'https://d2naa9112m64qn.cloudfront.net/logout',
      // *** Uncomment for TEST environment ***
      //domain: 'qhp-survey-test.auth.us-east-1.amazoncognito.com',
      //redirectSignIn: 'https://d39z2wah1anep5.cloudfront.net/login',
      //redirectSignOut: 'https://d39z2wah1anep5.cloudfront.net/logout',
      // *** Uncomment for PROD environment ***
      domain: 'qhp-survey.auth.us-east-1.amazoncognito.com',
      redirectSignIn: 'https://qhpsurvey.cms.gov/login',
      redirectSignOut: 'https://qhpsurvey.cms.gov/logout',
    },
  },
}

export default config
