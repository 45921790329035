import DOMPurify from 'dompurify'

// In most cases, this should be used in conjunction with createMarkup to generate safe HTML
// Use this first, followed by createMarkup
// NOTE: This might become obsolete once we implement a WYSIYG editor
function nl2br(text) {
  return text.replace(/\n/g, '<br />')
}

const createMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html),
  }
}

export { nl2br, createMarkup }
