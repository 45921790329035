import { forwardRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { API } from 'aws-amplify'
import { FormGroup, ErrorMessage, Grid, Label, Textarea, TextInput } from '@trussworks/react-uswds'
import { useForm, Controller } from 'react-hook-form'
import { isEmpty } from 'lodash'
import classNames from 'classnames'
import { toast } from 'react-toastify'

import ReportingUnitUsers from './ReportingUnitUsers'
import useAdminStore from '../../../stores/useAdminStore'
import useGlobalStore from '../../../stores/useGlobalStore'
import Notifications from '../../Notifications'

const ReportingUnitForm = forwardRef(({ config, reportingUnitsOptions, toggleModal }, ref) => {
  const data = useAdminStore((state) => state.currentReportingUnit)
  const setCurrentReportingUnit = useAdminStore((state) => state.setCurrentReportingUnit)
  const addReportingUnit = useAdminStore((state) => state.addReportingUnit)
  const updateReportingUnit = useAdminStore((state) => state.updateReportingUnit)
  const isAddMode = useAdminStore((state) => state.isAddMode)
  const setIsAddMode = useAdminStore((state) => state.setIsAddMode)
  const setNotification = useGlobalStore((state) => state.setNotification)
  const clearNotifications = useGlobalStore((state) => state.clearNotifications)

  const formRef = useRef(null)

  const defaultValues = {
    PK: '',
    year_: '',
    id: '',
    name_: '',
    status_: '',
    note: '',
  }

  const {
    register,
    reset,
    // resetField,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: defaultValues,
  })

  const reportingUnitExists = async (value) => {
    // const newPK = generateReportingUnitId(value, config?.sys?.year_)
    let exists = false
    if (value !== data.id) {
      try {
        exists = await API.get('qhp-survey-auth', `/attestation/reporting-unit/${encodeURIComponent(value)}`)
      } catch (error) {
        console.error(error)
        return 'There was an error validating this reporting unit ID'
      }
    }
    return !exists || 'This reporting unit already exists'
  }

  const onSubmit = async (data, e) => {
    e.preventDefault()
    clearNotifications()
    // const reportingUnitId = generateReportingUnitId(data.id)
    // const newData = { ...data, PK: reportingUnitId }
    if (isAddMode) {
      try {
        const result = await API.post('qhp-survey-auth', '/attestation/reporting-unit/create', { body: data })
        addReportingUnit(result.Attributes)
        setNotification({
          message: 'Reporting unit added successfully. Add associated users or close this dialog to continue.',
          type: 'success',
          scope: 'modal',
          slim: true,
        })
        setIsAddMode(false)
      } catch (error) {
        setNotification({
          message: "We're sorry, there was a problem adding reporting unit.",
          type: 'error',
          scope: 'modal',
          slim: true,
        })
      }
    } else {
      try {
        await API.post('qhp-survey-auth', '/attestation/reporting-unit/update', { body: data })
        updateReportingUnit(data)
        toggleModal()
        toast.success('Reporting unit updated successfully!')
      } catch (error) {
        console.error(error)
        setNotification({
          message: "We're sorry, there was a problem saving reporting unit information.",
          type: 'error',
          scope: 'modal',
          slim: true,
        })
      }
    }
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({
        inline: 'start',
        behavior: 'smooth',
      })
    }
  }

  const handleCancel = () => {
    toggleModal()
    setCurrentReportingUnit({})
  }

  useEffect(() => {
    // setIsLoading(true)
    if (!isEmpty(data)) {
      reset({
        PK: data.PK,
        year_: data.year_,
        id: data.id,
        name_: data.name_,
        status_: data.status_,
        note: data.note,
      })
    } else {
      reset({
        PK: '',
        year_: config?.sys?.year_,
        name_: '',
        status_: isAddMode ? 'Incomplete' : '',
        note: '',
      })
    }

    // setIsLoading(false)
  }, [data, reset])

  return (
    <div ref={formRef} style={{ scrollMarginTop: '8rem' }}>
      <Grid row gap>
        <Grid tablet={{ col: true }}>
          <Notifications scope="modal" />
        </Grid>
      </Grid>
      <Grid row gap>
        <Grid tablet={{ col: 6 }}>
          <form ref={ref} className="usa-form usa-form--full" onSubmit={handleSubmit(onSubmit)} noValidate>
            <input {...register('PK')} id="PK" name="PK" type="hidden" />
            <input {...register('year_')} id="year" name="year_" type="hidden" />
            <FormGroup error={errors.id}>
              <Label htmlFor="id" className="text-bold" error={errors.id}>
                Reporting Unit ID{' '}
                {isAddMode ? (
                  <span className="usa-hint usa-hint--required" aria-hidden="true">
                    *
                  </span>
                ) : (
                  <span className="usa-hint text-normal">(Read Only)</span>
                )}
              </Label>
              {errors.id && <ErrorMessage id="name-error-alert">{errors.id.message}</ErrorMessage>}
              <Controller
                control={control}
                name="id"
                defaultValue=""
                rules={{
                  required: 'Please enter a reporting unit ID',
                  pattern: {
                    value: /^\d{5}-[a-zA-Z]{2}-[a-zA-Z]{3}$/,
                    message: 'Reporting unit ID must be in the following format: 99999-AA-AAA',
                  },
                  validate: reportingUnitExists,
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <TextInput
                      type="text"
                      inputRef={ref}
                      id="id"
                      className={classNames('text-uppercase', { 'usa-input--error': errors.id })}
                      aria-describedby="id-format-hint id-error-alert"
                      aria-required="true"
                      value={value}
                      onChange={onChange}
                      readOnly={!isAddMode}
                    />
                  </>
                )}
              />
              <div id="id-format-hint" className="usa-hint font-body-2xs">
                Format: 9999-AA-AAA
              </div>
            </FormGroup>
            <FormGroup error={errors.name_}>
              <Label htmlFor="name" className="text-bold" error={errors.name_}>
                Organization Name{' '}
                <span className="usa-hint usa-hint--required" aria-hidden="true">
                  *
                </span>
              </Label>
              {errors.name_ && <ErrorMessage id="name-error-alert">{errors.name_.message}</ErrorMessage>}
              <Controller
                control={control}
                name="name_"
                defaultValue=""
                rules={{
                  required: 'Please enter an organization name',
                }}
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <TextInput
                      inputRef={ref}
                      id="name"
                      className={classNames({ 'usa-input--error': errors.name_ })}
                      aria-describedby="name-error-alert"
                      aria-required="true"
                      value={value}
                      onChange={onChange}
                    />
                  </>
                )}
              />
            </FormGroup>

            <FormGroup error={errors.status_} className={classNames({ 'display-none': isAddMode })}>
              <Label htmlFor="status" className="text-bold" error={errors.status_}>
                Attestation Status <span className="usa-hint text-normal">(Read Only)</span>
              </Label>
              {errors.status_ && <ErrorMessage id="status-error-alert">{errors.status_.message}</ErrorMessage>}
              <Controller
                control={control}
                name="status_"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <TextInput
                      type="text"
                      inputRef={ref}
                      id="status"
                      className={classNames({ 'usa-input--error': errors.status_ })}
                      aria-describedby="status-error-alert"
                      value={value}
                      onChange={onChange}
                      readOnly={true}
                    />
                  </>
                )}
              />
            </FormGroup>
            <FormGroup error={errors.note}>
              <Label htmlFor="note" className="text-bold" error={errors.note}>
                Note
              </Label>
              {errors.note && <ErrorMessage id="user-role-error-alert">{errors.note.message}</ErrorMessage>}
              <Controller
                control={control}
                name="note"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Textarea
                    inputRef={ref}
                    id="note"
                    className={classNames({ 'usa-input--error': errors.note })}
                    aria-describedby="note-error-alert"
                    value={value}
                    onChange={onChange}
                    style={{ width: '100%', height: '150px' }}
                  />
                )}
              />
            </FormGroup>
            <button id="buttonSubmit" className="display-none" type="submit">
              Save
            </button>
            <button id="buttonCancel" className="display-none" type="button" onClick={() => handleCancel()}>
              Cancel
            </button>
          </form>
        </Grid>
        <Grid tablet={{ col: 6 }}>
          <ReportingUnitUsers config={config} reportingUnitsOptions={reportingUnitsOptions} />
        </Grid>
      </Grid>
    </div>
  )
})

ReportingUnitForm.displayName = 'ReportingUnitForm'

ReportingUnitForm.propTypes = {
  config: PropTypes.object.isRequired,
  reportingUnitsOptions: PropTypes.array.isRequired,
  toggleModal: PropTypes.func.isRequired,
}

export default ReportingUnitForm
