import { Helmet } from 'react-helmet-async'
import { Grid, GridContainer, Link } from '@trussworks/react-uswds'
import { useAuth } from '../contexts/AuthContext'
import Main from '../components/Main'

const HomePage = () => {
  const user = useAuth()

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <section className="usa-hero">
        <GridContainer>
          <Grid row gap>
            <Grid tablet={{ col: 8 }}></Grid>
            <Grid tablet={{ col: 4 }}>
              {user.isLoggedIn ? (
                <div className="usa-hero__callout opacity-95 radius-lg">
                  <div className="usa-hero__heading">
                    <span className="usa-hero__heading--alt">Welcome, {user.info.nameFirst}</span>
                  </div>
                  <p>Access your QHP Enrollee Experience Survey Dashboard.</p>
                  <Link className="usa-button qhp-link--signin" variant="unstyled" href={'dashboard'}>
                    Go to Dashboard
                  </Link>
                </div>
              ) : (
                <div className="usa-hero__callout opacity-95 radius-lg">
                  <div className="usa-hero__heading">
                    <span className="usa-hero__heading--alt">Sign In</span>
                  </div>
                  <p>Access your QHP Enrollee Experience Survey account.</p>
                  <Link className="usa-button qhp-link--signin" variant="unstyled" href={'login'}>
                    Sign In
                  </Link>
                </div>
              )}
            </Grid>
          </Grid>
        </GridContainer>
      </section>
      <section className="usa-section">
        <GridContainer>
          <Grid row gap>
            <Grid tablet={{ col: 6 }}>
              <Main>
                <div className="usa-prose qhp-prose--full">
                  <h1>About the Qualified Health Plan (QHP) Enrollee Experience Survey</h1>
                  <p>
                    Section 1311(c)(4) of the Patient Protection and Affordable Care Act (PPACA) requires the United
                    States Department of Health and Human Services Secretary to develop an enrollee satisfaction survey
                    system that assesses consumer experience with Qualified Health Plans (QHPs) offered through a Health
                    Insurance Exchange. It also requires public display of information by each Exchange to allow
                    individuals to assess enrollee experience among comparable plans.
                  </p>
                  <p>
                    The goals of the survey are to provide comparable and useful information to consumers about the
                    quality of health care services and enrollee experience with QHPs offered through the Exchanges,
                    facilitate oversight of QHP issuer compliance with quality reporting standards, and provide
                    actionable information that QHP issuers can use to improve quality and performance.
                  </p>
                  <p>
                    For more information about the QHP Enrollee Experience Survey, please visit the{' '}
                    <Link
                      variant="external"
                      href={
                        'https://www.cms.gov/Medicare/Quality-Initiatives-Patient-Assessment-Instruments/QualityInitiativesGenInfo/ACA-MQI/ACA-MQI-Landing-Page'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="CMS Marketplace Quality Initiatives (MQI) website, external"
                    >
                      CMS Marketplace Quality Initiatives (MQI) website.
                    </Link>
                  </p>
                  <br />
                </div>
              </Main>
            </Grid>
            <Grid tablet={{ col: 6 }}>
              <div className="qhp-callout">
                <div className="usa-prose qhp-prose--full">
                  <h2>QHP Issuers</h2>
                  <p>
                    The attestation period for 2025 opens November 15, 2025. Designated QHP issuer contacts must log in
                    and complete the following via this website by <b>January 31, 2025</b>:
                  </p>
                  <ol>
                    <li>
                      For <b>eligible</b> reporting units: Attest to the QRS and QHP Enrollee Survey eligibility
                      criteria. This includes confirming reporting unit information, reporting survey vendor selection
                      to CMS, and confirming sample frame validation.
                    </li>
                    <li>
                      For <b>ineligible</b> reporting units: Report ineligibility status to CMS and confirm reason for
                      ineligibility.
                    </li>
                  </ol>
                  <p>
                    CMS will release the QRS and QHP Enrollee Survey: Operational Instructions for 2025 on the{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.cms.gov/Medicare/Quality-Initiatives-Patient-Assessment-Instruments/QualityInitiativesGenInfo/ACA-MQI/Consumer-Experience-Surveys/Surveys-page"
                      className="usa-link usa-link--external"
                      aria-label="CMS Marketplace Quality Initiatives (MQI) website link, external"
                    >
                      CMS Marketplace Quality Initiatives (MQI) website
                    </a>{' '}
                    in Fall 2024. This document is a resource for QHP issuers to review QRS and QHP Enrollee Experience
                    Survey requirements.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </GridContainer>
      </section>
    </>
  )
}

export default HomePage
