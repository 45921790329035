import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import SiteHeader from '../components/SiteHeader'
import SiteFooter from '../components/SiteFooter'
import IdleTimeOutHandler from '../components/IdleTimeOutHandler'
import { Slide, ToastContainer } from 'react-toastify'
import { useAuth } from '../contexts/AuthContext'

const Layout = () => {
  const user = useAuth()

  return (
    <>
      <Helmet defaultTitle="QHP Enrollee Experience Survey" titleTemplate="%s - QHP Enrollee Experience Survey" />
      <SiteHeader />
      {user.isLoggedIn && (
        <IdleTimeOutHandler idleWarningInterval={1000 * 60 * 13} idleTimeoutInterval={1000 * 60 * 15} />
      )}
      <Outlet />
      <SiteFooter />
      <ToastContainer hideProgressBar position="top-center" theme="colored" transition={Slide} />
    </>
  )
}

export default Layout
