/**
 * Custom animated spinner based off of spinner from CMS Design System
 * https://design.cms.gov/components/spinner/
 */
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Spinner = ({ size }) => {
  const classes = classNames('qhp-spinner', size && `qhp-spinner--${size}`)

  return (
    <>
      <span className={classes} role="status">
        <span className="usa-sr-only">Processing</span>
      </span>{' '}
    </>
  )
}

Spinner.propTypes = {
  size: PropTypes.string,
}

export default Spinner
