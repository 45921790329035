import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Amplify, Auth } from 'aws-amplify'
import { HelmetProvider } from 'react-helmet-async'
import config from './config'
import App from './App'

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'qhp-survey',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return {
            'X-Api-Key': config.apiGateway.APIKEY,
          }
        },
      },
      {
        name: 'qhp-survey-auth',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: async () => {
          return { Authorization: 'Bearer ' + (await Auth.currentSession()).getIdToken().getJwtToken() }
        },
      },
    ],
  },
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    storage: sessionStorage,
    oauth: {
      domain: config.cognito.oauth.domain,
      scope: config.cognito.oauth.scope,
      redirectSignIn: config.cognito.oauth.redirectSignIn,
      redirectSignOut: config.cognito.oauth.redirectSignOut,
      responseType: config.cognito.oauth.responseType,
    },
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
  },
})

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Router>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Router>
)
