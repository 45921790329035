import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { confirmable, createConfirmation } from 'react-confirm'
import { Button, ButtonGroup, Modal, ModalFooter, ModalHeading } from '@trussworks/react-uswds'

const ConfirmDialog = ({
  dialogTitle = 'Confirm',
  confirmation = 'Are you sure?',
  proceedLabel = 'OK',
  cancelLabel = 'Cancel',
  show,
  proceed,
  alertOnly = false,
}) => {
  const modalRef = useRef(null)

  useEffect(() => {
    modalRef.current.toggleModal(show)
  }, [show])

  return (
    <Modal
      ref={modalRef}
      id="confirm-dialog"
      aria-labelledby="confirm-dialog-heading"
      aria-describedby="confirm-dialog-description"
      renderToPortal={false}
      forceAction
    >
      <ModalHeading id="confirm-dialog-heading" className="margin-bottom-0">
        {dialogTitle}
      </ModalHeading>
      {confirmation}
      <ModalFooter>
        <ButtonGroup>
          <Button onClick={() => proceed(true)}>{proceedLabel}</Button>
          {!alertOnly ? (
            <Button outline onClick={() => proceed(false)}>
              {cancelLabel}
            </Button>
          ) : null}
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  )
}

ConfirmDialog.propTypes = {
  alertOnly: PropTypes.bool,
  dialogTitle: PropTypes.string,
  confirmation: PropTypes.string,
  proceedLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func,
  options: PropTypes.object,
}

const confirm = ({ dialogTitle, confirmation, proceedLabel, cancelLabel, alertOnly, options = {} }) => {
  return createConfirmation(confirmable(ConfirmDialog))({
    dialogTitle,
    confirmation,
    proceedLabel,
    cancelLabel,
    alertOnly,
    ...options,
  })
}

export default confirm
