import { Alert, Grid, GridContainer } from '@trussworks/react-uswds'
import Main from './Main'

const NoDashboard = () => {
  return (
    <>
      <GridContainer>
        <Grid row gap>
          <Grid tablet={{ col: true }}>
            <Main>
              <section className="usa-section">
                <Alert type="warning" heading="No Dashboard Access" headingLevel="h4">
                  Your account does not have access to the dashboard. If you feel this is an error, please contact us at{' '}
                  <a href="mailto:QHP_Survey@air.org">QHP_Survey@air.org</a>
                </Alert>
              </section>
            </Main>
          </Grid>
        </Grid>
      </GridContainer>
    </>
  )
}

export default NoDashboard
