import Skeleton from 'react-loading-skeleton'

const DataTableLoader = () => {
  return (
    <div className="width-full">
      <Skeleton height="3rem" count={3} />
    </div>
  )
}

export default DataTableLoader
